import { Component } from '@angular/core';
import { UserService } from './services/user.service';
import { ConfigurationService, LanguageService } from 'shared';
import { IUser } from './interfaces/user';
import { CurrentUserService } from 'projects/serviceportal/src/app/services/current-user.service';
import { tap, first } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin';
  user: IUser;

  constructor(
    public userService: UserService,
    private configurationService: ConfigurationService,
    private languageService: LanguageService,
    private currentUserService: CurrentUserService,
  ) {
    this.configurationService.LoadConfiguration();
    this.currentUserService.currentUser$
      .pipe(
        first(),
        tap((user) => {
          this.languageService.setupLanguages(user.language);
        }),
      )
      .subscribe();
  }
}
