<div class="steps-wrapper">
  <gbc-page-container class="steps">
    <div class="step" *ngFor="let step of steps; let i = index">
      <div class="bar" [ngClass]="{ enabled: selectedIndex === i }"></div>
      <div class="step-label" [ngClass]="{ enabled: selectedIndex === i }">
        {{ step.label }}
      </div>
    </div>
  </gbc-page-container>
</div>

<gbc-page-container>
  <div *ngIf="selected">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <footer class="stepper-navigation mb-8">
    <button gbc-button cdkStepperPrevious class="stepper-button stepper-button--back" [disabled]="backDisabled" *ngIf="selectedIndex !== 0">
      {{ backLabel }}
    </button>

    <button gbc-button cdkStepperPrevious class="stepper-button stepper-button--back" (click)="exit.emit()" *ngIf="selectedIndex === 0">
      {{ backLabel }}
    </button>

    <button gbc-button cdkStepperNext *ngIf="selectedIndex !== steps.length - 1" class="stepper-button" [disabled]="nextDisabled">
      {{ nextLabel }}
    </button>

    <button gbc-button class="stepper-button" *ngIf="selectedIndex === steps.length - 1" (click)="done.emit()" [disabled]="doneDisabled">
      {{ doneLabel }}
    </button>
  </footer>
</gbc-page-container>
