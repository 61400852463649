import { Component, Inject, OnInit } from '@angular/core';
import { ISnackBarRef, SNACK_BAR_DATA, SNACK_BAR_REF } from '../../interfaces/snack-bar';

@Component({
  selector: 'gbc-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
})
export class SnackBarComponent implements OnInit {
  constructor(
    @Inject(SNACK_BAR_DATA) public data: { message: string },
    @Inject(SNACK_BAR_REF) public snackBarRef: ISnackBarRef,
  ) {}

  ngOnInit(): void {}
}
