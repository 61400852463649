import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageInfo } from 'shared';
import { of } from 'rxjs';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';

@Injectable({
  providedIn: 'root',
})
export class PageInfoService {
  constructor(private translate: TranslateService) {}

  public overviewPageCompanies(): PageInfo {
    return {
      parent: null,
      title: this.translate.get('page-info-service.service-portal'),
      subtitles: [of('')],
      link: ['/overview', { tab: 'companies' }],
    };
  }

  public overviewPageUsers(): PageInfo {
    return {
      parent: null,
      title: this.translate.get('page-info-service.service-portal'),
      subtitles: [of('')],
      link: ['/overview', { tab: 'users' }],
    };
  }

  public companyPage(company: ICompany): PageInfo {
    return {
      parent: this.overviewPageCompanies(),
      title: of(company.name),
      subtitles: [company.archiveState === EArchiveState.Archived ? this.translate.get('page-info-service.archived') : of('')],
      link: ['/company/view', company.id],
    };
  }

  public createEditCompanyPage(mode: 'edit' | 'create', companyId?: string): PageInfo {
    const title = this.translate.get(mode === 'edit' ? 'page-info-service.edit-company-page' : 'page-info-service.create-company-page');
    const link = companyId ? ['company', companyId] : ['company'];
    return {
      parent: this.overviewPageCompanies(),
      title,
      subtitles: [of('')],
      link,
    };
  }

  public facilityPage(facility: IFacility): PageInfo {
    return {
      parent: this.companyPage(facility.company),
      title: of(`${facility.facilityType}: ${facility.name}`),
      subtitles: [facility.archiveState === EArchiveState.Archived ? this.translate.get('page-info-service.archived') : of('')],
      link: ['/facility/view', facility.id],
    };
  }

  public createEditFacilityPage(mode: 'edit' | 'create', companyId?: string, facilityId?: string): PageInfo {
    const title = this.translate.get(mode === 'edit' ? 'page-info-service.edit-facility-page' : 'page-info-service.create-facility-page');
    const link = facilityId ? ['facility', companyId, facilityId] : ['facility'];
    return {
      parent: this.overviewPageCompanies(),
      title,
      subtitles: [of('')],
      link,
    };
  }

  public createIGridInstallationPage(companyId: string): PageInfo {
    return {
      parent: this.overviewPageCompanies(),
      title: this.translate.get('page-info-service.create-igrid-installation-page'),
      subtitles: [of('')],
      link: ['create'],
      queryParams: {
        companyId,
      },
    };
  }

  public editIGridInstallationPage(facilityId: string, installationId: string): PageInfo {
    return {
      parent: this.overviewPageCompanies(),
      title: this.translate.get('page-info-service.edit-igrid-installation-page'),
      subtitles: [of('')],
      link: ['create', facilityId, installationId],
    };
  }

  public iGridInstallationPage(facility: IFacility, { serialNumber, deviceId, id: installationId }): PageInfo {
    return {
      parent: this.facilityPage(facility),
      title: of(`${facility.name} - ${serialNumber} [${deviceId}]`),
      subtitles: [of('')],
      link: ['installation', facility.id, installationId],
    };
  }

  public userPageFromOverViewPage(user: IUser): PageInfo {
    return {
      parent: this.overviewPageUsers(),
      title: of(user.name),
      subtitles: [of('')],
      link: ['/users', user.id],
    };
  }

  public createUser() {
    return {
      parent: this.overviewPageUsers(),
      title: this.translate.get('page-info-service.create-user'),
      subtitles: [of('')],
      link: ['/users/create'],
    };
  }

  public editUser(id: string) {
    return {
      parent: this.overviewPageUsers(),
      title: this.translate.get('page-info-service.edit-user'),
      subtitles: [of('')],
      link: ['/users/create', id],
    };
  }

  public archivePage() {
    return {
      parent: this.overviewPageCompanies(),
      title: this.translate.get('page-info-service.archive-page'),
      subtitles: [this.translate.get('page-info-service.archive-description')],
      link: ['/developer/archive'],
    };
  }
}
