import { Component, AfterContentInit } from '@angular/core';
import { AppError, AppErrorService, PageInfo, ModalService } from 'shared';
import { Observable, combineLatest } from 'rxjs';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { map, startWith, first, tap, shareReplay } from 'rxjs/operators';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { UserService } from 'projects/serviceportal/src/app/services/user.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { IReducedInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';
import { TranslateService } from '@ngx-translate/core';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';
import { IIGridSystem } from '../../interfaces/iGridSystem';

@Component({
  selector: 'app-facility-page',
  templateUrl: './facility-page.component.html',
  styleUrls: ['./facility-page.component.scss'],
})
export class FacilityPageComponent implements AfterContentInit {
  public users$: Observable<IUser[]>;
  public facility$: Observable<IFacility>;
  public installations$: Observable<(IReducedInstallationDTO | IIGridSystem)[]>;
  public pageError$: Observable<AppError>;
  public pageInfo$: Observable<PageInfo>;
  public isArchived$: Observable<boolean>;

  public showCreateInstallationButton$: Observable<boolean>;
  public showCreateUserButton$: Observable<boolean>;

  public id: string;

  public form = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  public createInstallationUrlMap = {
    BuildingConnect: '/buildingconnect/create',
    MIXIT: '/buildingconnect/create',
    iGrid: '/igrid/create',
  };

  constructor(
    private pageInfoService: PageInfoService,
    private facilityService: FacilityService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private errorService: AppErrorService,
    private translate: TranslateService,
    private modalService: ModalService,
  ) {
    this.id = this.route.snapshot.params.facilityId;

    this.facility$ = this.facilityService.getFacility(this.id).pipe(shareReplay());

    this.isArchived$ = this.facility$.pipe(map((facility) => facility.archiveState === EArchiveState.Archived));

    this.installations$ = combineLatest([this.form.controls.search.valueChanges.pipe(startWith('')), this.facility$]).pipe(
      map(([search, facility]) => {
        const activeInstallations = facility.installations.filter((installation) => installation.archiveState === EArchiveState.Active);

        if (!search) {
          return activeInstallations;
        }
        return activeInstallations.filter((installation) => {
          return [installation.serialNumber?.toString()?.toLowerCase(), installation.deviceId?.toString()?.toLowerCase()].some((param) =>
            param.includes(search?.toLowerCase()),
          );
        });
      }),
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.facility$]);

    this.pageInfo$ = this.facility$.pipe(map((facility) => this.pageInfoService.facilityPage(facility)));

    this.users$ = this.userService.getUsersForFacility(this.id).pipe(
      map((users) => {
        return users.filter((user) => user.archiveState === EArchiveState.Active);
      }),
    );
  }

  ngAfterContentInit() {
    this.showCreateInstallationButton$ = combineLatest([this.route.params, this.isArchived$]).pipe(
      map(([params, isArchived]) => params.tab === 'installations' && !isArchived),
    );

    this.showCreateUserButton$ = combineLatest([this.route.params, this.isArchived$]).pipe(
      map(([params, isArchived]) => params.tab === 'users' && !isArchived),
    );
  }

  public editFacility() {
    this.facility$.subscribe((facility) => {
      this.router.navigate(['/facility/create', facility.company.id, facility.id]);
    });
  }

  public navigateToInstallation(facility: IFacility, installation: IReducedInstallationDTO) {
    switch (facility.facilityType) {
      case 'BuildingConnect':
      case 'MIXIT':
        this.router.navigate(['buildingconnect', 'installation', facility.id, installation.id]);
        break;
      case 'iGrid':
        this.router.navigate(['igrid', 'installation', facility.id, installation.id]);
        break;
    }
  }

  confirmArchiveFacility() {
    this.modalService.showTextModal({
      iconUrl: './assets/svgs/archive.svg',
      title: this.translate.instant('app-archive'),
      headline: this.translate.instant('facility-page.confirm-archive'),
      content: this.translate.instant('facility-page.archive-description'),
      actions: [
        {
          text: this.translate.instant('app-cancel'),
          cancel: true,
        },
        {
          text: this.translate.instant('app-archive'),
          handler: this.archiveFacility.bind(this),
          type: 'danger',
        },
      ],
    });
  }

  archiveFacility() {
    this.facilityService.archive(Number(this.route.snapshot.params.facilityId)).subscribe(() => {
      this.facility$
        .pipe(
          first(),
          tap((facility) => {
            this.router.navigate(['/company/view', facility.company.id, { tab: 'facilities' }]);
          }),
        )
        .subscribe();
    });
  }
}
