<gbc-tile [title]="'igrid-installation-settings-component.title' | translate">
  <div *ngIf="currentSystem.settings?.length; else noSettings" class="tree-header-container">
    <div
      class="name"
      [ngStyle]="{
        width: (maxWidth$ | async) + ((maxIndentationLevel$ | async) + 1) * 20 + 'px'
      }"
    >
      {{ 'igrid-installation-settings-component.name' | translate }}
    </div>
    <div class="type">
      {{ 'igrid-installation-settings-component.type' | translate }}
    </div>
    <div class="value">
      {{ 'igrid-installation-settings-component.value' | translate }}
    </div>
    <div class="actions">
      {{ 'igrid-installation-settings-component.actions' | translate }}
    </div>
  </div>

  <div class="tree-container">
    <app-multi-dimensional-view
      *ngFor="let setting of settingsTree$ | async"
      [childPropName]="'settings'"
      [value]="setting"
      [content]="itemView"
    ></app-multi-dimensional-view>
  </div>

  <ng-template
    #itemView
    let-id="id"
    let-value="value"
    let-type="type"
    let-settingKey="settingKey"
    let-level="level"
    let-childrenCount="childrenCount"
    let-parentId="parentId"
    let-unit="unit"
    let-customName="customName"
    let-needsSupportUserLevel="needsSupportUserLevel"
  >
    <div
      class="setting-node"
      (click)="viewSetting(id)"
      [style.--hover-bg-color]="rainbowMode.value?.useRainbow ? (id | randomColor) : 'lemonchiffon'"
    >
      <div
        class="name"
        [ngClass]="{ 'header-node-name': !parentId || childrenCount }"
        [ngStyle]="{
          width: (maxWidth$ | async) + ((maxIndentationLevel$ | async) - level + 1) * 20 + level + 'px'
        }"
      >
        {{ needsSupportUserLevel ? '🧑‍🔧' : '' }}
        {{ customName || ('igrid-installation-settings.' + settingKey | translate) || settingKey }}
      </div>
      <div class="type">
        <p>{{ type || '' }}</p>
      </div>
      <div class="value">
        <p *ngIf="value?.length; else emptyCell">{{ value }} {{ unit ? '(' + unit + ')' : '' }}</p>
      </div>
      <div class="actions">
        <!-- <button class="delete-button" gbc-icon-button ><span class="button-icon view"></span></button> -->
        <button class="delete-button" gbc-icon-button (click)="$event.stopPropagation(); editSetting(id)">
          <span class="button-icon edit"> {{ 'app-edit' | translate }} ✏️</span>
        </button>
        <button
          class="delete-button"
          gbc-icon-button
          (click)="$event.stopPropagation(); deleteSetting(id)"
          *ngIf="!childrenCount; else emptyCell"
        >
          <span class="button-icon delete">{{ 'app-delete' | translate }} 🗑️</span>
        </button>
      </div>
    </div>
    <ng-template #emptyCell>
      <div>&nbsp;</div>
    </ng-template>
  </ng-template>
  <div class="settings-buttons-row">
    <button gbc-button (click)="addSetting()">
      {{ 'add-igrid-system-setting-dialog.add-new-setting' | translate }}
    </button>
    <form *ngIf="isDeveloper$ | async" [formGroup]="rainbowMode">
      <gbc-input-label>{{ 'Rainbow Mode 🌈' }} </gbc-input-label>
      <gbc-toggle [formControlName]="'useRainbow'">Rainbow mode</gbc-toggle>
    </form>
  </div>
</gbc-tile>

<ng-template #noSettings>
  <div class="no-settings">
    {{ 'igrid-installation-settings-component.no-settings' | translate }}
  </div>
</ng-template>
