import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { FacilityService } from '../../services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, startWith, first, tap, shareReplay } from 'rxjs/operators';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { IFacility, EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';
import { PageInfo, AppError, AppErrorService } from 'shared';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { FacilityTypeService } from '../../services/facilitytype.service';

@Component({
  selector: 'app-create-edit-facility',
  templateUrl: './create-edit-facility.component.html',
  styleUrls: ['./create-edit-facility.component.scss'],
})
export class CreateEditFacilityComponent {
  public mapsUrl$: Observable<string>;
  public facilityTypes: Observable<string[]>;
  public company$: Observable<ICompany | null>;
  public pageError$: Observable<AppError>;
  public pageInfo: PageInfo;

  public company: ICompany;

  public creating = true;

  public facility$: Observable<IFacility>;

  public form = new UntypedFormGroup({
    facilityName: new UntypedFormControl(''),
    address: new UntypedFormControl('', Validators.required),
    postalCode: new UntypedFormControl('', Validators.required),
    city: new UntypedFormControl('', Validators.required),
    latitude: new UntypedFormControl(0, [Validators.required, Validators.min(-90), Validators.max(90)]),
    longitude: new UntypedFormControl(0, [Validators.required, Validators.min(-180), Validators.max(180)]),
  });

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private pageInfoService: PageInfoService,
    private facilityTypeService: FacilityTypeService,
    private errorService: AppErrorService,
  ) {
    const params = this.activatedRoute.snapshot.params;
    this.facilityTypes = this.facilityTypeService.facilityTypes;

    this.company$ = this.companyService.getCompany(params.companyId).pipe(shareReplay());

    this.facility$ = params.facilityId ? this.facilityService.getFacility(params.facilityId).pipe(shareReplay()) : of(null);

    this.mapsUrl$ = this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((form) => {
        const road = form.address;
        const postal = form.postalCode;
        const city = form.city;
        // Yaboi wanna go directly to GMaps with the specified road, postal and city as url params; this will auto-redirect to the actual place with coordinates in the url appearing 'magically'
        if (road && !isNaN(+postal) && city) {
          return (
            'https://www.google.com/maps/place/' +
            road.split(' ').join('+') +
            '+' +
            postal.split(' ').join('+') +
            '+' +
            city.split(' ').join('+')
          );
        } else if (road) {
          // Say you don't really feel like typing in anything besides the address because maybe youre lazy that day
          // or maybe you feel absolutely certain that there's just one place in the world with the provided address...=> We will still redirect to GMaps but use the search functionality instead.
          return `https://www.google.com/maps/search/${road}${postal ? '+' + postal : ''}+${city ? '+' + city : ''}`;
        }
        return 'https://www.google.com/maps';
      }),
    );

    // There must be a company, and we retrieve it now, to show loading correctly at the beginning of the flow and to ensure it exists
    this.company$
      .pipe(
        first(),
        tap((company) => {
          this.company = company;
        }),
      )
      .subscribe();

    this.pageError$ = this.errorService.createPageErrorObservable([this.company$, this.facility$]);

    // Finally decide if we are editing or creating, populate form etc.
    if (params.facilityId) {
      this.creating = false;
      // Populate the form with the incoming facility
      this.populateFormWithIncomingFacility();

      this.pageInfo = this.pageInfoService.createEditFacilityPage('edit', params.companyId, params.facilityId);
    } else {
      this.pageInfo = this.pageInfoService.createEditFacilityPage('create');
    }
  }

  populateFormWithIncomingFacility() {
    this.facility$
      .pipe(
        first(),
        tap((facility) => {
          this.form.setValue({
            facilityName: facility.name,
            address: facility.addressRoad,
            postalCode: facility.addressPostal,
            city: facility.addressCity,
            latitude: facility.location.latitude,
            longitude: facility.location.longitude,
          });
        }),
      )
      .subscribe();
  }

  submit() {
    if (this.creating) {
      this.createFacility();
    } else {
      this.updateFacility();
    }
  }

  createFacility() {
    this.facilityService
      .createFacility({
        name: this.form.get('facilityName').value,
        addressRoad: this.form.get('address').value,
        addressPostal: this.form.get('postalCode').value,
        addressCity: this.form.get('city').value,
        company: this.company,
        facilityType: EFacilityType.iGrid,
        location: {
          latitude: this.form.get('latitude').value,
          longitude: this.form.get('longitude').value,
        },
      })
      .subscribe(() => {
        this.router.navigate(['/company/view', this.company.id]);
      });
  }

  updateFacility() {
    this.facilityService
      .patchFacility({
        id: this.activatedRoute.snapshot.params.facilityId,
        name: this.form.get('facilityName').value,
        addressRoad: this.form.get('address').value,
        addressPostal: this.form.get('postalCode').value,
        addressCity: this.form.get('city').value,
        company: this.company,
        facilityType: EFacilityType.iGrid,
        location: {
          latitude: this.form.get('latitude').value,
          longitude: this.form.get('longitude').value,
        },
      })
      .subscribe(() => {
        this.router.navigate(['/company/view', this.company.id]);
      });
  }
}
