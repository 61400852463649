<gbc-modal [title]="title$ | async">
  <gbc-modal-content>
    <div class="container">
      <form #createIGridSystemSettingForm [formGroup]="form" (ngSubmit)="save()">
        <gbc-input-container>
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.datapoint-type' | translate }}*</gbc-input-label>
          <gbc-dropdown
            formControlName="datapointType"
            placeholderTranslationString="app-select"
            [dropDownItems]="selectableDatapointTypes$ | async"
          >
          </gbc-dropdown>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.standard-key' | translate }}*</gbc-input-label>
          <gbc-dropdown
            formControlName="standardKey"
            placeholderTranslationString="app-select"
            [dropDownItems]="selectableStandardKeys$ | async"
          >
          </gbc-dropdown>
        </gbc-input-container>

        <gbc-input-container [ngClass]="{ error: form.controls.dataKey?.errors?.forbiddenDataKey }">
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.data-key' | translate }}*</gbc-input-label>
          <gbc-input-field inputType="text" placeholderTranslationString="app-select" formControlName="dataKey"> </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.order' | translate }}</gbc-input-label>
          <gbc-input-field inputType="number" placeholderTranslationString="app-select" formControlName="order"> </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.custom-name' | translate }}</gbc-input-label>
          <gbc-input-field
            inputType="text"
            placeholderTranslationString="{{ form.disabled ? '' : 'app-name' }}"
            formControlName="customName"
          >
          </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container class="datapoint-visible">
          <gbc-input-label>{{ 'add-edit-igrid-system-datapoint.visible' | translate }}</gbc-input-label>
          <gbc-toggle formControlName="visible"></gbc-toggle>
        </gbc-input-container>

        <div *ngIf="form.controls.dataKey?.errors?.forbiddenDataKey as forbiddenDataKeyError" class="alert alert-danger">
          {{ 'add-edit-igrid-system-datapoint.invalid-data-key' | translate: { forbiddenDataKey: forbiddenDataKeyError?.value } }}
        </div>

        <div class="form-group">
          <div *ngFor="let error of form.errors" class="error-container alert alert-danger">
            {{ error }}
          </div>
          <gbc-input-container class="action-button-row">
            <ng-container *ngIf="data.editable; else readOnlyBtn">
              <button gbc-button primary [disabled]="form.invalid || !form.dirty" (click)="save()" class="primary">
                {{ 'app-done' | translate }}
              </button>
              <button gbc-button (click)="cancel()">
                {{ 'app-cancel' | translate }}
              </button>
            </ng-container>
            <ng-template #readOnlyBtn>
              <button gbc-button (click)="cancel()">
                {{ 'app-ok' | translate }}
              </button>
            </ng-template>
          </gbc-input-container>
        </div>
      </form>
    </div>
  </gbc-modal-content>
</gbc-modal>
