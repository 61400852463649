import { ChangeDetectorRef, OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment_ from 'moment';
import { Subscription, timer } from 'rxjs';
const moment = moment_;

@Pipe({
  name: 'timeAgo',
  pure: false,
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  lastInput: number = null;
  lastOutput: string = null;
  subscription = new Subscription();

  public constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
  ) {
    // we need to regularly re-check that the formatted value should not change
    // A timer is sub-optimal - we could instead calculate exactly when the text would change,
    // but this is a simpler solution and performance will be acceptable because we do not
    // trigger angular change detection unless the formatted value actually changes.
    this.subscription.add(
      timer(10000, 10000).subscribe(() => {
        const newOutput = this.format(this.lastInput);
        if (newOutput !== this.lastOutput) {
          this.changeDetectorRef.markForCheck();
        }
      }),
    );
  }

  transform(value): string {
    this.lastInput = value;
    this.lastOutput = this.format(value);
    return this.lastOutput;
  }

  private format(value): string {
    if (moment().diff(value, 'seconds') < 60) {
      return this.translate.instant('time-ago.just-now');
    } else {
      return moment(value).fromNow();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
