import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeveloperUserPageComponent } from './pages/developer/developer-user-page/developer-user-page.component';
import { DeveloperFacilityPageComponent } from './pages/developer/facility-page/developer-facility-page.component';
import { DeveloperInstallationsPageComponent } from './pages/developer/developer-installations-page/developer-installations-page.component';
import { DeveloperCompanyPageComponent } from './pages/developer/developer-company-page/developer-company-page.component';
import { ServicegroupPageComponent } from './pages/developer/servicegroup-page/servicegroup-page.component';
import { OverviewPageComponent } from './pages/overview-page/overview-page.component';
import { CreateEditCompanyComponent } from './pages/create-edit-company/create-edit-company.component';
import { CreateEditFacilityComponent } from './pages/create-edit-facility/create-edit-facility.component';
import { DeveloperGuard } from './guards/developer.guard';
import { AccessClaimsGroupPageComponent } from './pages/developer/accessclaimsgroup-page/accessclaimsgroup-page.component';
import { DeveloperSupportPageComponent } from './pages/developer/developer-support-page/developer-support-page.component';
import { CommissionGuard } from './guards/commission.guard';
import { CommissionComponent } from './pages/developer/commission/commission.component';
import { RootPageComponent } from './pages/root-page/root-page.component';
import { AuthService, EmptyComponent } from 'shared';
import { CreateEditIGridInstallationComponent } from './pages/create-edit-igrid-installation/create-edit-igrid-installation.component';
import { IGridInstallationPageComponent } from './pages/igrid-installation-page/igrid-installation-page.component';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { CreateEditUserComponent } from 'projects/serviceportal/src/app/pages/create-edit-user/create-edit-user.component';
import { CompanyPageComponent } from 'projects/serviceportal/src/app/pages/company-page/company-page.component';
import { FacilityPageComponent } from 'projects/serviceportal/src/app/pages/facility-page/facility-page.component';
import { ArchivePageComponent } from 'projects/serviceportal/src/app/pages/developer/archive-page/archive-page.component';

export const routes: Routes = [
  {
    path: 'callback',
    component: EmptyComponent,
  },
  {
    path: 'logout/callback',
    component: EmptyComponent,
  },
  {
    path: 'silent-renew.html',
    component: EmptyComponent,
  },
  {
    path: '',
    component: RootPageComponent,
    canActivate: [AuthService],
    children: [
      {
        path: 'overview',
        component: OverviewPageComponent,
      },
      // Company
      {
        path: 'company/create',
        component: CreateEditCompanyComponent,
      },
      {
        path: 'company/create/:id',
        component: CreateEditCompanyComponent,
      },
      {
        path: 'company/view/:companyId',
        component: CompanyPageComponent,
      },

      // Facility

      {
        path: 'facility/create/:companyId',
        component: CreateEditFacilityComponent,
      },
      {
        path: 'facility/create/:companyId/:facilityId',
        component: CreateEditFacilityComponent,
      },
      {
        path: 'facility/view/:facilityId',
        component: FacilityPageComponent,
      },

      // Users
      {
        path: 'users/create',
        component: CreateEditUserComponent,
      },
      {
        path: 'users/create/:userId',
        component: CreateEditUserComponent,
      },
      {
        path: 'users/view/:userId',
        component: UserPageComponent,
      },

      //
      // iGrid pages
      //
      {
        path: 'igrid/create',
        component: CreateEditIGridInstallationComponent,
      },
      // Edit BuildigConnect installation
      {
        path: 'igrid/create/:facilityId/:installationId',
        component: CreateEditIGridInstallationComponent,
      },
      // Installation page
      {
        path: 'igrid/installation/:facilityId/:installationId',
        component: IGridInstallationPageComponent,
      },

      // Support pages
      {
        path: 'commission',
        canActivate: [CommissionGuard],
        component: CommissionComponent,
      },
      {
        path: 'commission/:installationId',
        canActivate: [CommissionGuard],
        component: CommissionComponent,
      },
      {
        path: 'support',
        component: DeveloperSupportPageComponent,
      },
      {
        path: 'archive',
        component: ArchivePageComponent,
      },
      //
      // Developer pages
      //
      {
        path: 'developer',
        canActivateChild: [DeveloperGuard],
        canActivate: [DeveloperGuard],
        children: [
          {
            path: 'users',
            component: DeveloperUserPageComponent,
          },
          {
            path: 'facilities',
            component: DeveloperFacilityPageComponent,
          },
          {
            path: 'installations',
            component: DeveloperInstallationsPageComponent,
          },
          {
            path: 'companies',
            component: DeveloperCompanyPageComponent,
          },
          {
            path: 'servicegroups',
            component: ServicegroupPageComponent,
          },
          {
            path: 'accessclaimsgroups',
            component: AccessClaimsGroupPageComponent,
          },
        ],
      },
      // Catch all
      {
        path: '**',
        redirectTo: '/overview',
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    enableTracing: false,
    scrollPositionRestoration: 'enabled'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
