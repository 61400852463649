<div class="page">
  <button (click)="createNewAccessClaimsGroup()">Create new AccessClaimsGroup</button>
  <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button>

  <!-- ListServiceGroup -->
  <div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" sortable="Name">Name</th>
          <th scope="col" sortable="AccessClaimsCount">AccessClaims Count</th>
          <th *ngIf="uiState === 'edit'" scope="col">Modify</th>
          <th *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody *ngIf="accessClaimsGroups$ | async | sortByName as accessClaimsGroups">
        <!-- | async -->
        <tr *ngFor="let accessClaimsGroup of accessClaimsGroups">
          <th scope="row">{{ accessClaimsGroup.id }}</th>
          <td>{{ accessClaimsGroup.name }}</td>
          <td>{{ accessClaimsGroup.accessClaims.length }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyAccessClaimsGroup(accessClaimsGroup)">Modify</button>
          </td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteAccessClaimsGroup(accessClaimsGroup)">X</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- CreateUser -->
  <div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
    <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="activeRow ml-1" *ngIf="form.Id">
        ID:
        <label class="formElement">{{ form.Id }}</label>
      </div>
      <input class="formElement" placeholder="Name" type="text" formControlName="Name" />

      <ng-select
        *ngIf="accessClaims$ | async | sortByValue as accessClaims"
        class="select"
        [items]="accessClaims"
        [multiple]="true"
        placeholder="Select AccessClaims"
        formControlName="AccessClaims"
      >
      </ng-select>

      <button type="submit" [disabled]="!form.valid">Submit</button>
      <button type="reset" (click)="cancelNewAccessClaimsGroup()">Cancel</button>
    </form>
  </div>
</div>
