import { Component, Input } from '@angular/core';
import { DataPoint } from '../../interfaces/iGridDeviceState';
import { CommissionService } from '../../services/commission.service';

@Component({
  selector: 'app-data-point-list',
  templateUrl: './data-point-list.component.html',
  styleUrls: ['./data-point-list.component.scss'],
})
export class DataPointListComponent {
  @Input() public dataPoints: DataPoint[];

  constructor(private commissionService: CommissionService) {}

  getStateDetails(action: string): { svgUrl: string; messageKey: string } {
    return {
      svgUrl: this.commissionService.getStatusIcon(action),
      messageKey: 'create-edit-igrid-installation.data-point.message.' + action.toLowerCase(),
    };
  }
}
