import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByValue',
})
export class SortByValuePipe implements PipeTransform {
  transform(value: any[], ...args: any[]): any[] {
    if (value) {
      return value.sort((c1, c2) => {
        if (c1 > c2) {
          return 1;
        }

        if (c1 < c2) {
          return -1;
        }

        return 0;
      });
    }
    return value;
  }
}
