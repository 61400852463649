import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class TestInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const index = document.cookie.indexOf('=');
    const roleName = document.cookie.slice(index + 1, document.cookie.length);
    const headers = request.headers.set('Authorization', roleName);
    const cloned = request.clone({
      headers,
    });

    return next.handle(cloned);
  }
}
