import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { IBatchListItem } from '../interfaces/gisSource';

@Pipe({
  name: 'latestGisImportStatus',
})
export class LatestGisImportStatusPipe implements PipeTransform {
  transform(value: IBatchListItem[], ...args: unknown[]): unknown {
    if (value.length) {
      const [latestImport] = orderBy(value, (_import) => _import.created, 'desc');
      return latestImport.status;
    }
    return null;
  }
}
