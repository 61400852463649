import { Injectable, Injector } from '@angular/core';
import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { AsyncSubject, from, Observable } from 'rxjs';
import { TextModalComponent } from '../components/text-modal/text-modal.component';
import {
  ITextModalAction,
  ITextModalData,
  MODAL_CONTROLLER,
  MODAL_DATA,
  ModalController,
  OpenDialogOptions,
  Result,
} from '../interfaces/modal';
import { TranslateService } from '@ngx-translate/core';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(
    private overlay: Overlay,
    private translateService: TranslateService,
  ) {}

  public confirm(title: string, text: string, callback: () => any): void {
    this.showConfirmModal(title, text).subscribe((confirmed) => {
      if (confirmed) {
        callback();
      }
    });
  }

  public showConfirmModal(title: string, text: string): Observable<boolean> {
    return from(
      new Promise<boolean>((resolve) => {
        const actions: ITextModalAction[] = [
          {
            text: this.translateService.instant('modal-service.confirm-dialog-cancel'),
            cancel: true,
            handler: () => resolve(false),
          },
          {
            text: this.translateService.instant('modal-service.confirm-dialog-continue'),
            handler: () => resolve(true),
            type: 'primary',
          },
        ];
        this.showTextModal({ title, content: text, actions });
      }),
    );
  }

  public showErrorModal(title: string | undefined, content: string): Observable<null> {
    return from(
      new Promise<null>((resolve) => {
        const options: ITextModalData = {
          title: title || this.translateService.instant('modal-service.error-dialog-default-title'),
          content,
          actions: [
            {
              text: this.translateService.instant('modal-service.error-dialog-dismiss'),
              handler: () => resolve(null),
              cancel: true,
            },
          ],
        };
        this.showTextModal(options);
      }),
    );
  }

  public showTextModal(options: ITextModalData) {
    const resultObservable = this.openDialog<ITextModalAction>(TextModalComponent, { data: options }).pipe(shareReplay());
    resultObservable.subscribe((result) => {
      const cancelAction = options.actions.find((action) => action.cancel);
      if (result.dismissed && cancelAction && cancelAction.handler) {
        cancelAction.handler();
      } else if (!result.dismissed) {
        const handler = result.result.handler;
        if (handler) {
          handler();
        }
      }
    });
    return resultObservable;
  }

  public openDialog<T>(component: ComponentType<any>, { injector, data }: OpenDialogOptions): Observable<Result<T>> {
    const positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically();
    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
    });

    const result$ = new AsyncSubject<Result<any>>();

    const modalController: ModalController<any> = {
      dismiss: () => {
        overlayRef.dispose();
        result$.next({ dismissed: true, result: null });
        result$.complete();
      },
      complete: (result: any) => {
        overlayRef.dispose();
        result$.next({ dismissed: false, result });
        result$.complete();
      },
    };

    const modalInjector = Injector.create({
      providers: [
        { provide: MODAL_DATA, useValue: data },
        { provide: MODAL_CONTROLLER, useValue: modalController },
      ],
      parent: injector,
    });
    const modalPortal = new ComponentPortal(component, null, modalInjector);
    overlayRef.attach(modalPortal);

    return result$;
  }
}
