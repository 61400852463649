import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => {};
export const CHECKBOX_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CheckboxComponent),
  multi: true,
};

@Component({
  selector: 'gbc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [CHECKBOX_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CheckboxComponent implements OnInit {
  @Input() public name: string;
  @Input() public formControlName: string;

  // Ng Model
  private innerValue;

  @Input()
  public disabled = false;
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  constructor() {}

  ngOnInit(): void {
    if (!this.name && this.formControlName) {
      this.name = this.formControlName;
    }
  }

  //
  // Ng Model stuff
  //

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v === this.innerValue) {
      return;
    }

    this.innerValue = v;
    this.onChangeCallback(v);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }

    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
