import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxDropzoneChangeEvent } from 'ngx-dropzone';
import { ReplaySubject, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { MODAL_DATA, MODAL_CONTROLLER, ModalController, AppError, AppErrorService } from 'shared';
import { ICompany } from '../../interfaces/company';
import { EGeometryClass } from '../../interfaces/gisSource';
import { CompanyService } from '../../services/company.service';
import { GisSourceService } from '../../services/gis-source.service';

@Component({
  selector: 'app-add-gis-data-source-dialog',
  templateUrl: './add-gis-data-source-dialog.component.html',
  styleUrls: ['./add-gis-data-source-dialog.component.scss'],
})
export class AddGisDataSourceDialogComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA) public data: { name?: string; company?: ICompany },
    @Inject(MODAL_CONTROLLER) private controller: ModalController<File>,
    private gisSourceService: GisSourceService,
    private companyService: CompanyService,
    private translateService: TranslateService,
    public fb: UntypedFormBuilder,
  ) {}

  public form = this.fb.group({
    description: ['', Validators.required],
    file: [null, Validators.required],
    geometryClass: [null, Validators.required],
    company: [null, Validators.required],
    autoIndexOnClose: [true], // controlled by the gbc-toggle, not domain relevant (omit when sending form to API)
  });
  public companies$ = this.companyService.companies$;
  public eGeometryClass = EGeometryClass;
  public geometryClasses$ = this.translateService.stream('gis-sources-list.geometry-classes').pipe(
    /* Auto-handle handle translation of geometryClasses even when user changes language while this dialog is open */
    map((geometryClassTranslations) =>
      Object.values(this.eGeometryClass).map((geometryClass) => ({
        name: geometryClassTranslations[geometryClass],
        value: geometryClass,
      })),
    ),
  );
  public uploading$ = new ReplaySubject<boolean>();
  public fileUploaded = false;

  public ngOnInit(): void {
    /* Initialize the company form group with the provided company (if provided by parent component) */
    if (this.data.company) {
      this.form.patchValue({ company: this.data.company });
    }
  }

  public async upload() {
    if (this.form.value.file) {
      const formData = new FormData();
      const { description, file, geometryClass, company } = this.form.value;
      formData.set('CompanyId', company.id);
      formData.set('Description', description);
      formData.set('File', file);
      formData.set('GeometryClass', geometryClass.value); // We've mapped the enum itself to the 'value' property for translation purposes.
      this.uploading$.next(true);
      this.gisSourceService
        .uploadGisSource(formData)
        .pipe(
          first((res) => !!res),
          catchError((err) => {
            this.uploading$.next(false);
            return throwError(err);
          }),
        )
        .subscribe(
          (id) => {
            if (this.form.value.autoIndexOnClose) {
              this.gisSourceService
                .indexGisSource(id)
                .pipe(first())
                .subscribe(() => {
                  // wait just a little bit to allow the backend to have added items to the import array before closing.
                  setTimeout(() => {
                    this.closeDialog();
                  }, 150);
                });
            } else {
              this.closeDialog();
            }
          },
          (_) => {
            this.form.patchValue({ file: null });
            this.form.setErrors({ uploadFailed: true });
            this.uploading$.next(false);
          },
          () => {
            this.uploading$.next(false);
          },
        );
    }
  }

  public closeDialog() {
    this.controller.complete(this.form.value.file);
  }

  public async onFileSelect({ addedFiles }: NgxDropzoneChangeEvent) {
    // We will be taking the first element in list as we only permit the user to upload a single source at a time.
    const [file] = addedFiles;
    if (file.type === 'application/x-zip-compressed') {
      this.form.patchValue({ file });
      this.form.setErrors({ invalidFileFormat: false });
      this.form.setErrors({ uploadFailed: false });
      this.form.updateValueAndValidity();
    } else {
      this.form.patchValue({ file: null });
      this.form.setErrors({ invalidFileFormat: true });
    }
  }

  public onFileRemove() {
    this.form.patchValue({ file: null });
  }
}
