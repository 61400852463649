import { Component, OnInit, ɵConsole } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { ServicegroupService } from '../../../services/servicegroup.service';
import { IServiceGroup } from 'projects/serviceportal/src/app/interfaces/serviceGroup';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}
@Component({
  selector: 'app-servicegroup-page',
  templateUrl: './servicegroup-page.component.html',
  styleUrls: ['./servicegroup-page.component.scss'],
})
export class ServicegroupPageComponent implements OnInit {
  uiState = UIState.edit;

  companies$;
  coreusers$;
  form;
  servicegroups$;

  selectedServiceGroupId: number;

  constructor(
    private userService: UserService,
    private companyService: CompanyService,
    private serviceGroupService: ServicegroupService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.companies$ = companyService.companies$;
    this.coreusers$ = userService.usersWithCompany$;
    this.servicegroups$ = serviceGroupService.serviceGroupsChanged$;

    this.form = formBuilder.group({
      Id: [''],
      Name: ['', Validators.required],
      Users: [''],
      Companies: [''],
    });
  }

  ngOnInit() {}

  private getFormAsServiceGroup(): IServiceGroup {
    return {
      name: this.form.get('Name').value,
      userIds: this.form.get('Users').value,
      companyIds: this.form.get('Companies').value,
    };
  }

  private getFormAsServiceGroupWithId(): IServiceGroup {
    return {
      id: this.form.get('Id').value,
      name: this.form.get('Name').value,
      userIds: this.form.get('Users').value,
      companyIds: this.form.get('Companies').value,
    };
  }

  onSubmit() {
    // new user
    if (this.selectedServiceGroupId === null) {
      this.serviceGroupService.createServiceGroup(this.getFormAsServiceGroup()).then(
        (res) => {
          this.reloadServiceGroups();
        },
        (err) => {},
      );
    } else {
      // editing existing user
      this.serviceGroupService.patchServiceGroup(this.getFormAsServiceGroupWithId()).then(
        (res) => {
          this.reloadServiceGroups();
        },
        (err) => {},
      );
    }

    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.list;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewServiceGroup() {
    this.uiState = UIState.create;
    this.selectedServiceGroupId = null;
    this.form.patchValue({
      Name: '',
      Users: '',
      Companies: '',
    });
  }

  deleteServiceGroup(servicegroup: IServiceGroup) {
    if (confirm('Confirm deletion of: ' + servicegroup.name)) {
      this.serviceGroupService.deleteServiceGroup(servicegroup).then(
        (res) => {
          this.reloadServiceGroups();
        },
        (err) => {},
      );
    }
  }

  modifyServiceGroup(serviceGroup: IServiceGroup) {
    this.uiState = UIState.modify;
    this.selectedServiceGroupId = serviceGroup.id;
    this.form.patchValue({
      Id: serviceGroup.id,
      Name: serviceGroup.name,
      Companies: serviceGroup.companyIds,
      Users: serviceGroup.userIds,
    });
  }

  cancelNewUser() {
    this.uiState = UIState.edit;
  }

  private reloadServiceGroups() {
    this.serviceGroupService.reloadServiceGroups();
  }
}
