import { Component, Input, HostBinding } from '@angular/core';
import { PolymorpheusContent } from '@tinkoff/ng-polymorpheus';

export type MultiDimensionalArray<T> = T | ReadonlyArray<MultiDimensionalArray<T>>;

@Component({
  selector: 'app-multi-dimensional-view',
  templateUrl: './multi-dimensional-view.component.html',
  styleUrls: ['./multi-dimensional-view.component.scss'],
})
export class MultiDimensionalViewComponent<T> {
  @Input()
  value: MultiDimensionalArray<T> = [];
  @Input()
  childPropName = '';
  @Input()
  content: PolymorpheusContent<never> | null = null;

  @HostBinding('class._array')
  get isArray(): boolean {
    return Array.isArray(this.value);
  }

  @HostBinding('class._parent-node')
  get isRootNode(): boolean {
    return this.childPropName && this.value[this.childPropName];
  }
}
