<!-- <form [formGroup]="form">
  <app-searchbar formControlName="search" [placeholder]="'users-list.search-user' | translate"></app-searchbar>
</form> -->

<gbc-tile [title]="'gis-sources-list.title' | translate" style="padding-top: 2em; margin-top: 0">
  <gbc-table *ngIf="gisSources?.length; else noSources" [clickableRows]="false">
    <th gbc-table-header colspan="2">
      {{ 'gis-sources-list.name' | translate }}
    </th>
    <th gbc-table-header colspan="2">
      {{ 'gis-sources-list.geometry-class' | translate }}
    </th>
    <th gbc-table-header colspan="3">
      {{ 'gis-sources-list.created-by' | translate }}
    </th>
    <th gbc-table-header colspan="2">
      {{ 'gis-sources-list.imported' | translate }}
    </th>
    <th gbc-table-header colspan="3">
      {{ 'gis-sources-list.status' | translate }}
    </th>
    <th gbc-table-header colspan="4">
      {{ 'gis-sources-list.actions' | translate }}
    </th>

    <tr gbc-table-row *ngFor="let gisSource of gisSources">
      <td gbc-table-cell colspan="2">{{ gisSource.description }}</td>
      <td gbc-table-cell colspan="2">
        {{ 'gis-sources-list.geometry-classes.' + gisSource.geometryClass | translate }}
      </td>
      <td gbc-table-cell colspan="3">{{ gisSource.owner }}</td>
      <td gbc-table-cell colspan="2">
        {{ (gisSource.imports | latestGisImportTime) || ('app-never' | translate) }}
      </td>
      <td gbc-table-cell colspan="3">
        <ng-template #defaultStatusCell>
          {{ gisSource.imports | latestGisImportStatus }}
        </ng-template>
        <ng-container
          #errorStatusCell
          *ngIf="
            (gisSource.imports | latestGisImportStatus) === eBatchStatus.Failed && (gisSource.imports | latestGisImport) as latestImport;
            else defaultStatusCell
          "
        >
          <a
            href="javascript:void(0)"
            *ngIf="latestImport.validationMessages?.length; else nonInteractiveStatus"
            (click)="viewValidationErrors(latestImport)"
          >
            {{ gisSource.imports | latestGisImportStatus }} </a
          ><br />
          <ng-template #nonInteractiveStatus>
            {{ gisSource.imports | latestGisImportStatus }}
          </ng-template>
          <span class="status-message">{{ latestImport.statusMessage }}</span>
          <br />
          <span class="status-message" *ngIf="!latestImport.validationMessages?.length">
            {{ '(' + ('gis-sources-list.validation-errors' | translate) + ' N/A)' || '' }}
          </span>
        </ng-container>
      </td>
      <td gbc-table-cell colspan="4">
        <div class="action-buttons-container">
          <button [disabled]="gisSource.loading" gbc-button danger class="delete-button" (click)="deleteGisSource(gisSource)">
            <img class="delete-icon" src="./assets/svgs/trash-can_outline.svg" />
            {{ 'gis-sources-list.delete' | translate }}
          </button>
          <button gbc-button class="import-button" [disabled]="gisSource.loading" (click)="indexGisSource(gisSource)">
            <img class="delete-icon" src="./assets/svgs/clipboard-replace_outline.svg" />
            {{ 'gis-sources-list.import' | translate }}
          </button>
        </div>
      </td>
    </tr>
  </gbc-table>
  <ng-template #noSources>
    <div class="no-sources-label">
      <p>{{ 'gis-sources-list.no-gis-sources' | translate }}</p>
    </div>
  </ng-template>
</gbc-tile>
