import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'randomColor',
})
export class RandomColorPipe implements PipeTransform {
  transform(id: number, ...args: unknown[]): unknown {
    const o = Math.round;
    const r = Math.random;
    const s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + (r() / (Math.PI + Math.E) + 0.1).toFixed(1) + ')';
  }
}
