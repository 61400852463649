import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Observable, combineLatest, Subject, ReplaySubject } from 'rxjs';
import { AppError, AppErrorService, CommandService, ECommissionStatus } from 'shared';
import { CreateInstallationFormComponent } from '../create-installation-form/create-installation-form.component';
import { first, shareReplay, map, tap } from 'rxjs/operators';
import { FacilityService } from '../../services/facility.service';
import { CompanyService } from '../../services/company.service';
import { InstallationsService } from '../../services/installations.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { IGridInstallationConfigurationFormComponent } from '../igrid-installation-configuration-form/igrid-installation-configuration-form.component';
import { IGridInstallationModuleConfigurationFormComponent } from '../igrid-installation-module-configuration-form/igrid-installation-module-configuration-form.component';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { SystemRoutingService } from '../../services/system-routing.service';
import { IFacility } from '../../interfaces/facility';
import { SystemStateService } from '../../services/system-state.service';
import { DataPoint, IIGridSystemStateDeviceStateEnum, StateResponse, SystemStateSetting } from '../../interfaces/iGridDeviceState';
import { IGridSummaryPageWarning } from '../igrid-summary-page/igrid-summary-page.component';
import { IPostSystemDTO, IUpdateSystemDTO, KeysAndAction, PathAndAction } from '../../interfaces/installation';
import { ICompany } from '../../interfaces/company';
import { IIGridInstallationType } from '../../interfaces/iGridInstallationConfiguration';

export type FormSummary = {
  company: ICompany;
  facility: IFacility;
  deviceGroup: string;
  systemType: IIGridInstallationType;
  serialNumber: string;
  deviceId: string;
  commissionStatus: ECommissionStatus;
  systemId: string;
  initialFacilityId: string;
};

@Component({
  selector: 'app-edit-igrid-installation-wizard',
  templateUrl: './edit-igrid-installation-wizard.component.html',
  styleUrls: ['./edit-igrid-installation-wizard.component.scss'],
})
export class EditIGridInstallationWizardComponent implements OnInit {
  public pageError$: Observable<AppError>;
  @Input()
  private initialCompanyId: number;

  @Input()
  private initialFacilityId: string;

  @Input()
  private installationId: string;
  public formSummary$ = new ReplaySubject<FormSummary>(1);
  public summarySystemState$ = new ReplaySubject<StateResponse>(1);
  public summaryWarning$ = new ReplaySubject<IGridSummaryPageWarning>(1);
  private isEditMode = false;

  @ViewChild(CreateInstallationFormComponent)
  createInstallationFormComponent: CreateInstallationFormComponent;
  @ViewChild(IGridInstallationConfigurationFormComponent)
  createInstallationConfigurationFormComponent: IGridInstallationConfigurationFormComponent;
  @ViewChild(IGridInstallationModuleConfigurationFormComponent)
  installationModuleConfigurationFormComponent: IGridInstallationModuleConfigurationFormComponent;

  private installation: Subject<IIGridSystem> = new Subject<IIGridSystem>();
  public installation$ = this.installation.asObservable();
  public backDisabled = true;
  public deviceRoutes$ = this.routingService.allSystemRoutings$.pipe(map((d) => d.deviceroutes));
  public outgoingRoutes$ = combineLatest([this.deviceRoutes$, this.formSummary$.pipe(first((s) => !!s))]).pipe(
    map(([routes, system]) => routes[system?.deviceId as string] ?? []),
  );
  public ingoingRoutes$ = combineLatest([this.deviceRoutes$, this.formSummary$.pipe(first((s) => !!s))]).pipe(
    map(([routes, system]) => {
      const incomingRoutes = [];
      for (const sourceDeviceId of Object.keys(routes)) {
        if (routes[sourceDeviceId].some((d) => d.targetDeviceId === system?.deviceId)) {
          incomingRoutes.push(sourceDeviceId);
        }
      }
      return incomingRoutes;
    }),
  );
  public routings$ = combineLatest([this.outgoingRoutes$, this.ingoingRoutes$]).pipe(
    map(([outgoing, incoming]) => ({ outgoing, incoming })),
  );

  constructor(
    private facilityService: FacilityService,
    private companyService: CompanyService,
    private installationService: InstallationsService,
    private errorService: AppErrorService,
    private commandService: CommandService,
    private router: Router,
    public userService: UserService,
    private routingService: SystemRoutingService,
    private systemStateService: SystemStateService,
  ) {}

  ngOnInit(): void {
    this.routingService.getAllSystemRoutings();
    const initializeObservables: Observable<any>[] = [this.companyService.companies$.pipe(first())];

    if (this.installationId) {
      this.initializeEditMode(initializeObservables);
    } else if (this.initialCompanyId && this.initialFacilityId) {
      this.initializeNewInstallation(initializeObservables);
    }

    this.pageError$ = this.errorService.createPageErrorObservable(initializeObservables);
  }

  public stepperChange({ selectedIndex }: StepperSelectionEvent) {
    switch (selectedIndex) {
      case 1:
        this.backDisabled = false;
        break;
      case 2:
        this.clearSummaryData();
        this.updateSummaryData();
        break;
      default:
        break;
    }
  }

  public stepperExit() {
    if (this.isEditMode) {
      this.router.navigate(['igrid', 'installation', this.initialFacilityId, this.installationId]);
    } else {
      this.router.navigate(['/facility/view', this.initialFacilityId, { tab: 'installations' }]);
    }
  }

  public completeWizard() {
    combineLatest([this.formSummary$, this.summarySystemState$]).subscribe(([formSummary, summarySystemState]) =>
      (this.installationId
        ? this.installationService.updateIGridInstallation(this.getUpdateDto(formSummary, summarySystemState))
        : this.installationService.createIGridInstallation(
            (formSummary.facility as IFacility).id,
            this.getPostDto(formSummary, summarySystemState),
          )
      )
        .pipe(tap((installation) => this.router.navigate(['/igrid/installation', (formSummary.facility as IFacility).id, installation.id])))
        .subscribe(),
    );
  }

  private clearSummaryData() {
    this.summaryWarning$.next(undefined);
    this.summarySystemState$.next(undefined);
  }

  private updateSummaryData() {
    const { company, facility } = this.createInstallationFormComponent.form.value;
    const {
      installationType: systemType,
      serialNumber,
      hardwareId: deviceId,
      deviceGroup,
      commissionStatus,
    } = this.createInstallationConfigurationFormComponent.form.value;

    if (systemType.syncDeviceSettingsViaIOTHub) {
      if (this.isStateFlow(systemType)) {
        this.executeSystemStateService(deviceId, systemType.id, serialNumber);
      }
    }

    this.formSummary$.next({
      company,
      facility,
      systemType,
      serialNumber,
      deviceGroup,
      deviceId,
      commissionStatus,
      systemId: this.installationId,
      initialFacilityId: this.initialFacilityId,
    });
  }

  private initializeEditMode(initializeObservables: Observable<any>[]): void {
    this.isEditMode = true;
    const initialFacility$ = this.facilityService.getIGridFacility(this.initialFacilityId).pipe(shareReplay());
    initializeObservables.push(initialFacility$);

    initialFacility$.subscribe((facility) => {
      this.createInstallationFormComponent.setCompanyAndFacility(facility?.company, facility);
      const installation = facility.installations.find((i) => i.id.toString() === this.installationId) as IIGridSystem;
      this.installation.next(installation);
    });
  }

  private initializeNewInstallation(initializeObservables: Observable<any>[]): void {
    const initialCompany$ = this.companyService.getCompany(this.initialCompanyId).pipe(shareReplay());
    const initialFacility$ = this.facilityService.getFacility(this.initialFacilityId).pipe(shareReplay());
    initializeObservables.push(initialCompany$, initialFacility$);

    combineLatest([initialCompany$, initialFacility$])
      .pipe(
        tap(([company, facility]) => {
          this.createInstallationFormComponent.setCompanyAndFacility(company, facility);
        }),
      )
      .subscribe();
  }

  private executeSystemStateService(deviceId: string, installationTypeId: number, serialNumber: string) {
    this.commandService
      .execute(() => this.systemStateService.getState(deviceId, installationTypeId, serialNumber), {
        noMessageOnSuccess: true,
      })
      .subscribe((systemState) => {
        this.setupStateSummary(systemState);
      });
  }

  private setupStateSummary(systemState: StateResponse) {
    const isDevicePresentInDis = systemState.state.system.disState === IIGridSystemStateDeviceStateEnum.exist;

    if (!isDevicePresentInDis) {
      this.summaryWarning$.next({
        textTranslationId: 'add-edit-igrid-system-datapoint.state-warning.missing-in-dis-text',
        titleTranslationId: 'add-edit-igrid-system-datapoint.state-warning.missing-in-dis-title',
      });
    }
    this.summarySystemState$.next(systemState);
  }

  private isStateFlow(installationType: IIGridInstallationType): boolean {
    const approvedInstallationTypes = ['iGRID Pressure Zone', 'iGRID Temperature Zone'];
    return approvedInstallationTypes.includes(installationType.systemTypeCategoryName);
  }

  private getPostDto(createValues: FormSummary, systemState: StateResponse): IPostSystemDTO {
    const baseDto: IPostSystemDTO = {
      companyId: createValues.facility?.company?.id || createValues.company?.id,
      systemTypeId: createValues.systemType.id,
      deviceId: createValues.deviceId,
      serialNumber: createValues.serialNumber,
      deviceGroup: createValues.deviceGroup,
    };
    if (systemState) {
      baseDto.dataKeysAndActions = this.generateDataKeysAndActions(systemState?.state?.system?.datapoints) ?? [];
      baseDto.settingPathsAndActions = systemState?.state?.system?.settings ?? [];
    }
    return baseDto;
  }

  private getUpdateDto(updateValues: FormSummary, systemState: StateResponse): IUpdateSystemDTO {
    const baseDto: IUpdateSystemDTO = {
      systemId: updateValues.systemId,
      systemTypeId: updateValues.systemType.id,
      facilityId: updateValues.facility.id,
      companyId: updateValues.company.id,
    };
    if (systemState) {
      baseDto.dataKeysAndActions = this.generateDataKeysAndActions(systemState?.state?.system?.datapoints) ?? [];
      baseDto.settingPathsAndActions = systemState?.state?.system?.settings ?? [];
    }
    return baseDto;
  }

  private generateDataKeysAndActions(dataPoints: DataPoint[]): KeysAndAction[] {
    if (!dataPoints) return;
    return dataPoints.map((item) => ({
      dataKey: item.dataKey,
      action: item.action,
    }));
  }

  prepareInstallationObject(summary: FormSummary): Partial<IIGridSystem> {
    return {
      commissionStatus: summary.commissionStatus,
      serialNumber: summary.serialNumber,
      deviceGroup: summary.deviceGroup,
      deviceId: summary.deviceId,
      systemTypeDescription: summary.systemType.systemTypeCategoryName,
      systemTypeVariant: summary.systemType.description,
    };
  }
}
