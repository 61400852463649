import { Pipe, PipeTransform } from '@angular/core';
import { IIGridSystem } from '../interfaces/iGridSystem';

@Pipe({
  name: 'filterByTab',
})
export class FilterByTabPipe implements PipeTransform {
  transform(value: IIGridSystem[], commissionStates: string[]): any {
    return value.filter((installation) => commissionStates.find((cs) => cs.toLowerCase() === installation.commissionStatus.toLowerCase()));
  }
}
