import { Pipe, PipeTransform } from '@angular/core';
import { IIGridSystem } from '../interfaces/iGridSystem';

@Pipe({
  name: 'sortBySerialnumber',
})
export class SortBySerialnumberPipe implements PipeTransform {
  transform(installations: IIGridSystem[], ...args: unknown[]): unknown {
    if (installations) {
      return installations.sort((c1, c2) => {
        if (c1.serialNumber > c2.serialNumber) {
          return 1;
        }

        if (c1.serialNumber < c2.serialNumber) {
          return -1;
        }

        return 0;
      });
    }
    return installations;
  }
}
