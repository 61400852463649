import { Injectable } from '@angular/core';

import { AsyncSubject, BehaviorSubject } from 'rxjs';
import { User } from 'oidc-client';

function getCookie(name) {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
}

@Injectable({
  providedIn: 'root',
})
export class TestAuthService  {
  public currentUser = new BehaviorSubject<User>(null);
  public authenticated = new AsyncSubject<boolean>();

  constructor() {
    if (getCookie('ROLE_COOKIE')) {
      setTimeout(() => {
        this.currentUser.next({
          id_token: '1',
          access_token: '',
          token_type: '',
          scope: '',
          profile: {
            name: '',
            iss: null,
            sub: null,
            aud: null,
            iat: null,
            exp: null,
          },
          state: null,
          toStorageString: () => '',
          expires_at: 0,
          // important that this is set and is above 10. Will not request current user if less that 10.
          expires_in: 10000,
          expired: false,
          scopes: [],
        });
        this.authenticated.next(true);
        this.authenticated.complete();
      }, 500);
    } else {
      this.authenticated.next(false);
      this.authenticated.complete();
    }
  }

  public signout() {}

  public signinRedirect() {}

  canActivate() {
    return true;
  }
}
