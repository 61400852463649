import { NgModule } from '@angular/core';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { TileComponent } from './components/tile/tile.component';
import { InputComponent } from './components/input/input.component';
import { IconButtonComponent } from './components/icon-button/icon-button.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalButtonComponent } from './components/modal-button/modal-button.component';
import { ModalButtonsComponent } from './components/modal-buttons/modal-buttons.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { TextModalComponent } from './components/text-modal/text-modal.component';
import { PageSpinnerComponent } from './components/page-spinner/page-spinner.component';
import { PageErrorHandlerComponent } from './components/page-error-handler/page-error-handler.component';
import { ErrorTileComponent } from './components/error-tile/error-tile.component';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { TopbarDropdownComponent } from './components/topbar-dropdown/topbar-dropdown.component';
import { TopbarDropdownItemComponent } from './components/topbar-dropdown-item/topbar-dropdown-item.component';
import { PageContainerComponent } from './components/page-container/page-container.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { SnackBarComponent } from './components/snack-bar/snack-bar.component';
import { CommandSpinnerComponent } from './components/command-spinner/command-spinner.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TabComponent } from './components/tab/tab.component';
import { TabsComponent } from './components/tabs/tabs.component';
import { TableComponent } from './components/table/table.component';
import { TableRowComponent } from './components/table-row/table-row.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { TimeWithAgoPipe } from './pipes/time-with-ago.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { EmptyComponent } from './components/empty-component/empty.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { InputContainerComponent } from './components/input-container/input-container.component';
import { InputLabelComponent } from './components/input-label/input-label.component';
import { InputFieldComponent } from './components/input-field/input-field.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { LanguagePickerComponent } from './components/language-picker/language-picker.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { ItemListSelectorComponent } from './components/item-list-selector/item-list-selector.component';
import { FormHintComponent } from './components/form-hint/form-hint.component';
import { ValidationMessagesModalComponent } from './components/validation-messages-modal/validation-messages-modal.component';
import { TableChevronHeaderComponent } from './components/table-chevron-header/table-chevron-header.component';

@NgModule({
  declarations: [
    EmptyComponent,
    ButtonComponent,
    CheckboxComponent,
    TextButtonComponent,
    TileComponent,
    InputComponent,
    IconButtonComponent,
    ModalComponent,
    ModalButtonComponent,
    ModalButtonsComponent,
    ModalContentComponent,
    TextModalComponent,
    PageSpinnerComponent,
    PageErrorHandlerComponent,
    ErrorTileComponent,
    LoadingSpinnerComponent,
    TopbarComponent,
    TopbarDropdownComponent,
    TopbarDropdownItemComponent,
    PageContainerComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    SnackBarComponent,
    CommandSpinnerComponent,
    StepperComponent,
    TabComponent,
    TabsComponent,
    TableComponent,
    TableRowComponent,
    TableCellComponent,
    TimeWithAgoPipe,
    TimeAgoPipe,
    TableHeaderComponent,
    InputContainerComponent,
    InputLabelComponent,
    InputFieldComponent,
    DropdownComponent,
    LanguagePickerComponent,
    ToggleComponent,
    ItemListSelectorComponent,
    FormHintComponent,
    ValidationMessagesModalComponent,
    TableChevronHeaderComponent,
  ],
  imports: [
    NgSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    TranslateModule,
    RouterModule,
    CdkStepperModule,
  ],
  exports: [
    EmptyComponent,
    ButtonComponent,
    CheckboxComponent,
    TextButtonComponent,
    TileComponent,
    InputComponent,
    IconButtonComponent,
    ModalComponent,
    ModalButtonComponent,
    ModalButtonsComponent,
    ModalContentComponent,
    PageSpinnerComponent,
    PageErrorHandlerComponent,
    ErrorTileComponent,
    LoadingSpinnerComponent,
    TopbarComponent,
    TopbarDropdownComponent,
    TopbarDropdownItemComponent,
    PageContainerComponent,
    PageHeaderComponent,
    BreadcrumbComponent,
    StepperComponent,
    TabComponent,
    TabsComponent,
    CommandSpinnerComponent,
    TableComponent,
    TableHeaderComponent,
    TableRowComponent,
    TableCellComponent,
    TimeAgoPipe,
    TimeWithAgoPipe,
    InputContainerComponent,
    InputLabelComponent,
    InputFieldComponent,
    DropdownComponent,
    ToggleComponent,
    ItemListSelectorComponent,
    FormHintComponent,
    TableChevronHeaderComponent,
  ],
  providers: [DatePipe, TimeAgoPipe, TimeWithAgoPipe],
})
export class SharedModule {}
