<gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
  <ng-container gbc-page-header-actions>
    <button gbc-text-button (click)="editInstallation()">
      <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
      {{ 'app-edit' | translate }}
    </button>
  </ng-container>
  <b>{{ company?.name }}</b
  ><br />
  {{ facility?.addressRoad }} <br />
  {{ facility?.addressPostal }}, {{ facility?.addressCity }} <br />
</gbc-page-header>
<gbc-tabs>
  <gbc-tab [title]="'igrid-installation-page.tabs.overview' | translate" name="overview">
    <gbc-page-container>
      <app-igrid-summary-page
        [installation]="installation$ | async"
        [facility]="facility"
        [company]="company"
        [configuration]="configuration"
        [routings]="routings$ | async"
      >
      </app-igrid-summary-page>

      <gbc-tile [title]="'igrid-installation-page.archive-installation' | translate">
        <gbc-table>
          <tr gbc-table-row>
            <td gbc-table-cell style="background: white">
              {{ 'igrid-installation-page.archive-description' | translate }}
            </td>
            <td gbc-table-cell class="archive-button" (click)="showArchiveModal()">
              <button>
                {{ 'igrid-installation-page.archive' | translate }}
              </button>
            </td>
          </tr>
        </gbc-table>
      </gbc-tile>
    </gbc-page-container>
  </gbc-tab>

  <gbc-tab [title]="'igrid-installation-page.tabs.users' | translate" name="users">
    <gbc-page-container>
      <app-users-list *ngIf="users$; else noUsers" [users]="users$"></app-users-list>
      <ng-template #noUsers>
        <gbc-tile [title]="'igrid-installation-page.tabs.users' | translate">
          <gbc-table [clickableRows]="false">
            <tr gbc-table-row>
              <td gbc-table-cell>
                {{ 'igrid-installation-page.no-users' | translate }}
              </td>
            </tr>
          </gbc-table>
        </gbc-tile>
      </ng-template>
    </gbc-page-container>
  </gbc-tab>

  <gbc-tab [title]="'igrid-installation-page.tabs.settings' | translate" name="settings">
    <gbc-page-container>
      <ng-container *ngIf="installation$ | async as installation">
        <app-igrid-settings ($settingsChanged)="handleSettingsChanged($event)" [currentSystem]="installation"></app-igrid-settings>

        <gbc-tile *ngIf="installation?.version" [title]="'igrid-installation-page.config-version-title' | translate">
          <gbc-table class="config-container">
            <tr gbc-table-row>
              <td gbc-table-cell>
                <p>
                  {{ 'igrid-installation-page.create-new-config-version-text' | translate }}
                </p>

                <p>
                  <b>{{ 'igrid-installation-page.config-version-current' | translate }}</b
                  >: {{ currentVersion.version }} -
                  {{ currentVersion.timestampEpoch | date: 'short' }}
                </p>
                <p *ngIf="deployedVersion">
                  <b>{{ 'igrid-installation-page.config-version-deployed' | translate }}</b
                  >: {{ deployedVersion.version }} -
                  {{ deployedVersion.timestampEpoch | date: 'short' }}
                </p>
              </td>
              <td gbc-table-cell class="config-update">
                <button gbc-button large secondary style="width: 280px" (click)="showPushConfigModal()">
                  {{ 'app-update' | translate }}
                </button>
              </td>
            </tr>
          </gbc-table>
        </gbc-tile>
      </ng-container>

      <gbc-tile [title]="'igrid-installation-page.config-versions' | translate">
        <ng-container *ngIf="installation$ | async as installation">
          <gbc-table *ngIf="installation?.version; else noVersion">
            <th gbc-table-header>
              {{ 'igrid-installation-page.config-version' | translate }}
            </th>
            <th gbc-table-header>
              {{ 'igrid-installation-page.config-change-reason' | translate }}
            </th>
            <th gbc-table-header>{{ 'app-date' | translate }}</th>
            <th gbc-table-header>{{ '' }}</th>

            <tr gbc-table-row *ngFor="let change of installation.version.changes.reverse()">
              <td gbc-table-cell>
                {{ change.version }}
              </td>
              <td gbc-table-cell>
                {{ 'igrid-installation-page.config-change-reason' | translate }}:
                {{
                  (change.deployed ? 'igrid-installation-page.config-change-deployed' : 'igrid-installation-page.config-change-updated')
                    | translate
                }}
              </td>
              <td gbc-table-cell>
                {{ change.timestampEpoch | date: 'short' }}
              </td>
              <td gbc-table-cell style="text-align: right">
                <button gbc-text-button (click)="confirmCreateNewConfigBasedOn(change.version)">
                  {{ 'igrid-installation-page.config-create-new' | translate }}
                </button>
              </td>
            </tr>
          </gbc-table>
        </ng-container>
        <ng-template #noVersion>
          <gbc-table [clickableRows]="false">
            <tr gbc-table-row>
              <td gbc-table-cell>
                {{ 'igrid-installation-page.config-no-version' | translate }}
              </td>
              <td gbc-table-cell style="text-align: right">
                <button gbc-text-button (click)="createNewConfigBasedOnExisting()">
                  {{ 'igrid-installation-page.config-edit-current' | translate }}
                </button>
              </td>
            </tr>
          </gbc-table>
        </ng-template>
      </gbc-tile>

      <gbc-tile [title]="'igrid-installation-page.system-settings' | translate">
        <gbc-table [clickableRows]="true">
          <tr gbc-table-row (click)="navigateToCustomerPortal()" class="change-system-settings-row">
            <td gbc-table-cell>
              {{ 'igrid-installation-page.change-system-settings-customer-portal' | translate }}
            </td>
          </tr>
        </gbc-table>
      </gbc-tile>
    </gbc-page-container>
  </gbc-tab>

  <gbc-tab [title]="'add-edit-igrid-system-datapoint.datapoints' | translate" name="datapoints">
    <gbc-page-container>
      <ng-container *ngIf="installation$ | async as installation">
        <app-igrid-system-datapoint-list [system]="installation"></app-igrid-system-datapoint-list>
      </ng-container>
    </gbc-page-container>
  </gbc-tab>
</gbc-tabs>
