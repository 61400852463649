import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { combineLatest, of, ReplaySubject, Subscription, timer } from 'rxjs';
import { distinctUntilChanged, filter, first, map, multicast, refCount, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MODAL_DATA, MODAL_CONTROLLER, ModalController, DropdownComponent } from 'shared';
import { IIGridSystem, ISetting } from '../../interfaces/iGridSystem';
import { InstallationsService } from '../../services/installations.service';
import { IAddSystemSettingPayload, IUpdateSystemSettingPayload } from '../../interfaces/iGridSystemSetting';

interface DropdownItem {
  name: string;
  value: any;
}

@Component({
  selector: 'app-add-igrid-system-setting-dialog',
  templateUrl: './add-igrid-system-setting-dialog.component.html',
  styleUrls: ['./add-igrid-system-setting-dialog.component.scss'],
})
export class AddIgridSystemSettingDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      system: IIGridSystem;
      isSupportUser?: boolean;
      setting?: ISetting;
      editable?: boolean;
    },
    @Inject(MODAL_CONTROLLER)
    private controller: ModalController<IAddSystemSettingPayload | IUpdateSystemSettingPayload>,
    private installationsService: InstallationsService,
    private translateService: TranslateService,
    public fb: UntypedFormBuilder,
  ) {}
  public title$ = this.translateService!.stream([
    'add-igrid-system-setting-dialog.modify-setting',
    'igrid-installation-settings.' + this.data.setting?.settingType?.settingKey,
    'add-igrid-system-setting-dialog.add-new-setting',
  ]).pipe(
    map(
      ({
        'add-igrid-system-setting-dialog.modify-setting': editSettingTitle,
        ['igrid-installation-settings.' + this.data.setting?.settingType?.settingKey]: settingNameTranslated,
        'add-igrid-system-setting-dialog.add-new-setting': addNewSettingTitle,
      }: any) =>
        this.data.setting
          ? this.data.editable
            ? editSettingTitle + ': ' + settingNameTranslated
            : settingNameTranslated
          : addNewSettingTitle,
    ),
  );

  private subscriptions = new Subscription();

  @ViewChild('settingTypeDropdown')
  public settingTypeDropdown?: DropdownComponent;
  public form = this.fb.group({
    id: null,
    customName: null,
    parentId: null,
    order: null,
    settingType: [null, Validators.required],
    value: [''],
    isSupportUserSetting: false,
  });
  public settingsTranslations$ = this.translateService.stream('igrid-installation-settings');
  public selectableParentSettings$ = this.settingsTranslations$.pipe(
    map((settingsTranslations) =>
      sortBy(
        this.data.system.settings.map((setting: ISetting) => ({
          name: setting.customName || settingsTranslations[setting.settingType.settingKey] + ` (${setting.settingType.settingKey})`,
          value: setting,
        })),
        (s) => s.name,
      ),
    ),
  );

  public allSettingTypes$ = this.settingsTranslations$.pipe(
    filter((t) => !!t),
    switchMap((settingsTranslations) =>
      this.installationsService.getIGridSystemSettingTypes().pipe(
        map((settingTypes) => {
          return sortBy(
            Object.values(settingTypes).map((settingType) => ({
              name: settingsTranslations[settingType.settingKey] + ` (${settingType.settingKey})`,
              value: settingType,
            })),
            (s) => s.name,
          );
        }),
      ),
    ),
    multicast(new ReplaySubject(1)),
    refCount(),
  );

  public selectableSettingTypes$ = new ReplaySubject<DropdownItem[]>();
  public fileUploaded = false;

  public ngOnInit(): void {
    this.allSettingTypes$.pipe(first()).subscribe((settingTypes) => {
      // Start out by allowing selection of all settingTypes.
      this.selectableSettingTypes$.next(settingTypes);
    });

    // This subscriptions handles re-setting the setting type dropdown to only contain the settingTypes that non of the currently selected parent's children are associated with.
    if (!this.data.setting) {
      this.subscriptions.add(
        this.form.controls.parentId.valueChanges
          .pipe(
            withLatestFrom(this.allSettingTypes$),
            tap(([newParentSetting, _]) => {
              if (newParentSetting?.value?.settingType?.settingKey) {
                const matchingSettingNode = this.data.system?.settings.find(
                  (setting) => setting.settingType.settingKey === newParentSetting?.value?.settingType?.settingKey,
                );
                const matchingSettingChildren = this.data.system?.settings.filter((set) => set.parentId === matchingSettingNode?.id);
                if (
                  newParentSetting?.value?.settingType.settingKey === this.form.controls.settingType?.value?.value?.settingKey ||
                  matchingSettingChildren?.length
                ) {
                  this.form.controls.settingType.patchValue(null);
                  setTimeout(() => {
                    this.form.controls.value.patchValue(null);
                  });
                }
              }
            }),
            map(([newParentSetting, allSettingTypes]) => {
              if (!newParentSetting?.value) {
                return allSettingTypes;
              }

              const matchingSettingNode = this.data.system?.settings?.find(
                (set) => set.settingType.settingKey === newParentSetting?.value?.settingType?.settingKey,
              );
              const matchingSettingChildren = this.data.system?.settings.filter((set) => set.parentId === matchingSettingNode.id);
              return sortBy(
                allSettingTypes.filter(
                  (s) =>
                    s.value.settingKey !== newParentSetting?.value?.settingType.settingKey &&
                    !matchingSettingChildren?.some((setting) => setting.settingType.settingKey === s.value.settingKey),
                ),
                (s) => s.name,
              );
            }),
          )
          .subscribe((types) => this.selectableSettingTypes$.next(types)),
      );
    }

    if (this.data.setting) {
      this.subscriptions.add(
        combineLatest([this.allSettingTypes$, this.selectableParentSettings$])
          .pipe(first())
          .subscribe(([settingTypes, parentSettings]) => {
            this.form.patchValue({
              id: this.data.setting.id,
              customName: this.data.setting.customName,
              parentId: parentSettings.find((s) => s.value.id === this.data.setting.parentId),
              order: this.data.setting.order,
              settingType: settingTypes.find((st) => st.value.id === this.data.setting.settingType.id),
              value: this.data.setting.value,
              isSupportUserSetting: this.data.setting.needsSupportUserLevel,
            });
          }),
      );
      this.form.controls.parentId.disable({ emitEvent: false });
      this.form.controls.value.disable({ emitEvent: false });
      // Handle removal of sibling settings from selectable settings - this is to prevent someone from unintentionally chaning type to a pre-existing setting under the same parent (which _will_ error)
      this.selectableSettingTypes$
        .pipe(
          first(),
          switchMap((types) => {
            const siblingSettings = this.data.system.settings.filter(
              (setting) => setting.parentId === this.data.setting.parentId && setting.id !== this.data.setting.id,
            );
            const selectableSettingTypes = types.filter(
              (settingType) => !siblingSettings.some((setting) => +setting.settingType.id === +settingType.value.id),
            );
            return of(selectableSettingTypes);
          }),
          switchMap((selectableSettingTypes) => timer(0).pipe(map(() => selectableSettingTypes))),
        )
        .subscribe((selectableSettingTypes) => this.selectableSettingTypes$.next(selectableSettingTypes));
    }

    if (!this.data.editable) {
      this.form.disable({ emitEvent: false });
    }

    /* Handle numeric settings (patch the value if it exceeds maxValue or is lower than minValue) */
    this.subscriptions.add(this.form.controls.value.valueChanges.pipe(distinctUntilChanged()).subscribe(this.handleNumericValues));
    /* Force the numeric settings to have the defaultValue from the beginning (fallback to minVal - if it's defined. If not, it will be set to empty.) */
    this.subscriptions.add(this.form.controls.settingType.valueChanges.subscribe(this.handleSetDefaultValueOnSettingTypeChange));
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public save() {
    if (this.form.value) {
      const { settingType, ...formValues } = this.form.value;
      this.controller.complete({
        ...formValues,
        order: this.form.value.order && +this.form.value.order,
        settingTypeId: this.form.value.settingType?.value.id,
        parentId: this.form.value.parentId && +this.form.value.parentId?.value.id,
        value: `${this.form.value.value}`,
      });
    }
  }

  public cancel() {
    this.controller.dismiss();
  }

  /**
   * This function handles setting the value of the form (+ the corresponding input) to the default value of the selected systemType.
   * It auto-updates when the user changes the selected type.
   * @param param0 Currently selected settingType
   */
  private handleSetDefaultValueOnSettingTypeChange = (settingType) => {
    // If we are editing and the settingType has not been changed, then keep the value
    if (this.data.setting && this.data.setting.settingType.id === settingType?.value?.id) {
      this.form.controls.value.patchValue(this.data.setting.value);
      return;
    }

    const parametersJson = settingType?.value?.parametersJson;
    let defaultValue;
    if (parametersJson) {
      try {
        const parameters = JSON.parse(parametersJson);
        if (parameters.default) {
          defaultValue = parameters.default;
        }
      } catch (error) {
        console.error('Something went wrong while parsing parametersJson property as JSON: ', error, parametersJson);
      }
    }

    const minValue = settingType?.value.minValue;
    if (defaultValue !== undefined || minValue !== undefined) {
      this.form.controls.value.patchValue(defaultValue || minValue);
    } else {
      setTimeout(() => {
        // Hackish: defer patch of value to allow the change detection to register that type isn't number before changing the value; avoids 'NaN' being printed.
        this.form.controls.value.patchValue('');
      }, 50);
    }
  };

  private handleNumericValues = (newValue) => {
    if (this.form.controls.settingType.value?.value?.valueType === 'number') {
      const { maxValue, minValue } = this.form.controls.settingType.value.value;
      if (minValue !== undefined && newValue < minValue) {
        setTimeout(() => {
          this.form.controls.value.patchValue(minValue);
        }, 50);
      }
      if (maxValue && newValue > maxValue) {
        setTimeout(() => {
          this.form.controls.value.patchValue(maxValue);
        }, 50);
      }
    }
  };
}
