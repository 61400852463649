import { Pipe, PipeTransform } from '@angular/core';
import { IFullInstallationDTO, IReducedInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';

@Pipe({
  name: 'filterInstallationOnNameOrSerial',
})
export class FilterInstallationOnNameOrSerialPipe implements PipeTransform {
  transform(
    values: IFullInstallationDTO[] | IReducedInstallationDTO[],
    filter?: string,
  ): IFullInstallationDTO[] | IReducedInstallationDTO[] {
    if (filter) {
      return values.filter(
        (installation) =>
          installation.name?.toLowerCase().includes(filter.toLowerCase()) || installation.serialNumber?.toString().includes(filter),
      );
    }
    return values;
  }
}
