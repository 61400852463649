<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo"></gbc-page-header>
  <gbc-command-spinner>
    <form [formGroup]="form">
      <gbc-tabs>
        <gbc-tab [title]="'archive-page.companies' | translate" name="companies">
          <gbc-page-container>
            <gbc-tile>
              <gbc-item-list-selector
                *ngIf="companies$ | async as companies"
                formControlName="companies"
                [clickable]="true"
                (rowClick)="clickCompany($event)"
                [fallbackMessage]="'archive-page.no-archived-companies' | translate"
                [header]="[{ key: 'name', title: 'archive-page.company' | translate }]"
                [items]="companies"
              >
              </gbc-item-list-selector>
            </gbc-tile>

            <button gbc-button danger (click)="removeCompanies()">
              {{ 'archive-page.remove-companies' | translate }}
            </button>
          </gbc-page-container>
        </gbc-tab>

        <gbc-tab [title]="'archive-page.facilities' | translate" name="facilities">
          <gbc-page-container>
            <gbc-tile>
              <gbc-item-list-selector
                *ngIf="facilities$ | async as facilities"
                formControlName="facilities"
                [clickable]="true"
                (rowClick)="clickFacility($event)"
                [header]="[{ key: 'name', title: 'archive-page.facility' | translate }]"
                [fallbackMessage]="'archive-page.no-archived-facilities' | translate"
                [items]="facilities"
              >
              </gbc-item-list-selector>
            </gbc-tile>

            <button gbc-button danger (click)="removeFacilities()">
              {{ 'archive-page.remove-facilities' | translate }}
            </button>
          </gbc-page-container>
        </gbc-tab>

        <gbc-tab [title]="'archive-page.installations' | translate" name="installations">
          <gbc-page-container>
            <gbc-tile>
              <gbc-item-list-selector
                *ngIf="installations$ | async as installations"
                formControlName="installations"
                [clickable]="true"
                (rowClick)="clickInstallation($event)"
                [header]="[
                  {
                    key: 'name',
                    title: 'archive-page.installation' | translate
                  }
                ]"
                [fallbackMessage]="'archive-page.no-archived-installations' | translate"
                [items]="installations"
              >
              </gbc-item-list-selector>
            </gbc-tile>

            <button gbc-button danger (click)="removeInstallations()">
              {{ 'archive-page.remove-installations' | translate }}
            </button>
          </gbc-page-container>
        </gbc-tab>

        <gbc-tab [title]="'archive-page.users' | translate" name="users">
          <gbc-page-container>
            <gbc-tile>
              <gbc-item-list-selector
                *ngIf="users$ | async as users"
                formControlName="users"
                [clickable]="true"
                (rowClick)="clickUser($event)"
                [header]="[{ key: 'name', title: 'archive-page.user' | translate }]"
                [fallbackMessage]="'archive-page.no-archived-users' | translate"
                [items]="users"
              >
              </gbc-item-list-selector>
            </gbc-tile>

            <button gbc-button danger (click)="removeUsers()">
              {{ 'archive-page.remove-users' | translate }}
            </button>
          </gbc-page-container>
        </gbc-tab>
      </gbc-tabs>
    </form>
  </gbc-command-spinner>
</ng-template>
