import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, of } from 'rxjs';
import { shareReplay, tap, map, switchMap } from 'rxjs/operators';
import { IFacility, IPutPatchFacility, IIGridFacilityDTO, EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';
import { CommandService, createRefreshSubject, AppErrorService } from 'shared';
import { CurrentUserService } from './current-user.service';
import { EArchiveState } from '../interfaces/archiveState';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  facilities$: Observable<IFacility[]>;

  reloadFacilities: () => Observable<IFacility[]>;

  constructor(
    private httpClient: HttpClient,
    private commandService: CommandService,
    private currentUserService: CurrentUserService,
    private appErrorService: AppErrorService,
  ) {
    const { subject, scheduleRefresh } = createRefreshSubject(() => this.httpGetFacilities(), this.currentUserService.initialCurrentUser$);
    this.facilities$ = subject;
    this.reloadFacilities = scheduleRefresh;
  }

  private httpGetFacilities(): Observable<IFacility[]> {
    return this.httpClient.get<IFacility[]>(`${environment.serverUrl}/api/facilities`);
  }

  public getFacility(facilityId: string): Observable<IFacility> {
    return this.httpClient.get<IFacility>(`${environment.serverUrl}/api/facilities/${facilityId}`).pipe(
      switchMap((facility) => {
        if (facility.facilityType === 'iGrid') {
          return this.getIGridFacility(facilityId);
        }
        return of(facility);
      }),
    );
  }

  public getIGridFacilities(): Observable<IIGridFacilityDTO[]> {
    return this.httpClient
      .get<IIGridFacilityDTO[]>(`${environment.serverUrl}/api/igrid/facility/`)
      .pipe(map((sites) => sites.map((site) => ({ ...site, id: site.facilityId }))));
  }

  public getIGridFacility(facilityId: string): Observable<IFacility> {
    return this.httpClient.get<IIGridFacilityDTO>(`${environment.serverUrl}/api/igrid/facility/${facilityId}`).pipe(
      map(
        ({
          facilityId: id,
          systems,
          companyId,
          companyName,
          companyOwnerName,
          companyOwnerEmail,
          companyOwnerPhone,
          facilityName,
          facilityAddressCity,
          facilityAddressPostal,
          facilityAddressRoad,
          facilityLocationLatitude,
          facilityLocationLongitude,
          companyArchiveState,
          ...rest
        }) => {
          return {
            id,
            installations: systems.map((s) => ({
              ...s,
              settings: (s as any).setting,
              parentFacilityId: id,
            })),
            company: {
              id: companyId,
              name: companyName,
              contactName: companyOwnerName,
              contactEmail: companyOwnerEmail,
              contactPhone: companyOwnerPhone,
              archiveState: companyArchiveState as EArchiveState,
            },
            name: facilityName,
            archiveState: EArchiveState.Active,
            addressCity: facilityAddressCity,
            addressPostal: facilityAddressPostal,
            addressRoad: facilityAddressRoad,
            facilityType: EFacilityType.iGrid,
            location: {
              latitude: facilityLocationLatitude,
              longitude: facilityLocationLongitude,
            },
            ...rest,
          };
        },
      ),
    );
  }

  private withReload<R>(fn: () => Observable<R>) {
    const result = fn().pipe(
      tap(() => this.reloadFacilities()),
      shareReplay(),
    );
    result.subscribe(
      () => {},
      () => {},
    );
    return result;
  }

  // TODO: Separate this out into the varios functions, add correct success and error messages to each function
  private executeFacilityCommand<R>(commandFn: () => Observable<R>) {
    return this.commandService.execute((): Observable<R> => {
      return this.withReload(commandFn);
    }, {});
  }

  public searchFacilities(searchTerm: string): Observable<IFacility[]> {
    return this.executeFacilityCommand(() => {
      return this.httpClient.post<IFacility[]>(`${environment.serverUrl}/api/facilities/search`, { searchTerm });
    });
  }

  public createFacility(facility: IPutPatchFacility): Observable<IFacility> {
    return this.executeFacilityCommand(() => this.httpClient.put<IFacility>(`${environment.serverUrl}/api/facilities`, facility));
  }

  public patchFacility(facility: IPutPatchFacility): Observable<IFacility> {
    return this.executeFacilityCommand(() =>
      this.httpClient.patch<IFacility>(`${environment.serverUrl}/api/facilities/${facility.id}`, facility),
    );
  }

  public updateFacilityInstallations(facilityId, installationIDs): Observable<IFacility> {
    return this.withReload(() =>
      this.httpClient.patch<IFacility>(`${environment.serverUrl}/api/facilities/${facilityId}/updateInstallations`, installationIDs),
    );
  }

  public deleteFacility(facilityId: number): Observable<unknown> {
    return this.executeFacilityCommand(() => this.httpClient.delete(`${environment.serverUrl}/api/facilities/${facilityId}`));
  }

  public archive(facilityId: number): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/facilities/archiveFacility/${facilityId}`, {}).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'facility-service.archive-failed',
            errorCodes: {
              403: {
                messageKey: 'facility-service.archive-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.reloadFacilities(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'facility-service.archive-success' },
    );
  }

  public archiveForDeletion(facilityIds: number[]): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/facilities/archiveFacilitiesForDeletion`, facilityIds).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'facility-service.archive-for-deletion-failed',
            errorCodes: {
              403: {
                messageKey: 'facility-service.archive-for-deletion-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.reloadFacilities(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'facility-service.archive-for-deletion-success' },
    );
  }
}
