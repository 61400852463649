export const environment = {
  production: true,
  serverUrl: '',
  deploymentPath: '/service',
  testMode: false,
  iGridCustomerPortalUrl: 'https://igrid.grundfos.com',
  getiGridCustomerPortalUrl: ({ hostname }: Location) => {
    if (hostname === 'localhost') {
      return 'https://d.igrid.grundfos.dev';
    }
    const [deploymentPrefix] = hostname.split('.');
    return deploymentPrefix.includes('bccore-d')
      ? 'https://d.igrid.grundfos.dev'
      : deploymentPrefix.includes('bccore-t')
      ? 'https://t.igrid.grundfos.dev'
      : deploymentPrefix.includes('bccore-q')
      ? 'https://q.igrid.grundfos.dev'
      : 'https://igrid.grundfos.com';
  },
};
