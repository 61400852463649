<div class="tile" [ngClass]="{ flexy }" [ngStyle]="{ height: contentHeight || '100%' }">
  <div class="tile-header" *ngIf="title">
    <div class="title">
      {{ title }}
    </div>
    <div class="right-hand-content">
      <ng-content select="[gbc-tile-right]"></ng-content>
    </div>
  </div>
  <div class="tile-content" [ngClass]="{ flexy }">
    <ng-content></ng-content>
  </div>
</div>
