import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { first, map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared';
import { IGridDeviceRouteStrategy, IIGridDeviceRoute } from '../../interfaces/iGridDeviceRoute';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { FacilityService } from '../../services/facility.service';
import { SystemRoutingService } from '../../services/system-routing.service';
import { AddEditRouteForSystemDialogComponent } from '../add-edit-route-for-system-dialog/add-edit-route-for-system-dialog.component';

@Component({
  selector: 'app-igrid-system-routings',
  templateUrl: './igrid-system-routings.component.html',
  styleUrls: ['./igrid-system-routings.component.scss'],
})
export class IgridSystemRoutingsComponent {
  constructor(
    private router: Router,
    private routingsService: SystemRoutingService,
    private facilityService: FacilityService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  @Input() installation: IIGridSystem;
  @Input() routings: {
    incoming: IIGridDeviceRoute[];
    outgoing: IIGridDeviceRoute[];
  };
  @Input() navigateRoutings = true;

  public allSystems$ = this.facilityService.getIGridFacilities().pipe(
    publishReplay(1),
    refCount(),
    first((d) => !!d?.length),
    map((f) => f.flatMap((fac) => fac.systems.map((s) => ({ ...s, parentFacilityId: fac.facilityId })))),
  );

  public navigateToInstallation(s: IIGridSystem) {
    this.router.navigate(['igrid', 'installation', s.parentFacilityId, s.id]);
  }

  public trackByDeviceId({ deviceId }) {
    return deviceId;
  }

  public openDeleteRouteDialog(route: IIGridDeviceRoute) {
    this.translateService
      .get(['igrid-system-routings.delete-route-dialog.title', 'igrid-system-routings.delete-route-dialog.content'], {
        routingName: route.routingName ?? `${route.sourceDeviceId} → ${route.targetDeviceId}`,
        sourceDeviceId: route.sourceDeviceId,
      })
      .pipe(
        switchMap(
          ({ 'igrid-system-routings.delete-route-dialog.title': title, 'igrid-system-routings.delete-route-dialog.content': content }) =>
            this.modalService.showConfirmModal(title, content).pipe(first()),
        ),
      )
      .subscribe({
        next: (confirmed) => {
          if (confirmed) {
            this.routingsService.deleteRoute(route.sourceDeviceId, this.installation.deviceId).subscribe();
          }
        },
      });
  }


  public openAddEditRouteDialog(system: IIGridSystem, route?: IIGridDeviceRoute) {
    this.modalService
      .openDialog<{
        description: string;
        routingName: string;
        sourceSystem: IIGridSystem;
        strategy: IGridDeviceRouteStrategy;
        enabled: boolean
      }>(AddEditRouteForSystemDialogComponent, {
        data: {
          system,
          route,
          selectableSystems$: this.allSystems$.pipe(
            map((systems) =>
              systems
                .filter(
                  (sys) =>
                    !this.routings.incoming.some(
                      (r) => sys.deviceId?.toLowerCase() === r.sourceDeviceId?.toLowerCase() && sys.deviceId !== route?.sourceDeviceId, // forbidden systems => all with existing routing to this system.
                    ) && sys.deviceId !== this.installation?.deviceId,
                )
                .map((s) => ({ name: s.deviceId, value: s })),
            ),
          ),
          forbiddenRoutes: this.routings.incoming.filter((r) => r.routingName !== route?.routingName), // forbidden route names => all incoming routes except the clicked route.
        },
      })
      .pipe(first())
      .subscribe(({ dismissed, result }) => {
        if (!dismissed) {
          const newRouting = {
            targetDeviceId: this.installation.deviceId,
            description: result.description,
            routingName: result.routingName,
            strategy: result.strategy,
            enabled: result.enabled
          };

          const addRouteObs = this.routingsService.addRoute((result.sourceSystem.value as IIGridSystem)?.deviceId, newRouting);

          // Case: editing a route => should remove the route before adding a new one due to restraints on routingName not being handled in the backend.
          if (route) {
            this.routingsService
              .deleteRoute(route.sourceDeviceId, this.installation.deviceId)
              .pipe(switchMap(() => addRouteObs))
              .subscribe({
                error: () => this.routingsService.refreshSystemRoutings(),
              });
          } // error callback refreshes state to ensure we're aligned with the backend.

          // Case: Just adding a new route; simply add it.
          else {
            addRouteObs.subscribe();
          }
        }
      });
  }
}
