import { Component, OnInit, AfterContentInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { PageInfo, AppErrorService, AppError, ModalService } from 'shared';
import { CompanyService } from 'projects/serviceportal/src/app/services/company.service';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { map, startWith, shareReplay, tap, switchMap } from 'rxjs/operators';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserService } from 'projects/serviceportal/src/app/services/user.service';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { TranslateService } from '@ngx-translate/core';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';
import { GisSourceService } from '../../services/gis-source.service';
import { EBatchStatus, IBatchListItem, IGisSourceFileRecord } from '../../interfaces/gisSource';
import { orderBy } from 'lodash';
import { AddGisDataSourceDialogComponent } from '../../components/add-gis-data-source-dialog/add-gis-data-source-dialog.component';

const gisSourceImportIsUnfinished = (value: IBatchListItem, index: number, array: IBatchListItem[]) =>
  value.status === EBatchStatus.Started || value.status === EBatchStatus.Created;

@Component({
  selector: 'app-company-page',
  templateUrl: './company-page.component.html',
  styleUrls: ['./company-page.component.scss'],
})
export class CompanyPageComponent implements OnInit, AfterContentInit {
  public pageError$: Observable<AppError>;

  public pageInfo$: Observable<PageInfo>;
  public company$: Observable<ICompany>;
  private facilitiesForCompany$: Observable<IFacility[]>;
  public facilities$: Observable<IFacility[]>;
  public usersForCompany$: Observable<IUser[]>;
  public isArchived$: Observable<boolean>;
  public showCreateFacilityButton$: Observable<boolean>;
  public showCreateUserButton$: Observable<boolean>;
  public showUploadNewGisDataSourceButton$: Observable<boolean>;
  public gisSourcesForCompany$: Observable<IGisSourceFileRecord[]>;

  public form = new UntypedFormGroup({
    facilitySearch: new UntypedFormControl(''),
  });

  constructor(
    private companyService: CompanyService,
    private facilityService: FacilityService,
    private pageInfoService: PageInfoService,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private appErrorService: AppErrorService,
    private modalService: ModalService,
    private gisSourceService: GisSourceService,
    private translate: TranslateService,
    private gisService: GisSourceService,
  ) {
    this.company$ = this.companyService.getCompany(this.route.snapshot.params.companyId).pipe(
      this.appErrorService.catchApiError({
        fallbackMessageKey: 'company-page.company-not-found',
      }),
      shareReplay(),
    );

    this.isArchived$ = this.company$.pipe(map((company) => company.archiveState === EArchiveState.Archived));

    this.usersForCompany$ = combineLatest([this.company$, this.userService.usersWithCompany$]).pipe(
      map(([company, users]) => {
        return users
          .filter((u) => {
            return u.companyId === company.id;
          })
          .filter((user) => user.archiveState === EArchiveState.Active);
      }),
    );

    this.facilitiesForCompany$ = combineLatest([this.company$, this.facilityService.facilities$]).pipe(
      map(([company, facilities]) => {
        return facilities
          .filter((f) => {
            return f.company?.id === company.id;
          })
          .filter((facility) => {
            return facility.archiveState === EArchiveState.Active;
          });
      }),
    );

    this.facilities$ = combineLatest([this.form.controls.facilitySearch.valueChanges.pipe(startWith('')), this.facilitiesForCompany$]).pipe(
      map(([search, facilities]) => {
        if (!search) {
          return facilities;
        }
        return facilities.filter((f) => {
          return [f.name, f.addressRoad, f.addressCity, f.addressPostal, f.facilityType].some(
            (s) => s?.toLowerCase().includes(search.toLowerCase()),
          );
        });
      }),
    );

    this.gisSourceService.refreshGisDataSources();

    this.gisSourcesForCompany$ = combineLatest([this.company$, this.gisSourceService.gisSources$]).pipe(
      map(([company, gs]) =>
        orderBy(
          gs.filter((source) => source.companyId === company.id),
          (g) => g.created,
          'desc',
        ).map((source) => (source.imports.some(gisSourceImportIsUnfinished) ? { ...source, loading: true } : { ...source })),
      ),
      tap((gisSources) => {
        // Handle polling for new data every second in case any of the sources have status "Started" or "Never"
        if (gisSources.some((gs) => gs.imports.some(gisSourceImportIsUnfinished))) {
          setTimeout(() => {
            this.gisSourceService.refreshGisDataSources();
          }, 1000);
        }
      }),
    );

    this.pageError$ = appErrorService.createPageErrorObservable([this.company$]);

    this.pageInfo$ = this.company$.pipe(
      map((company) => {
        return this.pageInfoService.companyPage(company);
      }),
    );
  }

  ngOnInit(): void {}

  editCompany() {
    this.router.navigate(['/company/create', this.route.snapshot.params.companyId]);
  }

  ngAfterContentInit() {
    this.showCreateFacilityButton$ = combineLatest([this.route.params, this.isArchived$]).pipe(
      map(([params, isArchived]) => params.tab === 'facilities' && !isArchived),
    );

    this.showCreateUserButton$ = combineLatest([this.route.params, this.isArchived$]).pipe(
      map(([params, isArchived]) => params.tab === 'users' && !isArchived),
    );

    this.showUploadNewGisDataSourceButton$ = combineLatest([this.route.params, this.isArchived$]).pipe(
      map(([params, isArchived]) => params.tab === 'gisSources' && !isArchived),
    );
  }

  public openAddGisSourceDataDialog() {
    this.company$
      .pipe(
        switchMap((company) =>
          this.modalService.openDialog<File>(AddGisDataSourceDialogComponent, {
            data: { company },
          }),
        ),
      )
      .subscribe(({ result }) => {
        if (result) {
          /* When there's a result it means we've just uploaded a file. A reference to the uploaded file is returned as the result.
             We need to dispatch the action to re-fetch gis data sources in order to update the list */
          setTimeout(() => {
            this.gisService.refreshGisDataSources();
          }, 150);
        }
      });
  }
  confirmArchiveCompany() {
    this.modalService.showTextModal({
      iconUrl: './assets/svgs/archive.svg',
      title: this.translate.instant('app-archive'),
      headline: this.translate.instant('company-page.confirm-archive'),
      content: this.translate.instant('company-page.archive-description'),
      actions: [
        {
          text: this.translate.instant('app-cancel'),
          cancel: true,
        },
        {
          type: 'primary',
          text: this.translate.instant('app-archive'),
          handler: this.archiveCompany.bind(this),
        },
      ],
    });
  }

  archiveCompany() {
    this.companyService.archive(Number(this.route.snapshot.params.companyId)).subscribe(() => {
      this.router.navigate(['/overview', { tab: 'companies' }]);
    });
  }
}
