export enum IIGridSystemStateSystemStateEnum {
  notCreated = 'NotCreated',
  created = 'Created',
}

export enum IIGridSystemStateDeviceStateEnum {
  notExist = 'NotExist',
  exist = 'Exist',
}

/**
 * Type for the new DisSync endpoint
 */

export interface DataPoint {
  action: string;
  dataKey: string;
  standardKey: string;
  dataPointType: string;
}

export interface SystemStateSetting {
  action: string;
  settingKey: string;
  path: string;
  order: number;
}

interface SystemState {
  state: IIGridSystemStateSystemStateEnum;
  disState: IIGridSystemStateDeviceStateEnum;
  datapoints: DataPoint[];
  settings: SystemStateSetting[];
}

export interface StateResponse {
  deviceId: string;
  state: {
    system: SystemState;
  };
}
