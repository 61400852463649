import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AsyncSubject, Observable, throwError } from 'rxjs';
import { tap, catchError, map } from 'rxjs/operators';
import { FeatureFlag, IConfiguration } from '../interfaces/configuration';
import { serverUrlToken } from '../injection-tokens';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(
    private httpClient: HttpClient,
    @Inject(serverUrlToken) private serverUrl: string,
  ) {}
  public configuration = new AsyncSubject<IConfiguration>();

  public LoadConfiguration() {
    this.httpClient
      .get<IConfiguration>(`${this.serverUrl}/api/configuration`)
      .pipe(
        tap((configuration) => {
          this.configuration.next(configuration);
          this.configuration.complete();
        }),
        catchError((error) => {
          setTimeout(() => this.LoadConfiguration(), 5000);
          return throwError('');
        }),
      )
      .subscribe();
  }

  public hasFeatureFlag(flag: FeatureFlag): Observable<boolean> {
    return this.configuration.pipe(
      map((c: IConfiguration) => {
        const hasFeatureFlag = c.featureFlags.hasOwnProperty(flag) && c.featureFlags[flag];
        return hasFeatureFlag;
      }),
    );
  }
}
