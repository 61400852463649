<div class="button-row">
  <form [formGroup]="searchForm">
    <app-searchbar id="facility-searchbar" formControlName="searchString" [placeholder]="'Search'"></app-searchbar>
  </form>
  <button (click)="createNewFacility()">Create new facility</button>
  <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button>
</div>

<!-- List -->
<div class="list" *ngIf="(uiState === 'list' || uiState === 'edit') && inverseOfTranslation as topPosition">
  <cdk-virtual-scroll-viewport #scrollViewport [itemSize]="60">
    <table class="table table-striped">
      <thead>
        <tr>
          <th [style.top]="topPosition" scope="col">#</th>
          <th [style.top]="topPosition" scope="col" sortable="Name">Name</th>
          <th [style.top]="topPosition" scope="col" sortable="type">Type</th>
          <th [style.top]="topPosition" scope="col" sortable="Company">Company</th>
          <th [style.top]="topPosition" scope="col" sortable="Location">Location</th>
          <th [style.top]="topPosition" scope="col" sortable="Address">Address</th>
          <th [style.top]="topPosition" scope="col" sortable="InstallationCount">Installation Count</th>
          <th [style.top]="topPosition" *ngIf="uiState === 'edit'" scope="col">Modify</th>
          <th [style.top]="topPosition" class="deleteColumn" *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody>
        <!-- | async -->
        <tr *cdkVirtualFor="let facility of facilities$ | async">
          <th scope="row">{{ facility.id }}</th>
          <td class="nameColumn">{{ facility.name }}</td>
          <td>{{ facility.facilityType }}</td>
          <td>{{ facility.company?.name }}</td>
          <td>
            <div>{{ facility.location?.latitude | number }}</div>
            <div>{{ facility.location?.longitude | number }}</div>
          </td>
          <td class="addressColumn">{{ facility.address }}</td>
          <td>{{ facility.installations.length }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyFacility(facility)">Modify</button>
          </td>
          <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteFacility(facility)">X</button>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
<!-- Create -->
<div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
  <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
    <input class="formElement" placeholder="Name" type="text" formControlName="Name" />
    <ng-select class="select" [items]="facilityTypes" [multiple]="false" placeholder="Select Facility Type" formControlName="FacilityType">
    </ng-select>
    <ng-select
      class="select"
      [items]="companies$ | async"
      bindLabel="name"
      [multiple]="false"
      placeholder="Select Company"
      formControlName="Company"
    >
    </ng-select>
    <div formGroupName="Location">
      <input class="formElement" placeholder="Location: Latitude" type="number" formControlName="Latitude" />
      <input class="formElement" placeholder="Location: Latitude" type="number" formControlName="Longitude" />
    </div>
    <input class="formElement" placeholder="Address Road" type="text" formControlName="AddressRoad" />
    <input class="formElement" placeholder="Address Postal" type="text" formControlName="AddressPostal" />
    <input class="formElement" placeholder="Address City" type="text" formControlName="AddressCity" />

    <ng-select
      class="select"
      [items]="installations$ | async"
      bindLabel="deviceId"
      [multiple]="true"
      placeholder="Select Installations"
      formControlName="InstallationSchematics"
    >
    </ng-select>

    <button type="submit" [disabled]="!form.valid">Submit</button>
    <button type="reset" (click)="cancelNewFacility()">Cancel</button>
  </form>
</div>
