import { Component, Inject, OnInit } from '@angular/core';
import { MODAL_DATA } from 'projects/shared/src/lib/interfaces/modal';

@Component({
  selector: 'gbc-validation-messages-modal',
  templateUrl: './validation-messages-modal.component.html',
  styleUrls: ['./validation-messages-modal.component.scss'],
})
export class ValidationMessagesModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public data: { validationMessages: string[]; title: string },
  ) {}

  ngOnInit(): void {}
}
