import { Component } from '@angular/core';
import { CurrentUserService } from '../../services/current-user.service';
import { IUser } from '../../interfaces/user';
import { Observable } from 'rxjs';

const hasClaim = (user: IUser, claim: string): boolean => {
  return user.accessClaimsGroupNames.includes(claim);
};

@Component({
  selector: 'app-developer-menu',
  templateUrl: './developer-menu.component.html',
  styleUrls: ['./developer-menu.component.scss'],
})
export class DeveloperMenuComponent {
  currentUser$: Observable<IUser>;
  showAccessClaimsButton: Observable<boolean>;
  showServiceGroupButton: Observable<boolean>;

  constructor(private currentUserService: CurrentUserService) {
    this.currentUser$ = this.currentUserService.currentUser$;
    this.showAccessClaimsButton = this.currentUserService.hasClaim('AccessClaimGroups');
    this.showServiceGroupButton = this.currentUserService.hasClaim('ServiceGroups');
  }
}
