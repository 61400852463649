import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { SnackBarService } from './snack-bar.service';
import { catchError, flatMap, shareReplay, tap } from 'rxjs/operators';
import { ICommandOptions } from '../interfaces/command';
import { TranslateService } from '@ngx-translate/core';
import { AppError } from './app-error.service';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class CommandService {
  private DEFAULT_SUCCESS_KEY = 'command-service.operation-succeeded-snack';
  private DEFAULT_ERROR_KEY = 'command-service.operation-failed-snack';
  commandInProgress = new BehaviorSubject(false);

  constructor(
    private snackBarService: SnackBarService,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) {}

  execute<T>(commandFn: () => Observable<T>, options: ICommandOptions): Observable<T> {
    if (this.commandInProgress.value) {
      // TODO this should not happen, but what to do? Best bet for now is to just ignore and return empty observable
      console.error('attempted to execute command while another command is already in progress');
      return of();
    }
    this.commandInProgress.next(true);
    const result = commandFn().pipe(
      tap(() => {
        if (!options.noMessageOnSuccess) {
          this.snackBarService.show(this.translateService.instant(options.successMessageKey || this.DEFAULT_SUCCESS_KEY));
        }
      }),
      tap({
        next: () => this.commandInProgress.next(false),
        error: () => this.commandInProgress.next(false),
      }),
      shareReplay(),
    );
    result
      .pipe(
        catchError((e) => {
          const modalCompleted$ =
            e instanceof AppError
              ? this.modalService.showErrorModal(e.userTitle, e.userMessage)
              : this.modalService.showErrorModal('Error', this.translateService.instant(this.DEFAULT_ERROR_KEY));
          return modalCompleted$.pipe(flatMap(() => of()));
        }),
      )
      .subscribe(
        () => undefined,
        () => undefined,
      );
    return result;
  }
}
