import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { IStandardKey } from '../interfaces/iGridSystem';

@Injectable({
  providedIn: 'root',
})
export class StandardKeyService {
  constructor(private httpClient: HttpClient) {
    this.refreshAllStandardKeys();
  }

  public allStandardKeys$ = new ReplaySubject<IStandardKey[]>(1);

  public getAllStandardKeys() {
    return this.httpClient.get<any[]>(`${environment.serverUrl}/api/igrid/standardkeys`);
  }

  public refreshAllStandardKeys() {
    return this.getAllStandardKeys()
      .pipe(first())
      .subscribe((sk) => this.allStandardKeys$.next(sk));
  }
}
