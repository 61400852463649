<div>
  <button (click)="createNewCompany()">Create new Company</button>
  <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button>

  <!-- List -->
  <div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" sortable="CompanyId">CompanyId</th>
          <th *ngIf="uiState === 'edit'" scope="col">Modify</th>
          <th class="deleteColumn" *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody>
        <!-- | async -->
        <tr *ngFor="let company of companies$ | async">
          <th scope="row">{{ company.id }}</th>
          <td>{{ company.name }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyCompany(company)">Modify</button>
          </td>
          <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteCompany(company)">X</button>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Create -->
  <div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
    <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <input class="formElement" placeholder="Name" type="text" formControlName="Name" />
      <button type="submit" [disabled]="!form.valid">Submit</button>
      <button type="reset" (click)="cancelNewCompany()">Cancel</button>
    </form>
  </div>
</div>
