import { Component, Input } from '@angular/core';
import { SettingsTreeNode } from '../igrid-summary-page/igrid-summary-page.component';
import { CommissionService } from '../../services/commission.service';

@Component({
  selector: 'app-settings-tree',
  templateUrl: './settings-tree.component.html',
  styleUrls: ['./settings-tree.component.scss'],
})
export class SettingsTreeComponent {
  @Input() settings: SettingsTreeNode[];

  constructor(private commissionService: CommissionService) {}

  getIcon(action: string): string {
    return this.commissionService.getStatusIcon(action);
  }
}
