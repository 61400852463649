import { Component, OnInit } from '@angular/core';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { AppErrorService, PageInfo, AppError, ECommissionStatus, ModalService } from 'shared';
import { Observable, of, combineLatest } from 'rxjs';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { map, first, tap } from 'rxjs/operators';
import { IReducedInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';
import { InstallationsService } from 'projects/serviceportal/src/app/services/installations.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { CompanyService } from 'projects/serviceportal/src/app/services/company.service';
import { TranslateService } from '@ngx-translate/core';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';
import { Router } from '@angular/router';
import { UserService } from 'projects/serviceportal/src/app/services/user.service';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';

@Component({
  selector: 'app-archive-page',
  templateUrl: './archive-page.component.html',
  styleUrls: ['./archive-page.component.scss'],
})
export class ArchivePageComponent implements OnInit {
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;

  public companies$: Observable<ICompany[]>;
  public facilities$: Observable<IFacility[]>;
  public installations$: Observable<IReducedInstallationDTO[]>;
  public users$: Observable<IUser[]>;

  public form = new UntypedFormGroup({
    installations: new UntypedFormControl([]),
    facilities: new UntypedFormControl([]),
    companies: new UntypedFormControl([]),
    users: new UntypedFormControl([]),
  });

  constructor(
    private pageInfoService: PageInfoService,
    private appErrorService: AppErrorService,
    private facilityService: FacilityService,
    private installationService: InstallationsService,
    private companyService: CompanyService,
    private userService: UserService,
    private modalService: ModalService,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.pageInfo$ = of(this.pageInfoService.archivePage());

    this.companies$ = this.companyService.companies$.pipe(
      map((companies) => {
        return companies.filter((c) => c.archiveState === EArchiveState.Archived);
      }),
    );

    this.facilities$ = this.facilityService.facilities$.pipe(
      map((facilities) => {
        return facilities.filter((facility) => facility.archiveState === EArchiveState.Archived);
      }),
    );

    this.users$ = this.userService.usersWithCompany$.pipe(
      map((users) => {
        return users.filter((u) => u.archiveState === EArchiveState.Archived);
      }),
    );

    this.installations$ = this.installationService
      .getArchivedInstallations()
      .pipe(map((s) => s.map((z) => ({ ...z, name: `${z.serialNumber} [${z.deviceId}]` }))));

    const loading$ = combineLatest([this.facilities$, this.installations$, this.companies$]).pipe(first());

    this.pageError$ = this.appErrorService.createPageErrorObservable([loading$]);
  }

  ngOnInit(): void {
    this.userService.refreshAllUsers();
    this.facilityService.reloadFacilities();
  }

  removeCompanies() {
    const companyIds = this.form.value.companies.map((c) => Number(c));

    if (!companyIds || !companyIds.length) {
      return;
    }

    this.modalService
      .showConfirmModal(
        this.translate.instant('archive-page.remove-companies'),
        this.translate.instant('archive-page.confirm-remove-companies'),
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.companyService.archiveForDeletion(companyIds).subscribe((res) => {
            this.installations$ = this.installationService.getArchivedInstallations();
            this.facilityService.reloadFacilities();
            this.userService.refreshAllUsers();
          });
        }
      });
  }

  removeFacilities() {
    const facilityIds = this.form.value.facilities.map((f) => Number(f));

    if (!facilityIds || !facilityIds.length) {
      return;
    }

    this.modalService
      .showConfirmModal(
        this.translate.instant('archive-page.remove-facilities'),
        this.translate.instant('archive-page.confirm-remove-facilities'),
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.facilityService.archiveForDeletion(facilityIds).subscribe((res) => {
            this.installations$ = this.installationService.getArchivedInstallations();
          });
        }
      });
  }

  removeInstallations() {
    const installationIds = this.form.value.installations;

    if (!installationIds || !installationIds.length) {
      return;
    }

    this.modalService
      .showConfirmModal(
        this.translate.instant('archive-page.remove-installations'),
        this.translate.instant('archive-page.confirm-remove-installations'),
      )
      .subscribe((confirmed) => {
        if (confirmed) {
          this.installationService.archiveForDeletion(installationIds).subscribe((res) => {
            this.installations$ = this.installationService.getArchivedInstallations();
          });
        }
      });
  }

  removeUsers() {
    const userIds = this.form.value.users;

    if (!userIds || userIds.length === 0) {
      return;
    }

    this.modalService
      .showConfirmModal(this.translate.instant('archive-page.remove-users'), this.translate.instant('archive-page.confirm-remove-users'))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.userService.archiveUserForDeletion(userIds).subscribe((res) => {
            this.installations$ = this.installationService.getArchivedInstallations();
          });
        }
      });
  }

  clickUser(id: string) {
    this.router.navigate(['/users/view/', id]);
  }

  clickCompany(id: any) {
    this.router.navigate(['/company/view/', id]);
  }

  clickFacility(id: any) {
    this.router.navigate(['/facility/view/', id]);
  }

  clickInstallation(id: any) {
    // Need to get all the facilities, as the installation might live under a facility that is not archived
    this.facilityService.facilities$
      .pipe(
        first(),
        tap((facilities) => {
          const foundFacility = facilities.find((facility) => facility.installations.find((i) => i.id === id));
          switch (foundFacility.facilityType) {
            case 'BuildingConnect':
              this.router.navigate(['/buildingconnect/installation', foundFacility.id, id]);
              break;
            case 'iGrid':
              this.router.navigate(['/igrid/installation', foundFacility.id, id]);
              break;
            default:
              console.error('Unhandled facility type');
          }
        }),
      )
      .subscribe();
  }
}
