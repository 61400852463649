import { Component } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { IAccessClaimsGroup } from 'projects/serviceportal/src/app/interfaces/accessClaimsGroups';
import { SortByValuePipe } from '../../../pipes/sort-by-value.pipe';
import { AccessclaimsgroupsService } from '../../../services/accessclaimsgroups.service';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}
@Component({
  selector: 'app-accessclaimsgroup-page',
  templateUrl: './accessclaimsgroup-page.component.html',
  styleUrls: ['./accessclaimsgroup-page.component.scss'],
})
export class AccessClaimsGroupPageComponent {
  uiState = UIState.edit;
  accessClaimsGroups$;
  form;
  accessClaims$;

  selectedGroupId;

  constructor(
    private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private accessClaimsGroupService: AccessclaimsgroupsService,
    private sortByValuePipe: SortByValuePipe,
  ) {
    this.accessClaimsGroups$ = accessClaimsGroupService.accessClaimsGroups$;
    this.accessClaims$ = accessClaimsGroupService.accessClaims$;

    this.form = formBuilder.group({
      Id: [''],
      Name: ['', Validators.required],
      AccessClaims: [''],
    });
  }

  private getFormAsIAccessClaimsGroup(): IAccessClaimsGroup {
    return {
      name: this.form.get('Name').value,
      accessClaims: this.form.get('AccessClaims').value,
    };
  }

  private getFormAsIAccessClaimsGroupWithId(): IAccessClaimsGroup {
    return {
      id: this.form.get('Id').value,
      name: this.form.get('Name').value,
      accessClaims: this.form.get('AccessClaims').value,
    };
  }

  onSubmit() {
    // new user
    if (this.selectedGroupId === null) {
      this.accessClaimsGroupService.createAccessClaimsGroup(this.getFormAsIAccessClaimsGroup());
    } else {
      // editing existing user
      this.accessClaimsGroupService.patchAccessClaimsGroup(this.getFormAsIAccessClaimsGroupWithId());
    }

    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.list;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewAccessClaimsGroup() {
    this.uiState = UIState.create;
    this.selectedGroupId = null;

    this.form.patchValue({
      Name: '',
      Id: '',
      AccessClaims: '',
    });
  }

  deleteAccessClaimsGroup(accessClaimsGroup: IAccessClaimsGroup) {
    if (confirm('Confirm deletion of: ' + accessClaimsGroup.name)) {
      this.accessClaimsGroupService.deleteAccessClaimsGroup(accessClaimsGroup);
    }
  }

  modifyAccessClaimsGroup(accessClaimsGroup: IAccessClaimsGroup) {
    this.uiState = UIState.modify;
    this.selectedGroupId = accessClaimsGroup.id;

    this.form.patchValue({
      Id: accessClaimsGroup.id,
      Name: accessClaimsGroup.name,
      AccessClaims: this.sortByValuePipe.transform(accessClaimsGroup.accessClaims),
    });
  }

  cancelNewAccessClaimsGroup() {
    this.uiState = UIState.edit;
  }
}
