<gbc-tile [title]="'create-edit-igrid-installation.settings.title' | translate" style="margin-top: 2em">
  <p-tree [value]="settings">
    <ng-template let-node pTemplate="setting">
      <img *ngIf='getIcon(node.action)'
        [src]="getIcon(node.action)"
        height="29px"
        width="29px"
      /> {{ node.text }}
    </ng-template>
    <ng-template let-node pTemplate="default">
      <b>{{ node.text }}</b>
    </ng-template>
  </p-tree>

</gbc-tile>
