<cdk-virtual-scroll-viewport
  [minBufferPx]="virtualScrollPortBufferHeight"
  [maxBufferPx]="virtualScrollPortBufferHeight"
  [itemSize]="60"
  *ngIf="facilities"
  #virtualScrollport
  class="virtual-viewport"
>
  <gbc-table
    [stickyHeaders]="true"
    [suppressAutoAddChevronHeader]="true"
    [clickableRows]="true"
    *ngIf="inverseOfTranslation as topPosition"
  >
    <th [style.top]="topPosition" gbc-table-header>
      {{ 'facility-list.name' | translate }}
    </th>
    <th [style.top]="topPosition" gbc-table-header>
      {{ 'facility-list.facility-type' | translate }}
    </th>
    <th [style.top]="topPosition" gbc-table-header>
      {{ 'facility-list.address' | translate }}
    </th>
    <th [style.top]="topPosition" gbc-table-header>
      {{ 'facility-list.city-and-postal-code' | translate }}
    </th>
    <th [style.top]="topPosition" gbc-table-chevron-header></th>

    <ng-container *cdkVirtualFor="let facility of facilities">
      <tr gbc-table-row [routerLink]="['/facility/view', facility.id]">
        <td gbc-table-cell>{{ facility.name }}</td>
        <td gbc-table-cell>{{ facility.facilityType }}</td>
        <td gbc-table-cell>{{ facility.addressRoad }}</td>
        <td gbc-table-cell>{{ facility.addressCity }}, {{ facility.addressPostal }}</td>
      </tr>
    </ng-container>
  </gbc-table>
</cdk-virtual-scroll-viewport>
