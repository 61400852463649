import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatBytes',
  pure: true,
})
export class FormatBytesPipe implements PipeTransform {
  transform(numBytes, decimals = 2) {
    if (0 === numBytes) {
      return '0 Bytes';
    }
    const c = 0 > decimals ? 0 : decimals;
    const d = Math.floor(Math.log(numBytes) / Math.log(1024));
    return parseFloat((numBytes / Math.pow(1024, d)).toFixed(c)) + ' ' + ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d];
  }
}
