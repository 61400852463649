import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

export function vhToPixels(viewPortHeightInPx, targetVhPercentage = 100) {
  return Math.round(viewPortHeightInPx / (100 / targetVhPercentage)) + 'px';
}

export function inverseOfTranslation(viewport: CdkVirtualScrollViewport): string {
  const offset = viewport?.getOffsetToRenderedContentStart();
  if (!viewport || !offset) {
    return '-0px';
  }
  return `-${offset}px`;
}
