import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ValidationMessagesModalComponent } from 'projects/shared/src/lib/components/validation-messages-modal/validation-messages-modal.component';
import { forkJoin, of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared';
import { ICompany } from '../../interfaces/company';
import { EBatchStatus, IBatchListItem, IGisSourceFileRecord } from '../../interfaces/gisSource';
import { GisSourceService } from '../../services/gis-source.service';

type IGisSourceFileRecordUI = IGisSourceFileRecord & { loading?: boolean }; // Helper type to easily control disabling etc when API call is ongoing for a file

@Component({
  selector: 'app-gis-sources-list',
  templateUrl: './gis-sources-list.component.html',
  styleUrls: ['./gis-sources-list.component.scss'],
})
export class GisSourcesListComponent implements OnInit {
  constructor(
    private gisService: GisSourceService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  @Input()
  public gisSources!: Array<IGisSourceFileRecordUI>; // Input is a list of GIS sources

  @Input()
  public company?: ICompany; // May be injected with a companyName; this is not a given (to allow this list component to be used for admin purposes in the future.)
  public eBatchStatus = EBatchStatus; // May be injected with a companyName; this is not a given (to allow this list component to be used for admin purposes in the future.)

  ngOnInit(): void {}

  /**
   * Handles delete btn pressed.
   * @param gisSource gisSource is passed from template
   */
  public deleteGisSource(gisSource: IGisSourceFileRecordUI) {
    gisSource.loading = true;
    this.translateService
      .get(['gis-sources-list.delete-warning-title', 'gis-sources-list.delete-warning-description'])
      .pipe(first())
      .subscribe(
        ({ 'gis-sources-list.delete-warning-title': modalTitle, 'gis-sources-list.delete-warning-description': modalText }: any) => {
          this.modalService
            .showConfirmModal(modalTitle, modalText)
            .pipe(first())
            .subscribe((deleteIntendConfirmed) => {
              if (!deleteIntendConfirmed) {
                gisSource.loading = false;
                return;
              }

              /* If source has imports: First delete all imports, then delete the source. */
              let promisesToDeleteSourceImport = gisSource.imports.map((iMport) => this.gisService.deleteGisSourceImport(iMport.id));
              if (!promisesToDeleteSourceImport.length) {
                promisesToDeleteSourceImport = [of(null)]; // set it to contain a single instantly resolved obsersable with no value (avoids having to use more than one return statement)
              }

              return forkJoin(promisesToDeleteSourceImport)
                .pipe(
                  first(), // also triggered when there's no imports to delete, due to how we fallback according to the comment above
                  switchMap(() => this.gisService.deleteGisSource(gisSource.id)),
                  first(),
                )
                .subscribe(
                  () => {
                    this.gisService.refreshGisDataSources();
                  },
                  () => {
                    gisSource.loading = false;
                  },
                  () => {
                    gisSource.loading = false;
                  },
                );
            });
        },
      );
  }

  public viewValidationErrors(impørt: IBatchListItem) {
    this.translateService
      .get('gis-sources-list.validation-errors')
      .pipe(
        switchMap((validationErrorsTransltion) =>
          this.modalService.openDialog<void>(ValidationMessagesModalComponent, {
            data: {
              validationMessages: impørt.validationMessages,
              title: validationErrorsTransltion,
            },
          }),
        ),
      )
      .subscribe();
  }

  public indexGisSource(gisSource: IGisSourceFileRecordUI) {
    gisSource.loading = true;
    this.gisService
      .indexGisSource(gisSource.id)
      .pipe(first())
      .subscribe(
        () => {
          // Workaround for some timing issue with the API???
          setTimeout(() => {
            this.gisService.refreshGisDataSources();
            gisSource.loading = false;
          }, 150);
        },
        () => {
          gisSource.loading = false;
        },
      );
  }
}
