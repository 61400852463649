import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { IIGridDeviceConfigurationDTO } from '../../interfaces/iGridInstallationConfiguration';
import { IFacility } from '../../interfaces/facility';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { StateResponse, SystemStateSetting } from '../../interfaces/iGridDeviceState';
import { TranslateService } from '@ngx-translate/core';

export interface SettingsTreeNode {
  label: string;
  text: string;
  key: string;
  type?: string;
  action: string;
  expanded: boolean;
  children: SettingsTreeNode[];
}
@Component({
  selector: 'app-igrid-summary-page',
  templateUrl: './igrid-summary-page.component.html',
  styleUrls: ['./igrid-summary-page.component.scss'],
})
export class IGridSummaryPageComponent implements OnChanges {
  constructor(private translateService: TranslateService) {}

  @Input() facility: IFacility;
  @Input() installation: IIGridSystem | Partial<IIGridSystem>;
  @Input() company: ICompany;
  @Input() configuration: IIGridDeviceConfigurationDTO;
  @Input() routings: any;
  @Input() navigateRoutings = true;
  @Input() systemState: StateResponse | undefined;
  @Input() warning: IGridSummaryPageWarning | undefined;

  settingsData: SettingsTreeNode[];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.systemState && changes.systemState.currentValue) {
      this.settingsData = this.buildSettingsTree(this.systemState.state?.system?.settings);
    }
  }

  buildSettingsTree(settings: SystemStateSetting[]): SettingsTreeNode[] {
    const tree: SettingsTreeNode[] = [];

    settings.forEach((setting) => {
      const pathArray = setting.path.split('/');
      let currentLevel: SettingsTreeNode[] = tree;

      pathArray.forEach((path, index) => {
        const existingNode = currentLevel.find((node) => node.label === path);

        if (existingNode) {
          currentLevel = existingNode.children;
        } else {
          const newNode: SettingsTreeNode = {
            label: path,
            text: this.translateService.instant('igrid-installation-settings.' + path),
            action: setting.action,
            key: index === pathArray.length - 1 ? setting.order.toString() : '',
            expanded: false,
            children: [],
          };
          currentLevel.push(newNode);
          currentLevel = newNode.children;
        }
      });
    });
    
    this.markAllSettings(tree);
    this.sortChildrenByKey(tree, "key");
    return tree;
  }

  markAllSettings(nodes: SettingsTreeNode[]) {
    nodes.forEach((node) => {
      if (node.children.length === 0) {
        node.type = 'setting';
      }
      node.expanded = node.children.length > 0;
      this.markAllSettings(node.children);
    });
  }

  sortChildrenByKey(tree: SettingsTreeNode[], key: string) {
    tree.forEach(node => {
      if (node.children && node.children.length > 0) {
        node.children.sort((a, b) => a[key] - b[key]); // Sort children by the specified key
        this.sortChildrenByKey(node.children, key); // Recursively sort the children of children
      }
    });
  }

}

export class IGridSummaryPageWarning {
  constructor() {}

  titleTranslationId: string;
  textTranslationId: string;
}
