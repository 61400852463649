<div class="page">
  <button (click)="createNewServiceGroup()">Create new ServiceGroup</button>
  <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button>

  <!-- ListServiceGroup -->
  <div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" sortable="Name">Name</th>
          <th scope="col" sortable="UserCount">User Count</th>
          <th scope="col" sortable="CompanyCount">Company Count</th>
          <th *ngIf="uiState === 'edit'" scope="col">Modify</th>
          <th *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody *ngIf="servicegroups$ | async as servicegroups">
        <!-- | async -->
        <tr *ngFor="let servicegroup of servicegroups">
          <th scope="row">{{ servicegroup.id }}</th>
          <td>{{ servicegroup.name }}</td>
          <td>{{ servicegroup.userIds.length }}</td>
          <td>{{ servicegroup.companyIds.length }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyServiceGroup(servicegroup)">Modify</button>
          </td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteServiceGroup(servicegroup)">X</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- CreateUser -->
  <div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
    <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="activeRow ml-1" *ngIf="form.Id">
        ID:
        <label class="formElement">{{ form.Id }}</label>
      </div>
      <input class="formElement" placeholder="Name" type="text" formControlName="Name" />
      <ng-select
        *ngIf="coreusers$ | async as coreusers"
        class="select"
        [items]="coreusers"
        bindLabel="email"
        bindValue="id"
        [multiple]="true"
        placeholder="Select Users"
        formControlName="Users"
      >
      </ng-select>

      <ng-select
        class="select"
        [items]="companies$ | async"
        bindLabel="name"
        bindValue="id"
        [multiple]="true"
        placeholder="Select Companies"
        formControlName="Companies"
      >
      </ng-select>

      <button type="submit" [disabled]="!form.valid">Submit</button>
      <button type="reset" (click)="cancelNewUser()">Cancel</button>
    </form>
  </div>
</div>
