import { Component, Inject, OnInit } from '@angular/core';
import { ITextModalAction, ITextModalData, MODAL_CONTROLLER, MODAL_DATA, ModalController } from '../../interfaces/modal';

@Component({
  selector: 'gbc-text-modal',
  templateUrl: './text-modal.component.html',
  styleUrls: ['./text-modal.component.css'],
})
export class TextModalComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA) public data: ITextModalData,
    @Inject(MODAL_CONTROLLER)
    private controller: ModalController<ITextModalAction>,
  ) {}

  selectAction(action: ITextModalAction) {
    this.controller.complete(action);
  }

  ngOnInit(): void {}
}
