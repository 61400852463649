import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { flatMap, shareReplay } from 'rxjs/operators';
import { IAccessClaimsGroup } from 'projects/serviceportal/src/app/interfaces/accessClaimsGroups';
import { createRefreshSubject, serverUrlToken } from 'shared';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class AccessclaimsgroupsService {
  accessClaimsGroups$: Observable<IAccessClaimsGroup[]>;
  accessClaims$: Observable<string[]>;
  accessLevels$: Observable<string[]>;

  private refreshAccessClaimsGroups: () => Observable<IAccessClaimsGroup[]>;
  private refreshAccessLevels: () => Observable<string[]>;

  private httpGetAccessClaimsGroups(): Observable<IAccessClaimsGroup[]> {
    return this.httpClient.get<IAccessClaimsGroup[]>(`${this.serverUrl}/api/access/claimsgroups`);
  }

  private httpGetAccessLevels(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.serverUrl}/api/access/levels`);
  }

  private httpGetAccessClaims(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.serverUrl}/api/access/claims`);
  }

  constructor(
    private httpClient: HttpClient,
    @Inject(serverUrlToken) private serverUrl: string,
    currentUserService: CurrentUserService,
  ) {
    const { subject: accessClaimsGroupsSubject, scheduleRefresh: refreshAccessClaimsGroups } = createRefreshSubject(
      () => this.httpGetAccessClaimsGroups(),
      currentUserService.initialCurrentUser$,
    );
    this.accessClaimsGroups$ = accessClaimsGroupsSubject;
    this.refreshAccessClaimsGroups = refreshAccessClaimsGroups;

    const { subject: accessLevelsSubject, scheduleRefresh: refreshAccessLevels } = createRefreshSubject(
      () => this.httpGetAccessLevels(),
      currentUserService.initialCurrentUser$,
    );
    this.accessLevels$ = accessLevelsSubject;
    this.refreshAccessLevels = refreshAccessLevels;
    this.accessClaims$ = currentUserService.initialCurrentUser$.pipe(
      flatMap(() => this.httpGetAccessClaims()),
      shareReplay(),
    );
  }

  public createAccessClaimsGroup(accessClaimsGroup): Observable<IAccessClaimsGroup> {
    const result = this.httpClient
      .put<IAccessClaimsGroup>(`${this.serverUrl}/api/access/claimsgroups`, accessClaimsGroup)
      .pipe(shareReplay());
    result.subscribe(() => this.refreshAccessClaimsGroups());
    return result;
  }

  public patchAccessClaimsGroup(accessClaimsGroup: IAccessClaimsGroup): Observable<IAccessClaimsGroup> {
    const result = this.httpClient
      .patch<IAccessClaimsGroup>(`${this.serverUrl}/api/access/claimsgroups/${accessClaimsGroup.id}`, accessClaimsGroup)
      .pipe(shareReplay());
    result.subscribe(() => this.refreshAccessClaimsGroups());
    return result;
  }

  public deleteAccessClaimsGroup(accessClaimsGroup: IAccessClaimsGroup): Observable<unknown> {
    const result = this.httpClient.delete(`${this.serverUrl}/api/access/claimsgroups/${accessClaimsGroup.id}`).pipe(shareReplay());
    result.subscribe(() => this.refreshAccessClaimsGroups());
    return result;
  }
}
