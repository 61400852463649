import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'gbc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input() backLabel: string;
  @Input() nextLabel: string;
  @Input() doneLabel: string;

  @Input() backDisabled: boolean = false;
  @Input() nextDisabled: boolean = false;
  @Input() doneDisabled: boolean = false;

  @Output() done = new EventEmitter<void>();
  @Output() exit = new EventEmitter<void>();
}
