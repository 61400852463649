export enum FeatureFlag {
  Account = 'Account',
  UpgradeTrial = 'UpgradeTrial',
}

export interface IConfiguration {
  authority: string;
  client_id: string;
  clientScope: string;
  featureFlags: Record<FeatureFlag, boolean>;
}
