export interface IGisSourceFileRecord {
  id: string;
  fileName: string;
  description: string;
  owner: string;
  created: string;
  geometryClass: string;
  companyId: number;
  imports: IBatchListItem[];
}

export enum EGeometryClass {
  'MainPipe' = 'MainPipe',
  'ServiceConnection' = 'ServiceConnection',
}

export interface IBatchListItem {
  id: string;
  created: string;
  status: EBatchStatus;
  statusMessage?: string;
  validationMessages?: string[];
}

export enum EBatchStatus {
  'Created' = 'Created',
  'Started' = 'Started',
  'Completed' = 'Completed',
  'Failed' = 'Failed',
}
