<Div>
  <div class="section-training">
    <button gbc-button (click)="createTrainingData()">Create Training Data</button>
    <button gbc-button (click)="deleteTrainingData()">Delete Training Data</button>
    Training Data State: {{ error }}
  </div>

  <div>
    <button (click)="createNewUser()">Create new user</button>
    <!-- <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button> -->
    Filter:
    <gbc-input-field
      class="overview-header-row-right-container-filter"
      [placeholderTranslationString]="'create-edit-user.name'"
      [(ngModel)]="currentfilter"
    >
    </gbc-input-field>
    <!-- ListUsers -->
    <div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col" sortable="Name">Name</th>
            <th scope="col" sortable="CompanyName">Company</th>
            <th scope="col" sortable="Phone">Phone</th>
            <th scope="col" sortable="Email">Email</th>
            <th *ngIf="uiState === 'edit'" scope="col">Modify</th>
            <th *ngIf="uiState === 'edit'" scope="col">X</th>
          </tr>
        </thead>
        <tbody *ngIf="users$ | async | filterCompanyOnName: currentfilter as users">
          <!-- | async -->
          <tr *ngFor="let user of users">
            <th scope="row">{{ user.id }}</th>
            <td>{{ user.name }}</td>
            <td>{{ user.company?.name }}</td>
            <td>{{ user.phone }}</td>
            <td>{{ user.email }}</td>
            <td>
              <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyUser(user)">Modify</button>
            </td>
            <td>
              <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteUser(user)">X</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- CreateUser -->
    <div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
      <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="activeRow ml-1" *ngIf="selectedUserId">
          ID:
          <label class="formElement">{{ selectedUserId }}</label>
        </div>
        <input class="formElement" placeholder="Name" type="text" formControlName="Name" />

        <input class="formElement" placeholder="Phone Number" type="text" formControlName="Phone" />
        <input class="formElement" placeholder="Email" type="text" formControlName="Email" />

        <!-- ServiceGroups -->
        <ng-select
          class="select"
          placeholder="Select ServiceGroup(s)"
          [items]="serviceGroups$ | async | sortByName"
          bindLabel="name"
          [searchable]="true"
          formControlName="ServiceGroups"
          [multiple]="true"
        >
        </ng-select>

        <!-- Company -->
        <div *ngIf="form.controls.ServiceGroups.value">
          <ng-select
            class="select"
            [items]="companies$ | async"
            bindLabel="name"
            bindValue="id"
            [multiple]="false"
            placeholder="Select Company"
            formControlName="CompanyId"
          >
          </ng-select>
        </div>
        <button type="submit" [disabled]="!form.valid">Submit</button>
        <button type="reset" (click)="cancelNewUser()">Cancel</button>
      </form>
    </div>
  </div>
</Div>
