import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CommandService, createRefreshSubject } from 'shared';
import { shareReplay } from 'rxjs/operators';
import { CurrentUserService } from './current-user.service';
import { ICompany, ICreateCompanyDTO, IPatchCompany } from 'projects/serviceportal/src/app/interfaces/company';
import { AppErrorService } from 'shared';

const CREATE_COMPANY_ERROR_CODES = {
  'company-owner-not-self': {
    messageKey: 'create-edit-company.owner-is-self',
  },
};

@Injectable({
  providedIn: 'root',
})
export class CompanyService {
  companies$: Observable<ICompany[]>;

  private refreshCompanies: () => Observable<ICompany[]>;

  constructor(
    private httpClient: HttpClient,
    private currentUserService: CurrentUserService,
    private commandService: CommandService,
    private appErrorService: AppErrorService,
  ) {
    const { subject, scheduleRefresh } = createRefreshSubject(() => this.httpGetCompanies(), currentUserService.initialCurrentUser$);
    this.companies$ = subject;
    this.refreshCompanies = scheduleRefresh;
  }

  public getCompany(companyId: number): Observable<ICompany> {
    return this.httpClient.get<ICompany>(`${environment.serverUrl}/api/companies/${companyId}`).pipe(
      this.appErrorService.catchApiError({
        fallbackMessageKey: 'company-service.get-company-failed',
        errorCodes: {
          403: {
            messageKey: 'company-service.get-company-not-allowed',
          },
        },
      }),
    );
  }

  public httpGetCompanies(): Observable<ICompany[]> {
    return this.httpClient.get<ICompany[]>(`${environment.serverUrl}/api/companies`).pipe(
      this.appErrorService.catchApiError({
        fallbackMessageKey: 'company-service.get-companies-failed',
        errorCodes: {
          403: {
            messageKey: 'company-service.get-companies-not-allowed',
          },
        },
      }),
    );
  }

  public createCompany(company: ICompany): Observable<ICompany> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<ICompany>(`${environment.serverUrl}/api/companies`, company).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.create-company-error',
            errorCodes: CREATE_COMPANY_ERROR_CODES,
          }),
          shareReplay(),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          () => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.company-created' },
    );
  }

  public createNewCompanyWithContactDetails(company: ICreateCompanyDTO): Observable<ICompany> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<ICompany>(`${environment.serverUrl}/api/companies/new`, company).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.create-company-error',
            errorCodes: CREATE_COMPANY_ERROR_CODES,
          }),
          shareReplay(),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          () => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.company-created' },
    );
  }

  public patchCompany(company: IPatchCompany): Observable<ICompany> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.patch<ICompany>(`${environment.serverUrl}/api/companies/${company.id}`, company).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.update-company-error',
          }),
          shareReplay(),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.company-updated' },
    );
  }

  public deleteCompany(company: ICompany): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.delete(`${environment.serverUrl}/api/companies/${company.id}`).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.delete-company-error',
          }),
          shareReplay(),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.company-deleted' },
    );
  }

  public archive(companyId: number): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/companies/archiveCompany/${companyId}`, {}).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.archive-failed',
            errorCodes: {
              403: {
                messageKey: 'company-service.archive-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.archive-success' },
    );
  }

  public archiveForDeletion(companyIds: number[]): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/companies/archiveCompaniesForDeletion`, companyIds).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'company-service.archive-for-deletion-failed',
            errorCodes: {
              403: {
                messageKey: 'company-service.archive-for-deletion-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.refreshCompanies(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'company-service.archive-for-deletion-success' },
    );
  }
}
