<gbc-tile [title]="'igrid-installation-module-configuration-form.title' | translate">
  <form [formGroup]="form">
    <ng-container *ngIf="(modules | async)?.length; else noModules">
      <div *ngFor="let module of modules | async">
        <div class="module-name-container">
          <b>{{ module.name }}</b>
        </div>
        <json-editor [options]="makeOptions()" [formControlName]="module.name"></json-editor>
      </div>
    </ng-container>

    <ng-template #noModules>
      {{ 'NO MODULES' | translate }}
    </ng-template>
  </form>
</gbc-tile>
