import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { StateResponse } from '../interfaces/iGridDeviceState';

@Injectable({
  providedIn: 'root',
})
export class SystemStateService {
  constructor(
    private httpClient: HttpClient,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  private baseUrl = environment.serverUrl;

  public getState(deviceId: string, systemTypeId: number, serialNumber: string): Observable<StateResponse> {
    const url =
      `${this.baseUrl}/api/igrid/system/device/${deviceId}/DisSync` + `?systemTypeId=${systemTypeId}&serialNumber=${serialNumber}`;
    return this.httpClient.get<StateResponse>(url);
  }
}
