<gbc-tile [title]="'igrid-installation-configuration-form.title' | translate">
  <form [formGroup]="form">
    <gbc-input-container>
      <gbc-input-label>{{ 'igrid-installation-configuration-form.serial-number' | translate }}*</gbc-input-label>
      <gbc-input-field #serialNumberInput formControlName="serialNumber"></gbc-input-field>
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>{{ 'igrid-installation-configuration-form.hardware-id' | translate }}*</gbc-input-label>
      <gbc-input-field #hardwareIdInput formControlName="hardwareId"></gbc-input-field>
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>{{ 'igrid-installation-configuration-form.installation-type' | translate }}*</gbc-input-label>
      <gbc-dropdown
        [minWidth]="500"
        formControlName="installationType"
        placeholderTranslationString="app-select"
        [dropDownItems]="installationTypesMapped$ | async"
        #installationTypeDropdown
      ></gbc-dropdown>
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>{{ 'igrid-installation-configuration-form.device-group' | translate }}</gbc-input-label>
      <gbc-input-field #deviceGroup formControlName="deviceGroup"></gbc-input-field>
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>{{ 'igrid-installation-configuration-form.commission-status' | translate }}*</gbc-input-label>
      <gbc-dropdown
        formControlName="commissionStatus"
        placeholderTranslationString="app-select"
        [dropDownItems]="commissionStatus$ | async"
        [disabled]="true"
        #commissionStatusDropdown
      ></gbc-dropdown>
    </gbc-input-container>
    <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
  </form>
</gbc-tile>
