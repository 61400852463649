import { Component, AfterContentInit } from '@angular/core';
import { CompanyService } from '../../services/company.service';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ICompany } from '../../interfaces/company';
import { IFacility } from '../../interfaces/facility';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { IReducedInstallationDTO } from '../../interfaces/installation';
import { PageInfoService } from '../../services/page-info.service';
import { IUser } from '../../interfaces/user';
import { UserService } from '../../services/user.service';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';

export type TTabs = 'development' | 'installed' | 'closed';

export interface IExtendedInstallation extends IReducedInstallationDTO {
  facility: IFacility;
  cssStatusClasses: {
    blue: boolean;
    orange: boolean;
    green: boolean;
    red: boolean;
  };
}

export interface IExtendedCompany extends ICompany {
  installations: IExtendedInstallation[];
}

@Component({
  selector: 'app-overview-page',
  templateUrl: './overview-page.component.html',
  styleUrls: ['./overview-page.component.scss'],
})
export class OverviewPageComponent implements AfterContentInit {
  public pageInfo: PageInfo;

  public pageError$: Observable<AppError>;
  private companies$: Observable<ICompany[]>;
  public companiesFilteredList$: Observable<ICompany[]>;
  public users$: Observable<IUser[]>;

  public form = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  public showCreateCompanyButton$: Observable<boolean>;
  public showCreateUserButton$: Observable<boolean>;
  public showCreateIgridSystemButton$: Observable<boolean>;

  constructor(
    private companyService: CompanyService,
    public route: ActivatedRoute,
    private pageInfoService: PageInfoService,
    private userService: UserService,
    errorService: AppErrorService,
  ) {
    this.companies$ = this.companyService.httpGetCompanies();

    this.companiesFilteredList$ = combineLatest([this.form.controls.search.valueChanges.pipe(startWith('')), this.companies$]).pipe(
      map(([search, companies]) => {
        const activeCompanies = companies.filter((c) => c.archiveState === EArchiveState.Active);

        if (!search) {
          return activeCompanies;
        }

        return activeCompanies.filter((company) => company.name.toLowerCase().includes(search.toLowerCase()));
      }),
    );

    this.users$ = this.userService.usersWithCompany$.pipe(
      map((users) => users.filter((user) => user.archiveState === EArchiveState.Active)),
    );

    this.pageError$ = errorService.createPageErrorObservable([this.companies$]);

    this.pageInfo = this.pageInfoService.overviewPageCompanies();
  }

  ngAfterContentInit() {
    this.showCreateCompanyButton$ = this.route.params.pipe(map((params) => params.tab === 'companies'));

    this.showCreateUserButton$ = this.route.params.pipe(map((params) => params.tab === 'users'));
    this.showCreateIgridSystemButton$ = this.route.params.pipe(map((params) => params.tab === 'installations'));
  }
}
