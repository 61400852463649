<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<div class="header-container">
  <div class="header">
    <div class="header-button-container" *ngIf="pageInfo.parent">
      <a class="header-button" [routerLink]="pageInfo.parent.link"><img src="./assets/svgs/back.svg" alt="Back" /></a>
    </div>

    <div class="header-headline-container">
      <h2 class="header-headline">
        {{ pageInfo.title | async }}
        <div class="menu" *ngIf="buttons && buttons.length">
          <div class="menu-wrapper" [ngClass]="{ 'menu-open': menuOpen }">
            <button class="menu-button" gbc-text-button (click)="menuOpen = !menuOpen">
              <img alt="More" src="./assets/svgs/menu-more.svg" />
            </button>
            <div *ngIf="menuOpen" class="menu-buttons">
              <ng-content select="[gbc-page-header-actions]"></ng-content>
            </div>
          </div>
        </div>
      </h2>
      <ng-container *ngFor="let subtitle of pageInfo.subtitles">
        <p *ngIf="subtitle" class="header-subheader">{{ subtitle | async }}</p>
      </ng-container>
    </div>
    <div class="header-button-container">
      <div>
        <ng-content select="[gbc-page-header-right]"></ng-content>
      </div>
    </div>
  </div>
</div>
