import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { NEVER, Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    public auth: AuthService,
    private router: Router,
  ) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.auth.currentUser.value) {
      if (this.auth.currentUser.value.expires_in < 10) {
        // Apparently, token renew has failed for some reason.
        // Start new signin flow
        this.auth.signinRedirect(this.router.url);
        return NEVER;
      }
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.auth.currentUser.value.access_token}`,
        },
      });
    }
    return next.handle(request).pipe(
      catchError((response) => {
        if (response instanceof HttpErrorResponse) {
          if (response.status === 401) {
            this.auth.signinRedirect('/');
          }
        }

        return throwError(response);
      }),
    );
  }
}
