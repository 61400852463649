import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import moment from 'moment';
import { IBatchListItem } from '../interfaces/gisSource';

@Pipe({
  name: 'latestGisImportTime',
})
export class LatestGisImportTimePipe implements PipeTransform {
  transform(value: IBatchListItem[], ...args: unknown[]): unknown {
    if (value.length) {
      const [latestImport] = orderBy(value, (_import) => _import.created, 'desc');
      return moment(latestImport.created).format('DD. MMM YYYY, HH:mm:ss');
    }
    return null;
  }
}
