<div class="input-container">
  <input
    [type]="type"
    [attr.name]="name"
    [(ngModel)]="value"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [maxlength]="maxlength"
    [ngClass]="{ error: error }"
  />
  <span class="error-message">{{ error }}</span>
</div>
