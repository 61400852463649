import { AfterContentInit, Component, ContentChildren, QueryList, OnDestroy } from '@angular/core';
import { TabComponent } from '../tab/tab.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gbc-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements AfterContentInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  ngAfterContentInit() {
    // You can navigate to the same page, but without the parameters, this causes no tab to be selected
    // By listening to the params, we make sure we always have a selected tab
    this.subscription.add(
      this.route.params.subscribe((params) => {
        // get all active tabs
        if (!params.tab) {
          this.router.navigate(
            [
              {
                tab: this.tabs.first.name,
              },
            ],
            {
              relativeTo: this.route,
              replaceUrl: true,
              queryParamsHandling: 'merge',
            },
          );
        }
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  selectTab(selectedTab: TabComponent) {
    this.router.navigate(
      [
        {
          tab: selectedTab.name,
        },
      ],
      { relativeTo: this.route },
    );
  }
}
