import { Component, Input, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { ECommissionStatus, InputFieldComponent, DropdownComponent } from 'shared';
import { IIGridInstallationType } from '../../interfaces/iGridInstallationConfiguration';
import { InstallationsService } from '../../services/installations.service';
import { map, startWith, tap } from 'rxjs/operators';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { startsWith } from 'lodash';

@Component({
  selector: 'app-igrid-installation-configuration-form',
  templateUrl: './igrid-installation-configuration-form.component.html',
  styleUrls: ['./igrid-installation-configuration-form.component.scss'],
})
export class IGridInstallationConfigurationFormComponent implements OnInit, AfterViewInit {
  @ViewChild('serialNumberInput') serialNumberInput: InputFieldComponent;
  @ViewChild('hardwareIdInput') hardwareIdInput: InputFieldComponent;
  @ViewChild('installationTypeDropdown')
  installationTypeDropdown: DropdownComponent;
  @ViewChild('commissionStatusDropdown')
  commissionStatusDropdown: DropdownComponent;
  @ViewChild('deviceGroup') deviceGroupInput: InputFieldComponent;

  @Input()
  public installation$: Observable<IIGridSystem>;
  @Input()
  public routings: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private installationService: InstallationsService,
  ) {
    this.form = this.formBuilder.group({
      installationId: [null],
      serialNumber: [null, Validators.required],
      hardwareId: [null, Validators.required],
      installationType: [null, Validators.required],
      deviceGroup: [null],
      commissionStatus: [ECommissionStatus.DeviceIdRegistered],
    });
    this.companies$ = this.companyService.companies$;
  }

  public form: UntypedFormGroup;
  companies$: Observable<ICompany[]>;

  installationTypes$?: Observable<IIGridInstallationType[]> = this.installationService?.getIGridInstallationTypes();
  installationTypesMapped$: Observable<IIGridInstallationType[]> = this.installationService?.getIGridInstallationTypes().pipe(
    map((iGridInstallationTypes: IIGridInstallationType[]) =>
      iGridInstallationTypes.map((iGridInstallationType: IIGridInstallationType) => {
        return this.formatInstallationDropdownName(iGridInstallationType);
      }),
    ),
  );
  commissionStatus$: BehaviorSubject<ECommissionStatus[]> = new BehaviorSubject(Object.values(ECommissionStatus));

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    combineLatest([this.installation$, this.installationTypes$]).subscribe(([installation, installationTypes]) => {
      if (!installation) {
        this.commissionStatusDropdown.setDisabledState(true);
      }
      if (installation) {
        const { serialNumber, commissionStatus, systemTypeId, deviceId: hardwareId, deviceGroup } = installation;
        if (serialNumber) {
          this.form.patchValue({ serialNumber });
          this.serialNumberInput.setDisabledState(true);
        }
        if (systemTypeId) {
          const iGridInstallationType: IIGridInstallationType = installationTypes.find((i) => `${i.id}` === `${systemTypeId}`);
          if (iGridInstallationType) {
            this.form.patchValue({
              installationType: this.formatInstallationDropdownName(iGridInstallationType),
            });
            this.installationTypeDropdown.setDisabledState(true);
          }
        }
        if (hardwareId) {
          this.form.patchValue({ hardwareId });
          this.hardwareIdInput.setDisabledState(true);
        }
        if (commissionStatus) {
          this.form.patchValue({ commissionStatus });
        }
        if (deviceGroup) {
          this.form.patchValue({ deviceGroup });
          this.deviceGroupInput.setDisabledState(true);
        }
      }
      this.form.updateValueAndValidity();
    });
    this.form.updateValueAndValidity();
  }

  private formatInstallationDropdownName(iGridInstallationType: IIGridInstallationType): IIGridInstallationType {
    return {
      ...iGridInstallationType,
      name: iGridInstallationType.systemTypeCategoryName + ` (${iGridInstallationType.description})`,
    };
  }
}
