import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'gbc-topbar-dropdown',
  templateUrl: './topbar-dropdown.component.html',
  styleUrls: ['./topbar-dropdown.component.scss'],
})
export class TopbarDropdownComponent {
  @Input() placeholder: string;

  public dropdownVisible = false;

  constructor() {}

  @HostListener('click', ['$event'])
  handleDropdownClick = () => {
    this.dropdownVisible = !this.dropdownVisible;
  };
}
