import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { IIGridFacilityDTO } from 'projects/serviceportal/src/app/interfaces/facility';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { combineLatest, fromEvent, Observable, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  first,
  map,
  tap,
  publishReplay,
  refCount,
  startWith,
  distinctUntilChanged,
  filter,
} from 'rxjs/operators';
import { inverseOfTranslation, vhToPixels } from '../../helpers/viewport.helpers';

@Component({
  selector: 'app-facility-installation-search-list',
  templateUrl: './facility-installation-search-list.component.html',
  styleUrls: ['./facility-installation-search-list.component.scss'],
})
export class FacilityInstallationSearchListComponent implements OnInit, OnDestroy {
  @ViewChild('virtualScrollport')
  virtualScrollport?: CdkVirtualScrollViewport;

  public form = new UntypedFormGroup({
    installationSearch: new UntypedFormControl(''),
  });

  public facilityTypeMap = {
    BuildingConnect: '/buildingconnect/installation',
    iGrid: '/igrid/installation',
    MIXIT: '/mixit/installation',
  };

  public allfacilities$ = this.facilityService.getIGridFacilities().pipe(
    publishReplay(1),
    refCount(),
    first((d) => !!d?.length),
  );

  public viewPortHeightInPx = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  public virtualScrollPortBufferHeight = vhToPixels(this.viewPortHeightInPx);

  // Observable of flattened list of systems matching filter with attached metadata from parent facility.
  public filteredSystems$: Observable<IIGridFacilityDTO[]> = combineLatest([
    this.form.controls.installationSearch.valueChanges.pipe(startWith('')),
    this.allfacilities$,
  ]).pipe(
    debounceTime(150),
    map(([value, facilities]) => {
      const searchTerm = value?.toLowerCase() ?? '';
      return facilities.flatMap((f) =>
        f.systems
          .filter((s) =>
            [
              s.serialNumber.toLowerCase(),
              s.deviceId.toLowerCase(),
              f.facilityAddressRoad?.toLowerCase(),
              f.facilityAddressPostal.toLowerCase(),
              f.facilityAddressCity.toLowerCase(),
            ].some((param) => param.includes(searchTerm)),
          )
          .map((system) => ({
            ...system,
            facilityId: f.facilityId,
            facilityAddress: `${f.facilityAddressRoad}, ${f.facilityAddressPostal} ${f.facilityAddressCity}`,
          })),
      );
    }),
    tap((facilities) => {
      if (facilities?.length === 1) {
        this.virtualScrollport?.scrollTo({ top: 0 });
      }
    }),
    catchError((error) => []),
  );

  private subscriptions = new Subscription();
  private navigationEndEvents = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd && e.url.includes('overview;tab=installations')),
  );

  constructor(
    private facilityService: FacilityService,
    private readonly router: Router,
  ) {}

  public ngOnInit() {
    this.subscriptions.add(this.navigationEndEvents.subscribe(() => window.dispatchEvent(new Event('resize'))));

    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(distinctUntilChanged(), debounceTime(10))
        .subscribe(() => {
          this.virtualScrollport?.checkViewportSize();
        }),
    );
  }

  public get inverseOfTranslation(): string {
    return inverseOfTranslation(this.virtualScrollport);
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
