<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
      <ng-container gbc-page-header-actions *ngIf="!(isArchived$ | async)">
        <button gbc-text-button (click)="editCompany()">
          <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
          {{ 'app-edit' | translate }}
        </button>
        <button gbc-text-button (click)="confirmArchiveCompany()">
          <img style="margin-right: 10px" src="./assets/svgs/archive.svg" />
          {{ 'app-archive' | translate }}
        </button>
      </ng-container>

      <ng-container gbc-page-header-right *ngIf="company$ | async as company">
        <button *ngIf="showCreateFacilityButton$ | async" gbc-button [routerLink]="['/facility/create', company.id]">
          {{ 'company-page.create-facility' | translate }}
        </button>
        <button *ngIf="showCreateUserButton$ | async" gbc-button [routerLink]="['/users/create']" [queryParams]="{ companyId: company.id }">
          {{ 'company-page.create-user' | translate }}
        </button>
        <button *ngIf="showUploadNewGisDataSourceButton$ | async" gbc-button (click)="openAddGisSourceDataDialog()">
          {{ 'app-create' | translate }}
        </button>
      </ng-container>
    </gbc-page-header>

    <gbc-tabs>
      <gbc-tab [title]="'company-page.facilities' | translate" name="facilities">
        <gbc-page-container>
          <form [formGroup]="form">
            <app-searchbar formControlName="facilitySearch" [placeholder]="'company-page.search-placeholder' | translate"> </app-searchbar>
          </form>

          <gbc-tile [title]="'company-page.facilities' | translate">
            <app-facility-list *ngIf="facilities$ | async as facilities; else loadingSpinner" [facilities]="facilities"></app-facility-list>
          </gbc-tile>
        </gbc-page-container>
      </gbc-tab>
      <gbc-tab [title]="'company-page.users' | translate" name="users">
        <gbc-page-container>
          <app-users-list [users]="usersForCompany$"></app-users-list>
        </gbc-page-container>
      </gbc-tab>
      <gbc-tab [title]="'company-page.gis-sources' | translate" name="gisSources">
        <gbc-page-container>
          <app-gis-sources-list [company]="company$ | async" [gisSources]="gisSourcesForCompany$ | async"></app-gis-sources-list>
        </gbc-page-container>
      </gbc-tab>
    </gbc-tabs>
  </gbc-command-spinner>
</ng-template>

<ng-template #loadingSpinner>
  <gbc-loading-spinner class="spinner"></gbc-loading-spinner>
</ng-template>
