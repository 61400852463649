import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { CompanyService } from '../../../services/company.service';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { ModalService } from 'shared';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}

@Component({
  selector: 'app-developer-company-page',
  templateUrl: './developer-company-page.component.html',
  styleUrls: ['./developer-company-page.component.scss'],
})
export class DeveloperCompanyPageComponent implements OnInit {
  uiState = UIState.edit;

  currentCompanyId;
  companies$;

  form;
  constructor(
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
  ) {
    this.companies$ = companyService.companies$;

    this.form = formBuilder.group({
      Name: ['', Validators.required],
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (this.uiState === UIState.create) {
      this.companyService.createCompany(this.form.value);
    } else {
      const company: ICompany = this.form.value;
      company.id = this.currentCompanyId;
      this.companyService.patchCompany(this.form.value);
    }
    console.warn(this.form.value);
    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.edit;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewCompany() {
    this.uiState = UIState.create;
  }

  modifyCompany(company: ICompany) {
    this.uiState = UIState.modify;
    this.currentCompanyId = company.id;
    this.form.patchValue({
      Name: company.name,
    });
  }

  deleteCompany(company: ICompany) {
    this.modalService.confirm('Delete Company', 'Confirm deletion of: ' + company.name, () => {
      this.companyService.deleteCompany(company);
    });
  }

  cancelNewCompany() {
    this.uiState = UIState.edit;
  }
}
