import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TimeAgoPipe } from './time-ago.pipe';

@Pipe({
  name: 'timeWithAgo',
  pure: false,
})
export class TimeWithAgoPipe implements PipeTransform {
  constructor(
    private timeAgoPipe: TimeAgoPipe,
    private datePipe: DatePipe,
  ) {}

  transform(value: number): string {
    if (!value) {
      return '';
    } else {
      return `${this.datePipe.transform(value, 'medium')} (${this.timeAgoPipe.transform(value)})`;
    }
  }
}
