import { Component, OnInit, Input } from '@angular/core';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { Observable, combineLatest } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {
  @Input() private users: Observable<IUser[]>;

  public usersList$: Observable<IUser[]>;

  form = new UntypedFormGroup({
    search: new UntypedFormControl(''),
  });

  constructor() {}

  ngOnInit(): void {
    this.usersList$ = combineLatest([this.users, this.form.controls.search.valueChanges.pipe(startWith(''))]).pipe(
      map(([users, search]) => {
        if (search) {
          const lowerCaseSearch = search.toLowerCase();
          return users.filter((user) => {
            const nameMatch = user.name.toLowerCase().includes(lowerCaseSearch);
            const emailMatch = user.email.toLowerCase().includes(lowerCaseSearch);
            const companyMatch = user.company?.name.toLowerCase().includes(lowerCaseSearch);
            return nameMatch || emailMatch || companyMatch;
          });
        }
        return users;
      }),
    );
  }
}
