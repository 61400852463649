import { Inject, Injectable } from '@angular/core';
import { IUser } from '../interfaces/user';
import { NEVER, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService, serverUrlToken, createRefreshSubject } from 'shared';
import { flatMap, map } from 'rxjs/operators';
import { AppErrorService } from 'shared';

export enum EAdminClaimNames {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'ServicePortalServiceGroup' = 'ServicePortalServiceGroup',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  'ServicePortalSupportBase' = 'ServicePortalSupportBase',
}

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  // currentUser$ do not emit errors, also never completes
  currentUser$: Observable<IUser>;

  // initialCurrentUser$ may emit an error if initial user loading fails, this one completes
  initialCurrentUser$: Observable<IUser>;

  public refreshNow: () => Observable<IUser>;

  private httpGetCurrentUser() {
    return this.httpClient.get<IUser>(`${this.serverUrl}/api/users/current`);
  }

  public hasClaim(claim: string): Observable<boolean> {
    return this.currentUser$.pipe(map((user) => user.accessClaimsGroupNames.includes(claim)));
  }

  constructor(
    private httpClient: HttpClient,
    @Inject(serverUrlToken) private serverUrl: string,
    private authService: AuthService,
    private appErrorService: AppErrorService,
  ) {
    const { subject, scheduleRefresh, initialValue } = createRefreshSubject<IUser>(
      () => this.httpGetCurrentUser(),
      this.authService.authenticated.pipe(
        flatMap((authenticated) => {
          if (authenticated) {
            return of();
          } else {
            return NEVER;
          }
        }),
      ),
    );
    this.refreshNow = scheduleRefresh;
    this.currentUser$ = subject;
    this.initialCurrentUser$ = initialValue.pipe(
      appErrorService.catchApiError({
        errorCodes: {
          'user-disabled': {
            titleKey: 'app-user-disabled-header',
            messageKey: 'app-user-disabled-body',
          },
          'user-not-found': {
            titleKey: 'app-user-not-found-header',
            messageKey: 'app-user-not-found-body',
          },
        },
      }),
    );
  }
}
