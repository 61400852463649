<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
      <ng-container gbc-page-header-actions *ngIf="!(isArchived$ | async)">
        <button gbc-text-button (click)="editFacility()">
          <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
          {{ 'app-edit' | translate }}
        </button>

        <button gbc-text-button (click)="confirmArchiveFacility()">
          <img style="margin-right: 10px" src="./assets/svgs/archive.svg" />
          {{ 'app-archive' | translate }}
        </button>
      </ng-container>

      <ng-container gbc-page-header-right *ngIf="facility$ | async as facility">
        <button
          *ngIf="showCreateInstallationButton$ | async"
          gbc-button
          [routerLink]="[createInstallationUrlMap[facility.facilityType]]"
          [queryParams]="{
            companyId: facility.company.id,
            facilityId: facility.id
          }"
        >
          {{ 'facility-page.create-installation' | translate }}
        </button>
        <button
          *ngIf="showCreateUserButton$ | async"
          gbc-button
          [routerLink]="['/users/create']"
          [queryParams]="{
            companyId: facility.company.id,
            facilityId: facility.id
          }"
        >
          {{ 'facility-page.create-user' | translate }}
        </button>
      </ng-container>
    </gbc-page-header>
    <gbc-tabs>
      <gbc-tab [title]="'facility-page.installations' | translate" name="installations">
        <gbc-page-container>
          <gbc-tile
            *ngIf="facility$ | async as facility"
            [title]="'facility-page.facility-information' | translate"
            style="padding-bottom: 0"
          >
            <gbc-input-container>
              <gbc-input-label>{{ 'facility-page.name' | translate }}*</gbc-input-label>
              <gbc-input-field [disabled]="true" [(ngModel)]="facility.name" name="name"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'facility-page.address' | translate }}*</gbc-input-label>
              <gbc-input-field [disabled]="true" [(ngModel)]="facility.addressRoad" name="address"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'facility-page.postal-code' | translate }}*</gbc-input-label>
              <gbc-input-field [disabled]="true" [(ngModel)]="facility.addressPostal" name="postalCode"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'facility-page.city' | translate }}*</gbc-input-label>
              <gbc-input-field [disabled]="true" [(ngModel)]="facility.addressCity" name="city"></gbc-input-field>
            </gbc-input-container>

            <gbc-form-hint></gbc-form-hint>
          </gbc-tile>

          <form [formGroup]="form">
            <app-searchbar formControlName="search" [placeholder]="'facility-page.search-for-installation' | translate"> </app-searchbar>
          </form>

          <gbc-tile *ngIf="facility$ | async as facility" [title]="'facility-page.installations' | translate">
            <gbc-table *ngIf="installations$ | async as installations" clickableRows="true">
              <th gbc-table-header>
                {{ 'facility-page.serial-number' | translate }}
              </th>
              <th gbc-table-header>
                {{ 'facility-page.device-id' | translate }}
              </th>
              <th gbc-table-header>{{ 'facility-page.status' | translate }}</th>

              <tr gbc-table-row *ngFor="let installation of installations" (click)="navigateToInstallation(facility, installation)">
                <td gbc-table-cell>{{ installation.serialNumber }}</td>
                <td gbc-table-cell>{{ installation.deviceId }}</td>
                <td gbc-table-cell>
                  {{ 'commission-statuses.' + installation.commissionStatus | translate }}
                </td>
              </tr>
            </gbc-table>
          </gbc-tile>
        </gbc-page-container>
      </gbc-tab>

      <gbc-tab [title]="'facility-page.users' | translate" name="users">
        <gbc-page-container>
          <app-users-list [users]="users$"></app-users-list>
        </gbc-page-container>
      </gbc-tab>
    </gbc-tabs>
  </gbc-command-spinner>
</ng-template>
