import { EArchiveState } from './archiveState';
import { ECommissionStatus } from 'shared';
import { IFullInstallationDTO } from './installation';
import { Observable } from 'rxjs';

export interface IIGridSystem extends Omit<IFullInstallationDTO, 'id' | 'serialNumber' | 'version'> {
  id: number;
  // name: string;
  commissionStatus: ECommissionStatus;
  deviceId: string;
  version?: {
    changes: {
      deployed: boolean;
      timestampEpoch: number;
      deployedTimestampEpoch: number;
      version: string;
      userId: string;
      reason: string;
    }[];
    id: string;
    currentVersion: string;
    deployedVersion: string;
  };
  // descriptionUser?: string;
  archiveState: EArchiveState;
  buildingConnectId: string;
  deviceGroup: string;
  facilityName: string;
  serialNumber: string;
  systemTypeId: string | number;
  systemTypeName: string;
  systemTypeDescription: string;
  systemTypeVersion?: string;
  systemTypeVariant: string;
  parentFacility?: string;
  parentFacilityId?: number;
  description: string;
  lastLog: string;
  installationTypeVersion: string;
  dataPoint: IDataPoint[];
  settings: ISetting[];
  module: [
    {
      id: number;
      name: string;
      order: number;
    },
  ];
  [key: string]: unknown;
}

export interface ISetting {
  customName?: string;
  id: number;
  parentId: number;
  childrenCount: number;
  settingType: ISettingType;
  value: any;
  order: number;
  hasParent: boolean;
  changedBy: string;
  changed: number;
  info: string;
  name: string;
  needsSupportUserLevel: boolean;
  calculateRawValue: (value: number) => number;
  selectOptions?: Observable<Array<unknown>> | null;
}

export interface ISettingType {
  translationKey: string;
  valueType: string;
  id: number;
  unit: any;
  name: string;
  settingKey: string;
  validationRegex: string;
  parametersJson: string;
  minValue: number;
  maxValue: number;
  polyA: number;
  polyB: number;
}

export enum ESettingValueType {
  'number' = 'number',
  'string' = 'string',
  'multiselect' = 'multiselect',
  'singleselect' = 'singleselect',
}

export enum ESettingControlType {
  'number' = 'number',
  'text' = 'text',
  'time' = 'time',
  'slider' = 'number',
  'multiselect' = 'multiselect',
  'singleselect' = 'singleselect',
}

export interface IDataPoint {
  id: number;
  customName?: string;
  dataKey?: string;
  dataPointLog?: [
    {
      value: number;
      timestamp: number;
    },
  ];
  dataPointTypeColor?: string;
  dataPointTypeFormat?: string;
  dataPointTypeUnit?: string;
  lastValue: number;
  order: number;
  translationKey: string;
  dataPointLogCount?: number;
  [key: string]: unknown;
}

export interface IDataPointDTO {
  dataKey: string;
  customName?: string;
  dataPointTypeId: number;
  id: number;
  lastValue: number;
  order: number;
  standardKeyId: number;
  visible: boolean;
  // mapped props:
  standardKey?: IStandardKey;
  dataPointType?: IDataPointType;
}

export interface IDataPointType {
  id: number;
  format: string;
  unit: string;
}

export interface IDatapointTypeListModel extends IDataPointType {
  used: boolean;
}

export interface IStandardKey {
  id: number;
  key: string;
  translationKey: string;
}
