<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <form [formGroup]="form">
      <gbc-stepper
        linear
        [backDisabled]="backDisabled"
        (selectionChange)="stepperChange($event)"
        [backLabel]="'app-back' | translate"
        [nextLabel]="'app-next' | translate"
        [doneLabel]="'app-done' | translate"
        (done)="completeWizard()"
      >
        <cdk-step
          label="{{ (creating ? 'create-edit-company.company-create' : 'create-edit-company.company-edit') | translate | uppercase }}"
        >
          <gbc-tile
            title=" {{ (creating ? 'create-edit-company.company-create' : 'create-edit-company.company-edit') | translate | uppercase }}"
            class="error-tile"
          >
            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.company-name' | translate }}*</gbc-input-label>
              <gbc-input-field formControlName="clientName"></gbc-input-field>
            </gbc-input-container>

            <ng-container *ngIf="serviceGroups$ | async as serviceGroups">
              <gbc-input-container *ngIf="serviceGroups.length">
                <gbc-input-label>{{ 'create-edit-company.service-group' | translate }}*</gbc-input-label>
                <gbc-dropdown
                  formControlName="serviceGroup"
                  placeholderTranslationString="app-select"
                  [dropDownItems]="serviceGroups"
                ></gbc-dropdown>
              </gbc-input-container>
            </ng-container>
            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.contact-name' | translate }}</gbc-input-label>
              <gbc-input-field formControlName="contactName"></gbc-input-field>
            </gbc-input-container>
            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.contact-phone' | translate }}</gbc-input-label>
              <gbc-input-field formControlName="contactPhone"></gbc-input-field>
            </gbc-input-container>
            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.contact-email' | translate }}*</gbc-input-label>
              <gbc-input-field
                inputType="email"
                formControlName="contactEmail"
                [valid]="!form.get('contactEmail').value || form.get('contactEmail').valid"
                errorDescriptionTranslationString="app-project-customer-email-invalid"
              >
              </gbc-input-field>
            </gbc-input-container>
            <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
          </gbc-tile>
        </cdk-step>

        <cdk-step [label]="'create-edit-company.admin-user' | translate" *ngIf="creating">
          <gbc-tile title=" {{ 'create-edit-company.admin-user' | translate }}" class="error-tile">
            <p class="text">
              {{ 'create-edit-company.admin-user-description' | translate }}
            </p>

            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.admin-name' | translate }}</gbc-input-label>
              <gbc-input-field formControlName="adminName"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.admin-phone' | translate }}</gbc-input-label>
              <gbc-input-field formControlName="adminPhone"></gbc-input-field>
            </gbc-input-container>

            <gbc-input-container>
              <gbc-input-label>{{ 'create-edit-company.admin-email' | translate }}</gbc-input-label>
              <gbc-input-field
                inputType="email"
                formControlName="adminEmail"
                [valid]="!form.get('adminEmail').value || form.get('adminEmail').valid"
                errorDescriptionTranslationString="app-project-customer-email-invalid"
              >
              </gbc-input-field>
            </gbc-input-container>

            <!-- Used for correct spacing -->
            <gbc-form-hint></gbc-form-hint>
          </gbc-tile>
        </cdk-step>
      </gbc-stepper>
    </form>
  </gbc-command-spinner>
</ng-template>
