import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class PathInterceptor implements HttpInterceptor {
  constructor() {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.indexOf('/assets/') !== -1) {
      const position = request.url.indexOf('/assets/');
      const output = [request.url.slice(0, position), environment.deploymentPath, request.url.slice(position)].join('');
      request = request.clone({
        url: output,
      });
    } else if (request.url.startsWith('/api/')) {
      const position = request.url.indexOf('/api/');
      const output = [request.url.slice(0, position), environment.deploymentPath, request.url.slice(position)].join('');
      request = request.clone({
        url: output,
      });
    }
    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {}),
      catchError((response) => {
        return throwError(response);
      }),
    );
  }
}
