<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5><img class="delete-icon" src="./assets/svgs/incoming.svg" />{{ 'summary-page.incoming-routes' | translate }}</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="icons">
    <a href="" (click)="openAddEditRouteDialog(installation); false">{{ 'igrid-system-routings.add-incoming-route' | translate }} ➕</a>
  </ng-template>

  <ng-container *ngIf="allSystems$ | async as systems; else loading">
    <ng-container
      *ngTemplateOutlet="
        routingsTable;
        context: {
          routings: routings?.incoming,
          systems: systems,
          isIncoming: true
        }
      "
    ></ng-container>
  </ng-container>
</p-panel>


<p-panel>
  <ng-template pTemplate="header">
    <div class="flex align-items-center gap-2">
      <h5><img class="delete-icon" src="./assets/svgs/outgoing.svg" />{{ 'summary-page.outgoing-routes' | translate }}</h5>
    </div>
  </ng-template>
  <ng-container *ngIf="allSystems$ | async as systems; else loading">
    <ng-container
      *ngTemplateOutlet="
        routingsTable;
        context: {
          routings: routings?.outgoing,
          systems: systems,
          isIncoming: false
        }
      "
    ></ng-container>
  </ng-container>
</p-panel>

<ng-template #routingsTable let-routings="routings" let-systems="systems" let-isIncoming="isIncoming">
  <gbc-table *ngIf="routings?.length; else noRoutesForSystem">
    <tr gbc-table-row>
      <th gbc-table-header>{{ 'app-installation' | translate }}</th>
      <th>{{ 'igrid-system-routings.routing-name' | translate }}</th>
      <th>{{ 'igrid-system-routings.routing-enabled' | translate }}</th>
      <th>{{ 'igrid-system-routings.description' | translate }}</th>
      <th [attr.colspan]="isIncoming ? 1 : 2">{{ 'igrid-system-routings.strategy' | translate }}</th>
      <th class="actions" *ngIf="isIncoming">
        {{ 'igrid-system-routings.actions' | translate }}
      </th>
    </tr>
    <tr class="route-row" gbc-table-row *ngFor="let route of routings">
      <ng-container *ngIf="isIncoming">
        <ng-container *ngIf="navigateRoutings && route.sourceDeviceId | generateLinkFromDeviceId: systems as system; else textFormat">
          <td gbc-table-cell>
            <ng-container *ngTemplateOutlet="linkFormat; context: { route: route.sourceDeviceId, system: system }"></ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="!isIncoming">
        <ng-container *ngIf="navigateRoutings && route.targetDeviceId | generateLinkFromDeviceId: systems as system; else textFormat">
          <td class="no-left-padding" gbc-table-cell>
            <ng-container *ngTemplateOutlet="linkFormat; context: { route: route.targetDeviceId, system: system }"></ng-container>
          </td>
        </ng-container>
      </ng-container>

      <ng-template #textFormat>
        <td class="route-text">{{ route.sourceDeviceId }}</td>
      </ng-template>
      <td>{{ route.routingName }}</td>
      <td><img class="route-enabled" [src]="route.enabled ? './assets/svgs/green-checked-circle.svg' : './assets/svgs/remove-circle_filled.svg'" /></td>
      <td>{{ route.description }}</td>
      <td [attr.colspan]="isIncoming ? 1 : 2">{{ route.strategy }}</td>
      <td *ngIf="isIncoming" class="actions-table-cell">
        <button (click)="openAddEditRouteDialog(installation, route)" gbc-icon-button>✏️ {{ 'app-edit' | translate }}</button>
        <button (click)="openDeleteRouteDialog(route)" gbc-icon-button>🗑️ {{ 'app-delete' | translate }}</button>
      </td>
    </tr>
  </gbc-table>

  <ng-template #noRoutesForSystem>
    <gbc-table>
      <tr class="no-routes-row no-bg" gbc-table-row>
        <td>
          {{ 'igrid-system-routings.no-routings-for-system' | translate }}
        </td>
      </tr>
    </gbc-table>
  </ng-template>
</ng-template>

<ng-template #loading>
  <div class="loading-routes">
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<ng-template #linkFormat let-route="route" let-system="system">
  <a class="route-link" href="" (click)="navigateToInstallation(system); (false)">{{ route }}</a>
</ng-template>
