<app-developer-menu *ngIf="showDeveloperUI$ | async"></app-developer-menu>

<gbc-topbar
  [title]="'core.igrid-description-title' | translate"
  (headerClick)="navigateToRoot()"
  [ngClass]="{ developerVisible: (showDeveloperUI$ | async) }"
>
  <gbc-topbar-dropdown *ngIf="currentUser$ | async as user" [placeholder]="user.name">
    <button gbc-topbar-dropdown-item [routerLink]="['/support']" *ngIf="showSupportButton$ | async">
      {{ 'root-page.support' | translate }}
    </button>
    <button gbc-topbar-dropdown-item [routerLink]="['/commission']" *ngIf="showCommissionButton$ | async">
      {{ 'root-page.commission' | translate }}
    </button>
    <button gbc-topbar-dropdown-item [routerLink]="['/archive']" *ngIf="showArchiveButton$ | async">
      {{ 'root-page.archive' | translate }}
    </button>
    <button gbc-topbar-dropdown-item (click)="logout()">
      {{ 'app-menu-logout' | translate }}
    </button>
  </gbc-topbar-dropdown>
</gbc-topbar>
<div class="outlet-container">
  <gbc-page-spinner>
    <gbc-page-error-handler [pageError$]="pageError$" [content]="content"> </gbc-page-error-handler>
    <ng-template #content>
      <router-outlet></router-outlet>
    </ng-template>
  </gbc-page-spinner>
</div>
