import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { CompanyService } from '../../services/company.service';

import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { PageInfo, AppError, AppErrorService } from 'shared';
import { ServicegroupService } from 'projects/serviceportal/src/app/services/servicegroup.service';
import { IServiceGroup } from 'projects/serviceportal/src/app/interfaces/serviceGroup';
import { combineLatest, Observable, of } from 'rxjs';
import { first, flatMap, shareReplay, tap } from 'rxjs/operators';
import { CurrentUserService } from 'projects/serviceportal/src/app/services/current-user.service';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  selector: 'app-create-edit-company',
  templateUrl: './create-edit-company.component.html',
  styleUrls: ['./create-edit-company.component.scss'],
})
export class CreateEditCompanyComponent implements OnInit {
  public creating = true;
  public companyCreated = false;
  public companyID: number;
  public userId: string;

  public company$: Observable<ICompany>;
  public serviceGroups$: Observable<IServiceGroup[]>;

  public backDisabled = true;

  public pageInfo: PageInfo;

  public pageError$: Observable<AppError>;

  private serviceGroupControl = new UntypedFormControl('', null);
  public form = new UntypedFormGroup({
    clientName: new UntypedFormControl('', Validators.required),
    ownerId: new UntypedFormControl(''),
    contactName: new UntypedFormControl('', Validators.required),
    contactPhone: new UntypedFormControl('', Validators.required),
    contactEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    adminName: new UntypedFormControl('', Validators.required),
    adminPhone: new UntypedFormControl('', Validators.required),
    adminEmail: new UntypedFormControl('', [Validators.required, Validators.email]),
    serviceGroup: this.serviceGroupControl,
  });

  private setAdminUserRequired = (isRequired) => {
    if (isRequired) {
      this.form.get('adminName').setValidators([Validators.required]);
      this.form.get('adminPhone').setValidators([Validators.required]);
      this.form.get('adminEmail').setValidators([Validators.required, Validators.email]);
    } else {
      [this.form.get('adminName'), this.form.get('adminPhone'), this.form.get('adminEmail')].forEach((formControl) =>
        formControl.setValidators([]),
      );
    }
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private currentUserService: CurrentUserService,
    private companyService: CompanyService,
    private pageInfoService: PageInfoService,
    private serviceGroupservice: ServicegroupService,
    private errorService: AppErrorService,
  ) {
    const params = this.activatedRoute.snapshot.params;

    this.serviceGroups$ = this.currentUserService.initialCurrentUser$.pipe(
      flatMap((currentUser) => {
        if (currentUser.accessClaimsGroupNames.includes('ServiceGroups')) {
          return this.serviceGroupservice.getServiceGroups();
        } else {
          return of(currentUser.serviceGroups);
        }
      }),
      // Set the service group when creating a new company and there is only one service group
      tap((serviceGroups) => {
        if (serviceGroups.length === 1 && params.id) {
          this.serviceGroupControl.setValue(serviceGroups[0]);
        }
      }),
      shareReplay(),
    );

    this.company$ = this.companyService.getCompany(parseInt(params.id, 10)).pipe(shareReplay());

    if (params.id) {
      this.creating = false;
      this.companyID = params.id as number;
      this.pageInfo = this.pageInfoService.createEditCompanyPage('edit', params.id);

      this.setCompanyFromId(params.id);
      this.pageError$ = this.errorService.createPageErrorObservable([this.company$, this.serviceGroups$]);
    } else {
      this.pageInfo = this.pageInfoService.createEditCompanyPage('create');
      this.pageError$ = this.errorService.createPageErrorObservable([this.serviceGroups$]);
    }
    this.setAdminUserRequired(!params.id);
  }

  ngOnInit() {}

  stepperChange(event: StepperSelectionEvent) {
    switch (event.selectedIndex) {
      case 0:
        // We are changing back to the company page, from the user page
        // No need to do anything
        break;
      case 1:
        this.backDisabled = false;
        // Need to think about handling the updating of a user, we don't allow that, so we should
        // Changing to the user page
        if (this.companyCreated) {
          // This is called in the case, that the user first creates the company, then goes bak again to change something
          this.updateCompany();
        } else {
          this.createCompany().subscribe((company) => {
            this.companyCreated = true;
            this.companyID = company.id;
          });
        }
        break;
    }
  }

  completeWizard() {
    if (this.creating) {
      // If we are creating, then this is coming from step number 2, so we want to create the user now
      this.createCompanyUser().subscribe(() => {
        // And finish the flow
        this.router.navigate(['company', 'view', this.companyID]);
      });
    } else {
      // If we are not creating, then this is the final step, where we want to update the company
      this.updateCompany().subscribe((company) => {
        this.router.navigate(['company', 'view', company.id]);
      });
    }
  }

  setCompanyFromId(id: string): void {
    combineLatest([this.serviceGroups$, this.company$])
      .pipe(first())
      .subscribe(([serviceGroups, company]) => {
        // Service groups can either be list of all service groups with companyIds or just the one the user is part of, without any companyIds.
        // If it's the list with company ids, then we just find the one for the company.
        // If they only have one service group, then we can assume the company belongs to that one.
        // But if it's not, and they have access to multiple service groups, we have no way of knowing which service group the company belongs to.
        let serviceGroup: IServiceGroup;
        if (serviceGroups.length === 1) {
          serviceGroup = serviceGroups[0];
        } else if (serviceGroups.length > 1 && serviceGroups[0].hasOwnProperty('companyIds')) {
          serviceGroup = serviceGroups.find((servicegroup) => servicegroup.companyIds?.find((c) => c === Number(id)));
        } else {
          // No idea what to do here
          throw new Error(
            'User has multiple service groups, but no service group access claim group. No way to decide which service group to select.',
          );
        }
        this.form.patchValue({
          clientName: company.name,
          contactName: company.contactName,
          contactEmail: company.contactEmail,
          contactPhone: company.contactPhone,
          serviceGroup,
        });
      });
  }

  createCompany() {
    return this.companyService.createNewCompanyWithContactDetails({
      name: this.form.get('clientName').value,
      contactName: this.form.get('contactName').value,
      contactEmail: this.form.get('contactEmail').value,
      contactPhone: this.form.get('contactPhone').value,
      serviceGroupId: this.form.get('serviceGroup').value?.id ?? null,
    });
  }

  updateCompany() {
    return this.companyService.patchCompany({
      id: this.companyID,
      name: this.form.get('clientName').value,
      serviceGroupId: this.form.get('serviceGroup').value?.id,
      contactName: this.form.get('contactName').value,
      contactEmail: this.form.get('contactEmail').value,
      contactPhone: this.form.get('contactPhone').value,
    });
  }

  createCompanyUser() {
    return this.userService.createCompanyAdminUser({
      name: this.form.get('adminName').value,
      companyId: this.companyID,
      phone: this.form.get('adminPhone').value,
      email: this.form.get('adminEmail').value,
    });
  }
}
