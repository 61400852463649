import { Component, OnInit } from '@angular/core';
import { AppError, AppErrorService, ModalService, PageInfo } from 'shared';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { combineLatest, Observable } from 'rxjs';
import { UserService } from 'projects/serviceportal/src/app/services/user.service';
import { flatMap, map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { EAccessLevel, IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { CompanyService } from 'projects/serviceportal/src/app/services/company.service';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';

interface IUserWithFormattedAccessLevel extends IUser {
  formattedAccessLevel: string;
  formattedLastLifesignTimestampEpoch: string;
}

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
})
export class UserPageComponent implements OnInit {
  public company$: Observable<ICompany>;
  public user$: Observable<IUserWithFormattedAccessLevel>;
  public pageInfo$: Observable<PageInfo>;
  public facilities$: Observable<IFacility[]>;
  public pageError$: Observable<AppError>;

  public userWithCompany$: Observable<IUser>;
  public isFacilityManager$: Observable<boolean>;
  public isCompanyOwner$: Observable<boolean>;

  // If we want to navigate using routerLink (which we want), this is the cleanest solution to creating the correct url
  public viewInstallationUrlMap = {
    BuildingConnect: '/buildingconnect/installation',
    iGrid: '/igrid/installation',
  };

  constructor(
    private pageInfoService: PageInfoService,
    private userService: UserService,
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private facilityService: FacilityService,
    private translate: TranslateService,
    private router: Router,
    private errorService: AppErrorService,
    private modalService: ModalService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    const userId = this.route.snapshot.params.userId;

    this.user$ = this.userService.getUser(userId).pipe(
      map((user) => {
        return {
          ...user,
          formattedAccessLevel: this.translate.instant('roles.' + user.accessLevel),
          formattedLastLifesignTimestampEpoch:
            user.lastLifesignTimestampEpoch === 0
              ? this.translate.instant('user-page.not-logged-in')
              : this.datePipe.transform(user.lastLifesignTimestampEpoch, 'medium'),
        };
      }),
    );

    this.company$ = this.user$.pipe(
      flatMap((user) => {
        return this.companyService.getCompany(user.companyId);
      }),
    );

    this.userWithCompany$ = combineLatest([this.user$, this.company$]).pipe(
      map(([user, company]) => {
        user.company = company;
        return user;
      }),
    );

    this.pageError$ = this.errorService.createPageErrorObservable([this.userWithCompany$]);

    this.pageInfo$ = this.user$.pipe(
      map((user) => {
        return this.pageInfoService.userPageFromOverViewPage(user);
      }),
    );
    this.isCompanyOwner$ = this.user$.pipe(map((user) => user.accessLevel === EAccessLevel.GrundfosClaimAccessCompany));
    this.isFacilityManager$ = this.user$.pipe(map((user) => user.accessLevel === EAccessLevel.GrundfosClaimAccessFacility));
    this.facilities$ = combineLatest([this.user$, this.facilityService.facilities$]).pipe(
      map(([user, facilities]) => {
        switch (user.accessLevel) {
          case EAccessLevel.GrundfosClaimAccessAny:
          case EAccessLevel.GrundfosClaimAccessServiceGroup:
            return []; // Dont show list of all facilities for admin users
          case EAccessLevel.GrundfosClaimAccessCompany:
            return facilities.filter((facility) => facility.company?.id === user.companyId);
          case EAccessLevel.GrundfosClaimAccessFacility:
            return facilities.filter((facility) => user.facilityIDs.includes(facility.id));
        }
      }),
    );
  }

  public editUser(userId: string) {
    this.router.navigate(['/users/create', userId]);
  }

  public confirmArchiveUser() {
    this.modalService.showTextModal({
      iconUrl: './assets/svgs/archive.svg',
      title: this.translate.instant('app-archive'),
      headline: this.translate.instant('user-page.confirm-archive'),
      content: this.translate.instant('user-page.archive-description'),
      actions: [
        {
          text: this.translate.instant('app-cancel'),
          cancel: true,
        },
        {
          text: this.translate.instant('app-archive'),
          handler: this.archiveUser.bind(this),
          type: 'danger',
        },
      ],
    });
  }

  private archiveUser() {
    this.userService.archiveUser(this.route.snapshot.params.userId).subscribe(() => {
      this.router.navigate(['/overview', { tab: 'users' }]);
    });
  }
}
