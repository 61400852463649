<div class="page">
  <button (click)="createNewUser()">Create new user</button>
  <!-- <button (click)="edit()">{{ uiState === 'edit' ? 'Cancel' : 'Edit' }}</button> -->
  Filter:
  <gbc-input-field
    class="overview-header-row-right-container-filter"
    [placeholderTranslationString]="'create-edit-user.name'"
    [(ngModel)]="currentfilter"
  >
  </gbc-input-field>
  <!-- ListUsers -->
  <div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col" sortable="Active">Active</th>
          <th scope="col" sortable="AccessLevel">Access Level</th>
          <th scope="col" sortable="AccessClaimsGroups">Access Claims Groups</th>
          <th scope="col" sortable="Name">Name</th>
          <th scope="col" sortable="CompanyName">Company</th>
          <th scope="col" sortable="Phone">Phone</th>
          <th scope="col" sortable="Email">Email</th>
          <th scope="col" sortable="FacilityCount">Facility Count</th>
          <th *ngIf="uiState === 'edit'" scope="col">Modify</th>
          <th *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody *ngIf="users$ | async | filterCompanyOnName: currentfilter as users">
        <!-- | async -->
        <tr *ngFor="let user of users">
          <th scope="row">{{ user.id }}</th>
          <td>{{ user.active }}</td>
          <td>{{ user.accessLevel }}</td>
          <td>{{ user.accessClaimsGroupNames.length }}</td>
          <td>{{ user.name }}</td>
          <td>{{ user.company?.name }}</td>
          <td>{{ user.phone }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.facilityIDs?.length }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyUser(user)">Modify</button>
          </td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteUser(user)">X</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!-- CreateUser -->
  <div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
    <form class="newForm" [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="activeRow ml-1" *ngIf="selectedUserId">
        ID:
        <label class="formElement">{{ selectedUserId }}</label>
      </div>
      <div class="activeRow ml-1">
        Active:
        <input class="ml-2" placeholder="Active" type="checkbox" formControlName="Active" />
      </div>
      <input class="formElement" placeholder="Name" type="text" formControlName="Name" />
      <ng-select
        class="select"
        [items]="companies$ | async"
        bindLabel="name"
        bindValue="id"
        [multiple]="false"
        placeholder="Select Company"
        formControlName="CompanyId"
      >
      </ng-select>
      <input class="formElement" placeholder="Phone Number" type="text" formControlName="Phone" />
      <input class="formElement" placeholder="Email" type="text" formControlName="Email" />
      <ng-select class="select" [searchable]="false" formControlName="AccessLevel">
        <ng-option *ngFor="let accessLevel of userAccessLevels$ | async | sortByValue" [value]="accessLevel"> {{ accessLevel }}</ng-option>
      </ng-select>

      <ng-select
        class="select"
        [items]="accessClaimsGroups$ | async | sortByName"
        bindLabel="name"
        bindValue="name"
        [searchable]="false"
        formControlName="AccessClaimsGroupNames"
        [multiple]="true"
      >
      </ng-select>

      <ng-select
        class="select"
        [items]="facilities$ | async"
        bindLabel="name"
        bindValue="id"
        [multiple]="true"
        placeholder="Select facilities"
        [(ngModel)]="selectedFacilityIds"
        [ngModelOptions]="{ standalone: true }"
      >
      </ng-select>
      <!-- ServiceGroups -->
      <ng-select
        class="select"
        [items]="serviceGroups$ | async | sortByName"
        bindLabel="name"
        bindValue="id"
        [searchable]="true"
        formControlName="ServiceGroups"
        [multiple]="true"
      >
      </ng-select>

      <button type="submit" [disabled]="!form.valid">Submit</button>
      <button type="reset" (click)="cancelNewUser()">Cancel</button>
    </form>
  </div>
</div>
