<gbc-tile [title]="'create-edit-igrid-installation.data-point.title' | translate">
  <ng-container *ngIf="dataPoints && dataPoints.length > 0; else emptyDataPoints">
    <gbc-table>
      <th gbc-table-header>
        {{ 'create-edit-igrid-installation.data-point.key' | translate }}
      </th>
      <th gbc-table-header>
        {{ 'create-edit-igrid-installation.data-point.name' | translate }}
      </th>
      <th gbc-table-header>
        {{ 'create-edit-igrid-installation.data-point.unit' | translate }}
      </th>
      <th gbc-table-header>
        {{ 'create-edit-igrid-installation.data-point.state' | translate }}
      </th>
      <tr *ngFor="let dataPoint of dataPoints" gbc-table-row>
        <td gbc-table-cell class="title">
          {{ dataPoint.dataKey }}
        </td>
        <td gbc-table-cell>
          {{ 'igrid-installation-datapoints.' + dataPoint.standardKey | translate }}
        </td>
        <td gbc-table-cell>
          {{ dataPoint.dataPointType }}
        </td>
        <td gbc-table-cell>
          <ng-container *ngIf="getStateDetails(dataPoint.action) as stateDetails">
            <img
              *ngIf="stateDetails.svgUrl"
              [src]="stateDetails.svgUrl"
              height="29px"
              width="29px"
              alt="SVG for {{ dataPoint.action }} action"
            />
            {{ stateDetails.messageKey | translate }}
          </ng-container>
        </td>
      </tr>
    </gbc-table>
  </ng-container>
  <ng-template #emptyDataPoints>
    <p class="no-available-data-points">
      {{ 'create-edit-igrid-installation.data-point.missing' | translate }}
    </p>
  </ng-template>
</gbc-tile>
