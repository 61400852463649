import { Component, Input, EventEmitter, Output, Inject } from '@angular/core';
import { versionToken } from '../../injection-tokens';
import { LanguageService } from 'projects/shared/src/lib/services/language.service';
import { Observable } from 'rxjs';
import { ModalService } from 'projects/shared/src/lib/services/modal.service';
import { LanguagePickerComponent } from 'projects/shared/src/lib/components/language-picker/language-picker.component';

@Component({
  selector: 'gbc-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  @Input() title: string;
  @Output() headerClick: EventEmitter<void> = new EventEmitter();

  public currentLanguage$: Observable<string>;

  constructor(
    @Inject(versionToken) public version: string,
    private languageService: LanguageService,
    private modalService: ModalService,
  ) {
    this.currentLanguage$ = this.languageService.currentLanguage;
  }

  openLanguagePicker() {
    this.modalService.openDialog(LanguagePickerComponent, {});
  }
}
