import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { orderBy } from 'lodash';
import { combineLatest, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';
import { ModalService } from 'shared';
import { IDataPointDTO, IIGridSystem } from '../../interfaces/iGridSystem';
import { DataPointService } from '../../services/data-point.service';
import { StandardKeyService } from '../../services/standard-key.service';
import { AddEditIgridSystemDatapointDialogComponent } from '../add-edit-igrid-system-datapoint-dialog/app-add-edit-igrid-system-datapoint-dialog.component';

const bypassRefreshToken = '🪄';

@Component({
  selector: 'app-igrid-system-datapoint-list',
  templateUrl: './igrid-system-datapoint-list.component.html',
  styleUrls: ['./igrid-system-datapoint-list.component.scss'],
})
export class IgridSystemDatapointListComponent implements OnInit, OnChanges {
  @Input()
  public system: IIGridSystem;

  public datapoints$ = new ReplaySubject<IDataPointDTO[]>(1);

  private allDataPointTypes$ = this.datapointService.allDatapointTypes$;
  private allStandardKeys$ = this.standardKeyService.allStandardKeys$;
  private subscriptions = new Subscription();
  public showDelete = false;

  constructor(
    private datapointService: DataPointService,
    private standardKeyService: StandardKeyService,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  /** When the input system has changed, we want to refresh the datapoints to match the new system. */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.system) {
      const { previousValue, currentValue } = changes.system;
      if (previousValue && currentValue && previousValue?.id !== currentValue?.id) {
        this.refreshDatapointsForSystem();
      }
    }
  }

  ngOnInit(): void {
    this.refreshDatapointsForSystem();
    this.subscriptions.add(this.translateService.onLangChange.subscribe(() => this.refreshDatapointsForSystem()));
  }

  /**
   * Handles opening of the add/edit datapoint dialog and subsequently handles add/update post/patch commands to backend.
   * @param datapoint Datapoint (passed when editing)
   * @param mode Explicitly pass 'add' or 'edit' to this function (mode could've )
   */
  openAddEditDatapointDialog(datapoint?: IDataPointDTO) {
    this.datapoints$.pipe(first()).subscribe((allDatapointsForSystem) => {
      const mode = !!datapoint ? 'edit' : 'add';
      let addEditObservable: Observable<string | IDataPointDTO[]>;

      switch (mode) {
        case 'add':
          addEditObservable = this.modalService
            .openDialog<IDataPointDTO>(AddEditIgridSystemDatapointDialogComponent, {
              data: {
                system: this.system,
                datapoint,
                allDatapointsForSystem,
                editable: true,
              },
            })
            .pipe(
              switchMap(({ result: addedDatapoint }) => {
                // Can't send the id when POSTing; API will cry and throw some obscure error => rem the id before POST...
                const { id, ...dp } = addedDatapoint ?? {};
                return addedDatapoint
                  ? this.datapointService.addDatapointForSystem(this.system?.parentFacilityId, this.system?.id, dp)
                  : of(bypassRefreshToken);
              }),
            );
          break;

        case 'edit':
          addEditObservable = this.modalService
            .openDialog<IDataPointDTO>(AddEditIgridSystemDatapointDialogComponent, {
              data: {
                system: this.system,
                datapoint,
                allDatapointsForSystem,
                editable: true,
              },
            })
            .pipe(
              switchMap(({ result: addedDatapoint }) => {
                return addedDatapoint
                  ? this.datapointService.updateDatapointForSystem(this.system?.parentFacilityId, this.system?.id, addedDatapoint)
                  : of(bypassRefreshToken);
              }),
            );
          break;

        default:
          // This will never actually happen but yeah, added it due to "best practice"...
          break;
      }

      this.subscriptions.add(
        addEditObservable.pipe(first()).subscribe({
          next: (r) => (r === bypassRefreshToken ? () => {} : this.refreshDatapointsForSystem()),
          error: (error) =>
            this.modalService.showErrorModal(
              this.translateService.instant('app-error'),
              mode === 'add'
                ? this.translateService.instant('data-point-service.failed-to-create-datapoint')
                : this.translateService.instant('data-point-service.failed-to-edit-datapoint'),
            ),
        }),
      );
    });
  }

  /**
   * This is called from template when clicking the delete icon button (awaiting backend support)
   * @param $event Click event; only included in order to prevent propagation (stop row click handling)
   * @param dataPoint The datapoint to delete
   */
  public deleteDatapoint($event: Event, dataPoint: IDataPointDTO) {
    $event.stopPropagation();
    this.subscriptions.add(
      this.datapointService.deleteDatapointForSystem(this.system.parentFacilityId, this.system.id, dataPoint.id).pipe(first()).subscribe(),
    );
  }

  /**
   * Refresh datapoints for system; this uses all datapoints and standardKeys in order to perform a correct mapping.
   * This waits for datapoints, standardKeys and datapoints for current system to all have been received from the backend before
   * executing the delegate subscription handler; as such, this is used as an async function that is not awaited.
   */
  private refreshDatapointsForSystem() {
    this.subscriptions.add(
      combineLatest([
        this.allDataPointTypes$,
        this.allStandardKeys$,
        this.datapointService.getDatapointsForSystem(this.system.parentFacilityId, this.system.id),
        this.translateService.get('igrid-installation-datapoints'),
      ])
        .pipe(first())
        .subscribe(([dataPointTypes, standardKeys, dataPoints, translations]) => {
          const dataPointsMapped = dataPoints.map((dp) => {
            const standardKey = (standardKeys ?? [])?.find((sk) => sk.id === dp.standardKeyId);
            const dataPointType = (dataPointTypes ?? [])?.find((dpt) => dpt.id === dp.dataPointTypeId);
            const name = standardKey?.key
              ? translations[standardKey?.key] ?? standardKey?.key + (dp.customName ? ` (${dp.customName})` : '')
              : dp.customName ?? '';
            return {
              ...dp,
              name,
              standardKey,
              dataPointType,
            };
          });
          this.datapoints$.next(orderBy(dataPointsMapped, 'order'));
        }),
    );
  }
}
