import { DOCUMENT } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { forkJoin, ReplaySubject } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AuthService } from 'shared';
import { environment } from '../../environments/environment';
import { IIGridDeviceRoute, IIGridDeviceRouteDTO } from '../interfaces/iGridDeviceRoute';

@Injectable({
  providedIn: 'root',
})
export class SystemRoutingService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    // When the user has signed in, fire the request to get allSystemRoutings:
    this.authService.currentUser.pipe(first((u) => !!u)).subscribe(() => this.refreshSystemRoutings());
  }
  private baseUrl = environment.getiGridCustomerPortalUrl(this.document.location);

  public allSystemRoutings$ = new ReplaySubject<IIGridDeviceRouteDTO>(1);

  public getAllSystemRoutings() {
    return this.http.get<IIGridDeviceRouteDTO>(`${this.baseUrl}/api/newRoutingrules`);
  }

  public toggleRoute(routeToAdd: IIGridDeviceRoute, ) {
    console.log()
  }

  public updateOutgoingRoutesForDevice(deviceId: string, outgoingRoutes: IIGridDeviceRoute[]) {
    return this.http
      .put<any>(`${this.baseUrl}/api/routingRules/${deviceId}`, {
        destinations: outgoingRoutes,
      })
      .pipe(tap(() => this.refreshSystemRoutings()));
  }

  public deleteRoute(sourceDeviceId: string, destinationDeviceId: string) {
    return this.allSystemRoutings$.pipe(
      first(),
      // first, update the local app state:
      tap(({ deviceroutes }) =>
        this.allSystemRoutings$.next({
          deviceroutes: {
            ...deviceroutes,
            [sourceDeviceId]: [...deviceroutes[sourceDeviceId]].filter((k) => k.targetDeviceId !== destinationDeviceId),
          },
        }),
      ),
      // Secondly, call server to actually perform the update:
      switchMap(({ deviceroutes }) => {
        const arrPostDeletion = deviceroutes[sourceDeviceId].filter((dr) => dr.targetDeviceId !== destinationDeviceId);
        return this.updateOutgoingRoutesForDevice(sourceDeviceId, arrPostDeletion);
      }),
    );
  }

  public addRoute(sourceDeviceId: string, routeToAdd: IIGridDeviceRoute) {
    return this.allSystemRoutings$.pipe(
      first(),
      switchMap(({ deviceroutes }) => {
        const arrPostAddition = [...(deviceroutes[sourceDeviceId] ?? []), routeToAdd];
        return this.updateOutgoingRoutesForDevice(sourceDeviceId, arrPostAddition);
      }),
    );
  }

  public refreshSystemRoutings() {
    this.getAllSystemRoutings()
      .pipe(first())
      .subscribe((routings) => this.allSystemRoutings$.next(routings));
  }
}
