import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommissionService } from '../../../services/commission.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { IConfigVersions, IFullInstallationDTO } from '../../../interfaces/installation';

type ETabs = 'missingId' | 'pendingDevice' | 'testing' | 'tested' | 'live';

@Component({
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrls: ['./commission.component.scss'],
})
export class CommissionComponent implements OnInit, OnDestroy {
  installation;
  deviceIdControl = new UntypedFormControl('');

  currentfilter;

  softwareNames$;
  showSoftwareSelection = false;
  selectedSoftwarePackageName;
  selectedSoftwarePackage;
  filteredSoftewarePackages;

  updatingDeviceId = false;
  requestPending = false;

  deviceTwinStatus;
  checkResult;
  installations$;
  configVersions: IConfigVersions;

  subscription;
  constructor(
    public commissionService: CommissionService,
    private router: Router,
    public activeRoute: ActivatedRoute,
  ) {
    this.commissionService.GetConfigVersions3().subscribe((mydata) => {
      this.configVersions = mydata;
    });
    // this.configVersions = commissionService.configVersions;
    this.installations$ = commissionService.installationsChanged$;

    this.softwareNames$ = commissionService.softwareNamesChanged$;

    // When Active Route changes
    this.activeRoute.paramMap.subscribe((paramMap) => {
      const installationId = paramMap.get('installationId');
      if (installationId) {
        // Setting Installation
        this.subscription = this.installations$.subscribe((installations) => {
          this.installation = installations.find((i) => i.id === installationId);
          if (this.installation) {
            this.deviceIdControl.setValue(this.installation.deviceId);

            if (this.activeRoute.snapshot.queryParams.tab === 'testing') {
              this.getInstallationTwinStatus();
            } else if (this.activeRoute.snapshot.queryParams.tab === 'pendingDevice') {
              this.checkDeviceOnlineStatus();
            }
          }
        });
      }
    });

    const queryParams = this.activeRoute.snapshot.queryParams;
    if (!queryParams.tab) {
      this.router.navigate(['.'], {
        relativeTo: this.activeRoute,
        queryParams: { tab: 'missingId' },
      });
    }
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  isSelectedInstallation(installation) {
    return installation === this.installation;
  }

  isNumbers(splitResult) {
    var i: number;
    for (i = 0; i < splitResult.length; i++) {
      if (isNaN(splitResult[i])) {
        return false;
      }
    }
    return true;
  }

  isVersionOk(backendVersion, firmwareVersion) {
    if (backendVersion == null || firmwareVersion == null) {
      return true;
    }
    var backendParts = backendVersion.split('.');
    var firmwareParts = firmwareVersion.split('.');
    if (this.isNumbers(backendParts) && this.isNumbers(firmwareParts) && backendParts.length > 2 && firmwareParts.length > 2) {
      var b_major: Number = parseInt(backendParts[0], 10);
      var f_major: Number = parseInt(firmwareParts[0], 10);
      var b_minor: Number = parseInt(backendParts[1], 10);
      var f_minor: Number = parseInt(firmwareParts[1], 10);
      console.log(
        'b: ' +
          JSON.stringify(backendVersion) +
          ', f: ' +
          JSON.stringify(firmwareVersion) +
          ', going to return ' +
          (b_major == f_major && b_minor <= f_minor),
      );
      return b_major == f_major && b_minor >= f_minor;
    } else {
      return true;
    }
  }

  ngOnInit() {}

  reloadInstallationData() {
    this.commissionService.reloadInstallations();
  }

  getSoftwareVersionsFromData() {
    this.filteredSoftewarePackages = this.commissionService.softwareData.filter(
      (packageInfo) => packageInfo.name === this.selectedSoftwarePackageName,
    );
  }

  tabToCommissionStates(tabName) {
    if (tabName === 'missingId') {
      return ['Created'];
    } else if (tabName === 'pendingDevice') {
      return ['DeviceIdRegistered'];
    } else if (tabName === 'testing') {
      return ['DeviceRegisteredInIotHub', 'ReadyForTest', 'BeingTested'];
    } else if (tabName === 'tested') {
      return ['TestSuccess'];
    } else if (tabName === 'live') {
      return ['Live'];
    }
  }

  selectTab(tab: ETabs) {
    this.installation = null;

    this.selectedSoftwarePackage = null;
    this.selectedSoftwarePackageName = null;

    if (tab === 'missingId') {
    }
    if (tab === 'pendingDevice') {
      this.checkDeviceOnlineStatus();
    }
    if (tab === 'testing') {
    }
    if (tab === 'tested') {
    }
    if (tab === 'live') {
    }

    this.router.navigate(['commission'], { queryParams: { tab } });
  }

  toggleShowSoftwareSelection() {
    if (this.showSoftwareSelection) {
    } else {
      this.selectedSoftwarePackage = null;
      this.selectedSoftwarePackageName = null;
    }

    this.showSoftwareSelection = !this.showSoftwareSelection;
  }

  selectTabWithInstallation(tab: ETabs) {
    this.selectedSoftwarePackage = null;
    this.selectedSoftwarePackageName = null;

    if (tab === 'missingId') {
    }
    if (tab === 'pendingDevice') {
    }
    if (tab === 'testing') {
    }
    if (tab === 'tested') {
    }

    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: { tab },
      queryParamsHandling: 'merge',
    });
  }

  selectInstallation(installation: IFullInstallationDTO, tab) {
    this.router.navigate([installation.id], {
      relativeTo: this.activeRoute,
      queryParams: { tab },
    });
  }
  // type ETabs = 'missingId'
  setDeviceIDtoControlValue() {
    if (this.installation) {
      this.updatingDeviceId = true;
      this.commissionService.setInstallationDeviceId(this.installation.id, this.deviceIdControl.value).then(
        (returnedInstallation) => {
          this.installation = returnedInstallation;
          if (this.selectedSoftwarePackage) {
            this.commissionService
              .updateDeviceSoftware(returnedInstallation.id, this.selectedSoftwarePackage ? this.selectedSoftwarePackage : {})
              .then(
                (softwareInfo) => {
                  this.updatingDeviceId = false;
                  this.reloadInstallationData();
                  this.selectTabWithInstallation('pendingDevice');
                },
                (e) => {
                  this.updatingDeviceId = false;
                },
              );
          } else {
            this.updatingDeviceId = false;
            this.reloadInstallationData();
            this.selectTabWithInstallation('pendingDevice');
          }
        },
        (e) => {
          this.checkResult = e;
          if (e.status === 400) {
            this.checkResult.statusText = 'Invalid Device id';
          } else if (e.status === 404) {
            this.checkResult.statusText = 'Installation not found';
          } else {
            this.checkResult.statusText = 'Unknown Error: ' + this.checkResult.statusText;
          }
          this.updatingDeviceId = false;
        },
      );
    }
  }

  // type ETabs = 'pendingDevice'
  checkDeviceOnlineStatus() {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .checkDeviceOnlineStatus(this.installation.id)
        .then((res) => {
          this.checkResult = { status: 200, statusText: 'Success 🦾' };
          this.requestPending = false;
          this.reloadInstallationData();
          this.selectTabWithInstallation('testing');
        })
        .catch((error) => {
          this.checkResult = error;
          if (error.status === 417) {
            this.checkResult.statusText = 'Device not found in IOTHub';
          } else if (error.status === 404) {
            this.checkResult.statusText = 'Installation not found';
          } else {
            this.checkResult.statusText = 'Unknown Error: ' + this.checkResult.statusText;
          }
          this.requestPending = false;
        });
    }
  }

  // type ETabs = 'testing'
  getInstallationTwinStatus() {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .getInstallationTwinStatus(this.installation.id)
        .then((res) => {
          this.deviceTwinStatus = res;
          this.requestPending = false;
        })
        .catch((res) => {
          this.deviceTwinStatus = res;
          this.requestPending = false;
        });
    }
  }

  testInstallation(command: string) {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .commandInstallation(this.installation.id, command)
        .then((res) => {
          this.checkResult = { status: 200, statusText: 'Success 🦾' };
          this.requestPending = false;
        })
        .catch((res) => {
          this.checkResult = res;
          this.requestPending = false;
        });
    }
  }

  approveDeviceTests() {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .approveDeviceTests(this.installation.id)
        .then((res) => {
          this.checkResult = { status: 200, statusText: 'Success 🦾' };
          this.requestPending = false;
          this.reloadInstallationData();
          this.selectTabWithInstallation('tested');
        })
        .catch((res) => {
          this.checkResult = res;
          this.requestPending = false;
        });
    }
  }

  // type ETabs = tested'
  moveDeviceToLive() {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .goliveInstallation(this.installation.id)
        .then((res) => {
          this.checkResult = { status: 200, statusText: 'Success 🦾' };
          this.requestPending = false;
          this.reloadInstallationData();
          this.selectTabWithInstallation('testing');
        })
        .catch((res) => {
          this.checkResult = res;
          this.requestPending = false;
        });
    }
  }

  // Utility
  resetCommissionProgress() {
    if (!this.requestPending) {
      this.checkResult = '';
      this.requestPending = true;
      this.commissionService
        .resetCommissionProgress(this.installation.id)
        .then((res) => {
          this.deviceIdControl.setValue('');
          this.installation = res;
          this.requestPending = false;
          this.reloadInstallationData();
          this.selectTabWithInstallation('missingId');
        })
        .catch((res) => {
          this.checkResult = res;
          this.requestPending = false;
        });
    }
  }
}
