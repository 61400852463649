import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCompanyOnName',
})
export class FilterCompanyOnNamePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (args[0]) {
      return value.filter((company) => company.name.toLowerCase().includes(args[0].toLowerCase()));
    }
    return value;
  }
}
