import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, shareReplay } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';
import { CompanyService } from './company.service';
import {
  IUser,
  IPOSTCompanyUser,
  IPOSTFacilityManager,
  IPUTCompanyUser,
  IPUTFacilityManager,
  IPostUser,
  IPOSTServiceUser,
  IPUTServiceUser,
} from 'projects/serviceportal/src/app/interfaces/user';
import { createRefreshSubject, CommandService, AppErrorService } from 'shared';
import { CurrentUserService } from './current-user.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private readonly baseUserUrl = `${environment.serverUrl}/api/users`;

  private allUsers$: Observable<IUser[]>;

  public refreshAllUsers: () => Observable<IUser[]>;

  public usersWithCompany$: Observable<IUser[]>;

  private httpGetAllUsers() {
    return this.httpClient.get<IUser[]>(this.baseUserUrl);
  }

  constructor(
    private httpClient: HttpClient,
    private companyService: CompanyService,
    private currentUserService: CurrentUserService,
    private commandService: CommandService,
    private appErrorService: AppErrorService,
  ) {
    const { subject, scheduleRefresh } = createRefreshSubject(() => this.httpGetAllUsers(), currentUserService.initialCurrentUser$);
    this.allUsers$ = subject;
    this.refreshAllUsers = scheduleRefresh;
    this.usersWithCompany$ = combineLatest([this.allUsers$, this.companyService.companies$]).pipe(
      map(([users, companies]) =>
        users.map(
          (u) =>
            ({
              ...u,
              company: companies.find((c) => c.id === u.companyId),
            }) as IUser,
        ),
      ),
      shareReplay(1),
    );
  }
  public createServiceUser(user: IPOSTServiceUser): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.post<IUser>(`${environment.serverUrl}/api/users/service`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.create-service-user-error',
          }),
          shareReplay(),
        );
        result.subscribe((_) => this.refreshAllUsers());
        return result;
      },
      { successMessageKey: 'user-service.create-service-user-success' },
    );
  }

  public createCompanyAdminUser(user: IPOSTCompanyUser): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.post<IUser>(`${environment.serverUrl}/api/users/CompanyAdmin`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.create-company-admin-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          }),
          shareReplay(),
        );
        result.subscribe(
          (_) => this.refreshAllUsers(),
          () => {},
        );
        return result;
      },
      { successMessageKey: 'user-service.create-company-admin-success' },
    );
  }

  public createFacilityManagerUser(user: IPOSTFacilityManager): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.post<IUser>(`${environment.serverUrl}/api/users/FacilityManager`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.create-facility-manager-error',
            errorCodes: {
              302: {
                titleKey: 'user-service.user-already-exists',
                messageKey: 'user-service.user-already-exists-message',
              },
            },
          }),
          shareReplay(),
        );
        result.subscribe(
          (_) => this.refreshAllUsers(),
          () => {},
        );
        return result;
      },
      { successMessageKey: 'user-service.create-facility-manager-success' },
    );
  }

  public updateServiceUser(user: IPUTServiceUser): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<IUser>(`${environment.serverUrl}/api/users/service/${user.id}`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.update-service-user-error',
          }),
          shareReplay(),
        );
        result.subscribe((_) => this.refreshAllUsers());
        return result;
      },
      { successMessageKey: 'user-service.update-service-user-success' },
    );
  }

  public updateCompanyAdminUser(user: IPUTCompanyUser): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<IUser>(`${environment.serverUrl}/api/users/CompanyAdmin/${user.id}`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.update-company-admin-error',
          }),
          shareReplay(),
        );
        result.subscribe((_) => this.refreshAllUsers());
        return result;
      },
      { successMessageKey: 'user-service.update-company-admin-success' },
    );
  }

  public updateFacilityManagerUser(user: IPUTFacilityManager): Observable<IUser> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<IUser>(`${environment.serverUrl}/api/users/FacilityManager/${user.id}`, user).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.update-facility-manager-error',
          }),
          shareReplay(),
        );
        result.subscribe((_) => this.refreshAllUsers());
        return result;
      },
      { successMessageKey: 'user-service.update-facility-manager-success' },
    );
  }

  // Common APIs
  public getUser(userId: string): Observable<IUser> {
    return this.httpClient.get<IUser>(`${this.baseUserUrl}/${userId}`).pipe(shareReplay());
  }

  public getUsersForFacility(facilityId: string): Observable<IUser[]> {
    return this.httpClient.get<IUser[]>(`${this.baseUserUrl}/facility/${facilityId}`).pipe(shareReplay());
  }

  public archiveUser(userId: string) {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<void>(`${this.baseUserUrl}/archiveUser/${userId}`, {}).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.archive-failed',
            errorCodes: {
              403: {
                messageKey: 'user-service.archive-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.refreshAllUsers(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'user-service.archive-success' },
    );
  }

  public archiveUserForDeletion(userIds: string[]) {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put<void>(`${this.baseUserUrl}/archiveUsersForDeletion`, userIds).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'user-service.archive-for-deletion-failed',
            errorCodes: {
              403: {
                messageKey: 'user-service.archive-for-deletion-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.refreshAllUsers(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'user-service.archive-for-deletion-success' },
    );
  }

  // Admin Create
  public createUser(user: IPostUser): Observable<IUser> {
    const result = this.httpClient.post<IUser>(`${this.baseUserUrl}/administration`, user).pipe(shareReplay());
    result.subscribe(() => this.refreshAllUsers());
    return result;
  }
  // Admin Update
  public updateCustomUser(userId, user: IUser): Observable<IUser> {
    const result = this.httpClient.put<IUser>(`${this.baseUserUrl}/administration/${userId}`, user).pipe(shareReplay());
    result.subscribe(() => this.refreshAllUsers());
    return result;
  }

  public deleteUser(user: IUser): Observable<unknown> {
    const result = this.httpClient.delete(`${environment.serverUrl}/api/users/${user.id}`).pipe(shareReplay());
    result.subscribe(() => this.refreshAllUsers());
    return result;
  }
}
