import { Injectable } from '@angular/core';
import { EFacilityType } from 'projects/serviceportal/src/app/interfaces/facility';

@Injectable({
  providedIn: 'root',
})
export class FacilityTypeService {
  facilityTypes;

  constructor() {
    this.facilityTypes = Object.keys(EFacilityType);
  }
}
