<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>

<ng-template #content>
  <ng-container *ngIf="userWithCompany$ | async as user">
    <gbc-page-header *ngIf="pageInfo$ | async as pageInfo" [pageInfo]="pageInfo">
      <ng-container gbc-page-header-actions>
        <button gbc-text-button (click)="editUser(user.id)">
          <img style="margin-right: 10px" src="./assets/svgs/edit.svg" />
          {{ 'app-edit' | translate }}
        </button>

        <button gbc-text-button (click)="confirmArchiveUser()">
          <img style="margin-right: 10px" src="./assets/svgs/archive.svg" />
          {{ 'app-archive' | translate }}
        </button>
      </ng-container>
    </gbc-page-header>

    <gbc-page-container>
      <gbc-tile [title]="'user-page.user-information' | translate">
        <gbc-input-container>
          <gbc-input-label>{{ 'user-page.name' | translate }}</gbc-input-label>
          <gbc-input-field disabled="true" [(ngModel)]="user.name" name="name"></gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'user-page.email' | translate }}</gbc-input-label>
          <gbc-input-field disabled="true" [(ngModel)]="user.email" name="email"></gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'user-page.company' | translate }}</gbc-input-label>
          <gbc-input-field disabled="true" [(ngModel)]="user.company.name" name="company"></gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'user-page.role' | translate }}</gbc-input-label>
          <gbc-input-field disabled="true" [(ngModel)]="user.formattedAccessLevel" name="role"></gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'user-page.last-login' | translate }}</gbc-input-label>
          <gbc-input-field disabled="true" [(ngModel)]="user.formattedLastLifesignTimestampEpoch" name="lastLogin"></gbc-input-field>
        </gbc-input-container>

        <br />
      </gbc-tile>
      <gbc-tile *ngIf="(isFacilityManager$ | async) || (isCompanyOwner$ | async)" [title]="'user-page.assigned-to' | translate">
        <app-facility-list *ngIf="facilities$ | async as facilities" [facilities]="facilities"></app-facility-list>
      </gbc-tile>
    </gbc-page-container>
  </ng-container>
</ng-template>
