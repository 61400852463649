import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { CommandService } from '../../services/command.service';

@Component({
  selector: 'gbc-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit {
  @HostBinding('class.no-shadow') noShadow = false;

  constructor() {}

  ngOnInit(): void {}
}
