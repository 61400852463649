import { Injectable, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { serverUrlToken } from '../injection-tokens';
import { tap, shareReplay, first } from 'rxjs/operators';
import * as moment from 'moment';

// This type is the language codes that we use in our application, these are the source of truth
export enum languages {
  'nl-NL' = 'nl-NL',
  'en' = 'en',
  'da-DK' = 'da-DK',
  'fr-FR' = 'fr-FR',
  'de-DE' = 'de-DE',
  'it-IT' = 'it-IT',
  'sv-SE' = 'sv-SE',
}

// As the name implies, these are the languages that are shown in the frontend
export const languagesVisibleInFronted: {
  name: string;
  languageCode: languages;
}[] = [
  {
    name: 'English',
    languageCode: languages['en'],
  },
  {
    name: 'Dansk',
    languageCode: languages['da-DK'],
  },
  {
    name: 'Deutsch',
    languageCode: languages['de-DE'],
  },
  {
    name: 'Français',
    languageCode: languages['fr-FR'],
  },
  {
    name: 'Nederlands',
    languageCode: languages['nl-NL'],
  },
  {
    name: 'Svenska',
    languageCode: languages['sv-SE'],
  },
];

// We use certain locale features from moment for some dates and times
export const momentLanguageMap: { [k: string]: string } = {
  [languages.en]: 'en-gb',
  [languages['de-DE']]: 'de',
  [languages['nl-NL']]: 'nl',
  [languages['da-DK']]: 'da',
  [languages['sv-SE']]: 'sv',
  [languages['fr-FR']]: 'fr',
  [languages['it-IT']]: 'it',
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  public languages$: Observable<string[]>;
  public currentLanguage: BehaviorSubject<string> = new BehaviorSubject(null);
  private defaultLanguage = 'en';

  constructor(
    private translate: TranslateService,
    private httpClient: HttpClient,
    @Inject(serverUrlToken) private serverUrl: string,
  ) {
    this.translate.setDefaultLang(this.defaultLanguage);
    this.setLanguageLocally(this.defaultLanguage);

    this.languages$ = this.getLanguagesFromBackend().pipe(shareReplay());

    this.languages$
      .pipe(
        first(),
        tap((languages) => {
          this.translate.addLangs(languages);
        }),
      )
      .subscribe();
  }

  public async setupLanguages(userLanguage: string) {
    this.languages$
      .pipe(
        first(),
        tap((languages) => {
          // Always use the language that the user has set on their profile first
          if (userLanguage && languages.includes(userLanguage)) {
            this.setLanguageLocally(userLanguage);
            return;
          }

          // Get the users browserlanguage and set if there is a match
          const userBrowserLanguage = this.translate.getBrowserCultureLang();
          if (languages.includes(userBrowserLanguage)) {
            this.setLanguageLocallyAndRemotely(userBrowserLanguage);
            return;
          }

          // The user has a language with a culture (en-GB)
          // but we don't have that in our supported languages
          // but we do have one without culture (en)
          if (userBrowserLanguage.includes('-')) {
            const withoutCulture = this.translate.getBrowserLang(); // this returns browserLang without culture
            for (const language of languages) {
              if (language === withoutCulture) {
                this.setLanguageLocallyAndRemotely(language);
                return;
              }
            }
          } else {
            // The user can have a language without a culture: ie. 'de' instead of 'de-DE'
            // We will attempt to find a language that matches and select the first one
            for (const language of languages) {
              const indexOfDash = language.indexOf('-');
              const languageWithoutCulture = language.slice(0, indexOfDash);
              if (languageWithoutCulture === userBrowserLanguage) {
                this.setLanguageLocallyAndRemotely(language);
                return;
              }
            }
          }

          this.setLanguageLocallyAndRemotely(this.defaultLanguage);
        }),
      )
      .subscribe();
  }

  public setLanguageLocallyAndRemotely(language: string) {
    this.setLanguageLocally(language);
    this.setLanguageOnBackend(language);
    this.setMomentLocale(language);
  }

  public setLanguageLocally(language: string) {
    this.translate.use(language);
    this.currentLanguage.next(language);
    this.setMomentLocale(language);
  }

  private setMomentLocale(locale: string) {
    moment.locale(momentLanguageMap[locale]);
  }

  private getLanguagesFromBackend(): Observable<string[]> {
    return this.httpClient.get<string[]>(`${this.serverUrl}/api/configuration/languages`);
  }

  private setLanguageOnBackend(language: string) {
    return this.httpClient.put(`${this.serverUrl}/api/users/language/${language}`, null).subscribe();
  }
}
