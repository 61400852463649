import { InjectionToken, Injector } from '@angular/core';

export const MODAL_DATA = new InjectionToken('MODAL_DATA');
export const MODAL_CONTROLLER = new InjectionToken('MODAL_CONTROLLER');

export interface DismissResult {
  dismissed: false;
  result: null;
}

export interface CompleteResult<T> {
  dismissed: true;
  result: T;
}

export type Result<T> = DismissResult | CompleteResult<T>;

export interface ModalController<T> {
  dismiss();

  complete(result: T);
}

export interface OpenDialogOptions {
  injector?: Injector;
  data?: any;
}

export interface ITextModalAction {
  text: string;
  type?: 'default' | 'danger' | 'primary';
  handler?: () => void;
  cancel?: boolean;
}

export interface ITextModalData {
  iconUrl?: string;
  headline?: string;
  title: string;
  content: string;
  actions: ITextModalAction[];
}
