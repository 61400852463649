<gbc-modal [title]="title$ | async">
  <gbc-modal-content>
    <div class="container">
      <form #createIGridSystemSettingForm [formGroup]="form" (ngSubmit)="save()">
        <gbc-input-container>
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.parent' | translate }}</gbc-input-label>
          <gbc-dropdown
            formControlName="parentId"
            placeholderTranslationString="{{ form.disabled ? '' : ('app-select' | translate) }}"
            [dropDownItems]="selectableParentSettings$ | async"
          >
          </gbc-dropdown>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.setting-type' | translate }}*</gbc-input-label>
          <gbc-dropdown
            formControlName="settingType"
            placeholderTranslationString="app-select"
            [dropDownItems]="selectableSettingTypes$ | async"
          >
          </gbc-dropdown>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.order' | translate }}</gbc-input-label>
          <gbc-input-field inputType="number" placeholderTranslationString="add-igrid-system-setting-dialog.order" formControlName="order">
          </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.value' | translate }}</gbc-input-label>
          <gbc-input-field
            [min]="form.value.settingType?.value?.valueType === 'number' ? form.value.settingType?.value?.minValue : undefined"
            [max]="form.value.settingType?.value?.valueType === 'number' ? form.value.settingType?.value?.maxValue : undefined"
            inputType="{{ form.value.settingType?.value?.valueType === 'number' ? 'number' : 'text' }}"
            placeholderTranslationString="{{ form.disabled ? '' : ('add-igrid-system-setting-dialog.value' | translate) }}"
            formControlName="value"
          >
          </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container>
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.custom-name' | translate }}</gbc-input-label>
          <gbc-input-field
            inputType="text"
            placeholderTranslationString="{{ form.disabled ? '' : ('app-name' | translate) }}"
            formControlName="customName"
          >
          </gbc-input-field>
        </gbc-input-container>

        <gbc-input-container *ngIf="data.isSupportUser" class="is-support-user-setting">
          <gbc-input-label>{{ 'add-igrid-system-setting-dialog.is-support-user-setting' | translate }}🧑‍🔧 </gbc-input-label>
          <gbc-toggle formControlName="isSupportUserSetting"></gbc-toggle>
        </gbc-input-container>

        <div class="form-group">
          <div *ngFor="let error of form.errors" class="error-container alert alert-danger">
            {{ error }}
          </div>
          <gbc-input-container class="action-button-row">
            <ng-container *ngIf="data.editable; else readOnlyBtn">
              <button gbc-button primary [disabled]="form.invalid || !form.dirty" (click)="save()" class="primary">
                {{ 'app-done' | translate }}
              </button>
              <button gbc-button (click)="cancel()">
                {{ 'app-cancel' | translate }}
              </button>
            </ng-container>
            <ng-template #readOnlyBtn>
              <button gbc-button (click)="cancel()">
                {{ 'app-ok' | translate }}
              </button>
            </ng-template>
          </gbc-input-container>
        </div>
      </form>
    </div>
  </gbc-modal-content>
</gbc-modal>
