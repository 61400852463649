<gbc-tile [title]="'add-edit-igrid-system-datapoint.datapoints' | translate">
  <ng-container *ngIf="datapoints$ | async as dataPoints">
    <gbc-table *ngIf="dataPoints?.length; else noSources" [clickableRows]="true">
      <th gbc-table-header colspan="2">
        {{ 'add-edit-igrid-system-datapoint.data-key' | translate }}
      </th>
      <th gbc-table-header colspan="2">{{ 'app-name' | translate }}</th>
      <th gbc-table-header colspan="2">
        {{ 'add-edit-igrid-system-datapoint.datapoint-type' | translate }}
      </th>
      <th gbc-table-header colspan="2">
        {{ 'add-edit-igrid-system-datapoint.order' | translate }}
      </th>
      <th gbc-table-header colspan="2">
        {{ 'add-edit-igrid-system-datapoint.last-value' | translate }}
      </th>
      <th gbc-table-header colspan="2">
        {{ 'add-edit-igrid-system-datapoint.visible' | translate }}
      </th>
      <th gbc-table-header colspan="2" *ngIf="showDelete">
        {{ 'add-edit-igrid-system-datapoint.actions' | translate }}
      </th>

      <tr gbc-table-row *ngFor="let datapoint of dataPoints" (click)="openAddEditDatapointDialog(datapoint)">
        <td gbc-table-cell colspan="2">{{ datapoint.dataKey }}</td>
        <td gbc-table-cell colspan="2" id="name-column">
          {{ datapoint.name }}
        </td>
        <td gbc-table-cell colspan="2">
          {{ datapoint.dataPointType?.unit | translate }}
        </td>
        <td gbc-table-cell colspan="2">{{ datapoint.order }}</td>
        <td gbc-table-cell colspan="2">
          {{ datapoint.lastValue | number: '1.0-2' | noComma }}
        </td>
        <td gbc-table-cell colspan="2">
          <img *ngIf="datapoint.visible" src="./assets/svgs/green-checked-circle.svg" height="29px" width="29px" alt="green check" />
          <img *ngIf="!datapoint.visible" src="./assets/svgs/remove-circle_filled.svg" height="29px" width="29px" alt="red minus" />
        </td>
        <td *ngIf="showDelete" gbc-table-cell colspan="2">
          <button gbc-icon-button id="delete-datapoint-button" (click)="deleteDatapoint($event, datapoint)">🗑️</button>
        </td>
      </tr>
    </gbc-table>

    <div class="button-row">
      <button gbc-button id="add-datapoint-button" (click)="openAddEditDatapointDialog()">
        {{ 'add-edit-igrid-system-datapoint.add-new-datapoint' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-template #noSources>
    <div class="no-sources-label">
      <p>{{ 'add-edit-igrid-system-datapoint.no-data-points' | translate }}</p>
    </div>
  </ng-template>
</gbc-tile>
