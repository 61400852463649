import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppErrorService, FORBIDDEN_ERROR_CODE, NOT_FOUND_ERROR_CODE, PAYLOAD_TOO_LARGE_CODE } from 'shared';
import { environment } from '../../environments/environment';
import { IGisSourceFileRecord } from '../interfaces/gisSource';

@Injectable({
  providedIn: 'root',
})
export class GisSourceService {
  constructor(
    private httpClient: HttpClient,
    private appErrorService: AppErrorService,
  ) {}
  private rootGisUrl = `${environment.getiGridCustomerPortalUrl(location) ?? `https://${location.hostname}`}/api/gis-data`;

  public gisSources$ = new ReplaySubject<IGisSourceFileRecord[]>(1);

  public refreshGisDataSources() {
    this.getGisSources()
      .pipe(first())
      .subscribe((res) => this.gisSources$.next(res));
  }

  public getGisSources(): Observable<IGisSourceFileRecord[]> {
    return this.httpClient.get<IGisSourceFileRecord[]>(`${this.rootGisUrl}/source-file`).pipe(
      this.appErrorService.catchApiError<IGisSourceFileRecord[]>({
        errorCodes: { ...FORBIDDEN_ERROR_CODE, ...NOT_FOUND_ERROR_CODE },
        fallbackMessageKey: 'gis-data-source-dialog.failed-to-get-sources',
      }),
    );
  }

  public deleteGisSource(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.rootGisUrl}/source-file/${id}`).pipe(
      this.appErrorService.catchApiError<void>({
        errorCodes: { ...FORBIDDEN_ERROR_CODE, ...NOT_FOUND_ERROR_CODE },
        fallbackMessageKey: 'gis-data-source-dialog.failed-to-delete-source',
      }),
    );
  }

  public deleteGisSourceImport(id: string): Observable<void> {
    return this.httpClient.delete<void>(`${this.rootGisUrl}/import/${id}`).pipe(
      this.appErrorService.catchApiError<void>({
        errorCodes: { ...FORBIDDEN_ERROR_CODE, ...NOT_FOUND_ERROR_CODE },
        fallbackMessageKey: 'gis-data-source-dialog.failed-to-delete-source',
      }),
    );
  }

  public indexGisSource(id: string): Observable<IGisSourceFileRecord> {
    return this.httpClient
      .post<IGisSourceFileRecord>(`${this.rootGisUrl}/import`, {
        sourceFile: id,
      })
      .pipe(
        this.appErrorService.catchApiError<IGisSourceFileRecord>({
          errorCodes: {
            ...FORBIDDEN_ERROR_CODE,
            ...NOT_FOUND_ERROR_CODE,
            ...PAYLOAD_TOO_LARGE_CODE,
          },
          fallbackMessageKey: 'gis-data-source-dialog.failed-to-upload-source',
        }),
      );
  }

  public uploadGisSource(formData): Observable<string> {
    return this.httpClient.post<string>(`${this.rootGisUrl}/source-file`, formData).pipe(
      this.appErrorService.catchApiError<string>({
        errorCodes: {
          ...FORBIDDEN_ERROR_CODE,
          ...NOT_FOUND_ERROR_CODE,
          ...PAYLOAD_TOO_LARGE_CODE,
        },
        fallbackMessageKey: 'gis-data-source-dialog.failed-to-upload-source',
      }),
    );
  }
}
