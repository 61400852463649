import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe, NgOptimizedImage } from '@angular/common';
import { DeveloperUserPageComponent } from './pages/developer/developer-user-page/developer-user-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DeveloperFacilityPageComponent } from './pages/developer/facility-page/developer-facility-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DeveloperInstallationsPageComponent } from './pages/developer/developer-installations-page/developer-installations-page.component';
import { DeveloperCompanyPageComponent } from './pages/developer/developer-company-page/developer-company-page.component';
import { ServicegroupPageComponent } from './pages/developer/servicegroup-page/servicegroup-page.component';
import { AccessClaimsGroupPageComponent } from './pages/developer/accessclaimsgroup-page/accessclaimsgroup-page.component';
import { OverviewPageComponent } from './pages/overview-page/overview-page.component';

import version from '../assets/version.json';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FilterCompanyOnNamePipe } from './pipes/filter-company-on-name.pipe';
import { SortByNamePipe } from './pipes/sort-company-by-name.pipe';
import { PathInterceptor } from './interceptors/path.interceptor';
import { CreateInstallationFormComponent } from './components/create-installation-form/create-installation-form.component';
import { CreateEditCompanyComponent } from './pages/create-edit-company/create-edit-company.component';
import { CreateEditFacilityComponent } from './pages/create-edit-facility/create-edit-facility.component';
import { SummaryPageComponent } from './components/summary-page/summary-page.component';
import { DeveloperSupportPageComponent } from './pages/developer/developer-support-page/developer-support-page.component';
import { CommissionComponent } from './pages/developer/commission/commission.component';
import { SortByValuePipe } from './pipes/sort-by-value.pipe';
import { FilterInstallationOnNameOrSerialPipe } from './pipes/filter-installation-on-name-or-serial.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import {
  serverUrlToken,
  SharedModule,
  TokenInterceptor,
  versionToken,
  sentryConfig,
  SentryErrorHandler,
  AuthService,
  TestAuthService,
  TestInterceptor,
} from 'shared';
import { DeveloperMenuComponent } from './components/developer-menu/developer-menu.component';
import { FilterByTabPipe } from './pipes/filter-by-tab.pipe';
import { RootPageComponent } from './pages/root-page/root-page.component';
import { environment } from '../environments/environment';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CreateEditIGridInstallationComponent } from './pages/create-edit-igrid-installation/create-edit-igrid-installation.component';
import { IGridInstallationPageComponent } from './pages/igrid-installation-page/igrid-installation-page.component';
import { SearchbarComponent } from './components/searchbar/searchbar.component';
import { IGridInstallationConfigurationFormComponent } from './components/igrid-installation-configuration-form/igrid-installation-configuration-form.component';
import { UserPageComponent } from 'projects/serviceportal/src/app/pages/user-page/user-page.component';
import { CreateEditUserComponent } from './pages/create-edit-user/create-edit-user.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { IGridSummaryPageComponent } from './components/igrid-summary-page/igrid-summary-page.component';
import { IGridInstallationModuleConfigurationFormComponent } from './components/igrid-installation-module-configuration-form/igrid-installation-module-configuration-form.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { FacilityPageComponent } from './pages/facility-page/facility-page.component';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchivePageComponent } from './pages/developer/archive-page/archive-page.component';
import { FilterCompaniesByServiceGroups } from './pipes/filter-companies-by-servicegroups';
import { EditIGridInstallationWizardComponent } from './components/edit-igrid-installation-wizard/edit-igrid-installation-wizard.component';
import { SortBySerialnumberPipe } from './pipes/sort-by-serialnumber.pipe';
import { FacilityListComponent } from './components/facility-list/facility-list.component';
import { FacilityInstallationSearchListComponent } from './components/facility-installation-search-list/facility-installation-search-list.component';
import { GisSourcesListComponent } from './components/gis-sources-list/gis-sources-list.component';
import { AddGisDataSourceDialogComponent } from './components/add-gis-data-source-dialog/add-gis-data-source-dialog.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormatBytesPipe } from './pipes/format-bytes.pipe';
import { LatestGisImportTimePipe } from './pipes/latest-gis-import-time.pipe';
import { LatestGisImportStatusPipe } from './pipes/latest-gis-import-status.pipe';
import { IgridSettingsComponent } from './components/igrid-settings/igrid-settings.component';
import { MultiDimensionalViewComponent } from './components/multi-dimensional-view/multi-dimensional-view.component';
import { PolymorpheusModule } from '@tinkoff/ng-polymorpheus';
import { AddIgridSystemSettingDialogComponent } from './components/add-igrid-system-setting-dialog/add-igrid-system-setting-dialog.component';
import { RandomColorPipe } from './components/igrid-settings/random-color.pipe';
import { LatestGisImportStatusMessagePipe } from './pipes/latest-gis-import-status-message.pipe';
import { LatestGisImportPipe } from './pipes/latest-gis-import.pipe';
import { CdkVirtualScrollViewportPatchDirective } from './directives/cdk-virtual-scroll-viewport-patch.directive';
import { IgridSystemDatapointListComponent } from './components/igrid-system-datapoint-list/igrid-system-datapoint-list.component';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { AddEditIgridSystemDatapointDialogComponent } from './components/add-edit-igrid-system-datapoint-dialog/app-add-edit-igrid-system-datapoint-dialog.component';
import { GenerateLinkFromDeviceIdPipe } from './pipes/generate-link-from-device-id.pipe';
import { IgridSystemRoutingsComponent } from './components/igrid-system-routings/igrid-system-routings.component';
import { AddEditRouteForSystemDialogComponent } from './components/add-edit-route-for-system-dialog/add-edit-route-for-system-dialog.component';
import { DataPointListComponent } from './components/data-point-list/data-point-list.component';
import { SettingsTreeComponent } from './components/settings-tree/settings-tree.component';
import { TreeModule } from 'primeng/tree';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputSwitchModule } from 'primeng/inputswitch';



const testModeProviders = [
  {
    provide: AuthService,
    useClass: TestAuthService,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TestInterceptor,
    multi: true,
  },
];

@NgModule({
  declarations: [
    AppComponent,
    DeveloperUserPageComponent,
    DeveloperFacilityPageComponent,
    DeveloperInstallationsPageComponent,
    DeveloperCompanyPageComponent,
    ServicegroupPageComponent,
    AccessClaimsGroupPageComponent,
    OverviewPageComponent,
    FilterCompanyOnNamePipe,
    SortByNamePipe,
    SortByValuePipe,
    CreateInstallationFormComponent,
    CreateEditCompanyComponent,
    CreateEditFacilityComponent,
    SummaryPageComponent,
    DeveloperSupportPageComponent,
    CommissionComponent,
    DeveloperMenuComponent,
    SortByValuePipe,
    SortByNamePipe,
    FilterInstallationOnNameOrSerialPipe,
    FilterCompanyOnNamePipe,
    FilterByTabPipe,
    FilterCompaniesByServiceGroups,
    RootPageComponent,
    CreateEditIGridInstallationComponent,
    IGridInstallationPageComponent,
    SearchbarComponent,
    IGridInstallationConfigurationFormComponent,
    UserPageComponent,
    CreateEditUserComponent,
    UsersListComponent,
    IGridSummaryPageComponent,
    IGridInstallationModuleConfigurationFormComponent,
    CompanyPageComponent,
    FacilityPageComponent,
    ArchivePageComponent,
    EditIGridInstallationWizardComponent,
    SortBySerialnumberPipe,
    FacilityInstallationSearchListComponent,
    FacilityListComponent,
    GisSourcesListComponent,
    AddGisDataSourceDialogComponent,
    FormatBytesPipe,
    LatestGisImportPipe,
    LatestGisImportTimePipe,
    LatestGisImportStatusPipe,
    LatestGisImportStatusMessagePipe,
    IgridSettingsComponent,
    MultiDimensionalViewComponent,
    AddIgridSystemSettingDialogComponent,
    RandomColorPipe,
    CdkVirtualScrollViewportPatchDirective,
    IgridSystemDatapointListComponent,
    NoCommaPipe,
    AddEditIgridSystemDatapointDialogComponent,
    GenerateLinkFromDeviceIdPipe,
    IgridSystemRoutingsComponent,
    AddEditRouteForSystemDialogComponent,
    DataPointListComponent,
    SettingsTreeComponent,
  ],
  imports: [
    NgbModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    CdkStepperModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OverlayModule,
    SharedModule,
    NgxDropzoneModule,
    NgJsonEditorModule,
    PolymorpheusModule,
    NgOptimizedImage,
    TreeModule,
    PanelModule,
    ProgressSpinnerModule,
    InputSwitchModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    DatePipe,
    SortByNamePipe,
    SortByValuePipe,
    {
      provide: serverUrlToken,
      useValue: environment.serverUrl,
    },
    {
      provide: versionToken,
      useValue: version.version,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PathInterceptor,
      multi: true,
    },
    {
      provide: sentryConfig,
      useValue: {
        dsn: 'https://2dbae147752e4eb6b2d951d5710e1645@sentry.io/2850997',
        release: 'service-portal@' + version,
        enabled: environment.production,
      },
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },

    ...(environment.testMode ? testModeProviders : []),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
