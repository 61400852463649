import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { combineLatest, of, Subscription } from 'rxjs';
import { first, map, withLatestFrom } from 'rxjs/operators';
import { MODAL_DATA, MODAL_CONTROLLER, ModalController } from 'shared';
import { IDataPointDTO, IDataPointType, IIGridSystem } from '../../interfaces/iGridSystem';
import { IAddSystemSettingPayload, IUpdateSystemSettingPayload } from '../../interfaces/iGridSystemSetting';
import { StandardKeyService } from '../../services/standard-key.service';
import { DataPointService } from '../../services/data-point.service';

@Component({
  selector: 'app-add-edit-igrid-system-datapoint-dialog',
  templateUrl: './app-add-edit-igrid-system-datapoint-dialog.component.html',
  styleUrls: ['./app-add-edit-igrid-system-datapoint-dialog.component.scss'],
})
export class AddEditIgridSystemDatapointDialogComponent implements OnInit, OnDestroy {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      system: IIGridSystem;
      isSupportUser?: boolean;
      datapoint: IDataPointDTO;
      allDatapointsForSystem?: IDataPointDTO[];
      editable?: boolean;
    },
    @Inject(MODAL_CONTROLLER)
    private controller: ModalController<IAddSystemSettingPayload | IUpdateSystemSettingPayload>,
    private standardKeyService: StandardKeyService,
    private datapointService: DataPointService,
    private translateService: TranslateService,
    public fb: UntypedFormBuilder,
  ) {}

  public title$ = this.translateService
    .stream([
      'add-edit-igrid-system-datapoint.modify-datapoint',
      'igrid-installation-datapoints.' + this.data.datapoint?.standardKey?.key,
      'add-edit-igrid-system-datapoint.add-new-datapoint',
    ])
    .pipe(
      map(
        ({
          'add-edit-igrid-system-datapoint.modify-datapoint': editDatapointTitle,
          ['igrid-installation-datapoints.' + this.data.datapoint?.standardKey?.key]: datapointNameTranslated,
          'add-edit-igrid-system-datapoint.add-new-datapoint': addNewDatapointTitle,
        }: any) =>
          this.data.datapoint
            ? this.data.editable
              ? `${editDatapointTitle}: ` +
                (!!this.data.datapoint?.standardKey?.key
                  ? datapointNameTranslated + (this.data.datapoint?.customName ? `(${this.data.datapoint?.customName ?? ''})` : '')
                  : `${datapointNameTranslated} ${this.data.datapoint?.customName ? '(' + this.data.datapoint?.customName + ')' : ''}`)
              : datapointNameTranslated // readonly mode: only show name of dp
            : addNewDatapointTitle,
      ),
    );

  private subscriptions = new Subscription();

  public form = this.fb.group({
    id: null,
    dataKey: [null, Validators.required, this.forbiddenDataKeyValidator.bind(this)],
    customName: null,
    order: 0,
    datapointType: [null, Validators.required],
    standardKey: [null, Validators.required],
    visible: false,
  });

  public datapointTranslations$ = this.translateService.stream('igrid-installation-datapoints');
  public allDatapointTypes$ = this.datapointService.allDatapointTypes$;
  public allStandardKeys$ = this.standardKeyService.allStandardKeys$;

  public selectableDatapointTypes$ = this.allDatapointTypes$.pipe(
    map((dataPoints) =>
      sortBy(
        dataPoints?.map((dataPointType: IDataPointType) => ({
          name: dataPointType.unit,
          value: dataPointType,
        })),
        (s) => s.name,
      ),
    ),
  );

  public selectableStandardKeys$ = this.allStandardKeys$.pipe(
    withLatestFrom(this.datapointTranslations$),
    map(([sk, translations]) =>
      sortBy(
        sk.map((s) => ({ name: translations[s.key] ?? s.key, value: s })),
        (s) => s.name,
      ),
    ),
  );

  public ngOnInit(): void {
    if (this.data.datapoint) {
      combineLatest([this.selectableDatapointTypes$, this.selectableStandardKeys$])
        .pipe(first())
        .subscribe(([dataPointTypes, standardKeys]) => {
          this.form.patchValue({
            ...this.data.datapoint,
            datapointType: dataPointTypes.find((dpt) => dpt.value.id === this.data.datapoint.dataPointTypeId),
            standardKey: standardKeys.find((sk) => sk.value.id === this.data.datapoint.standardKeyId),
          });
        });
    }
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public save() {
    if (this.form.value) {
      const { standardKey, datapointType, ...formValues } = this.form.value;
      this.controller.complete({
        ...formValues,
        order: formValues.order ?? 0,
        datapointTypeId: datapointType.value.id,
        standardKeyId: standardKey.value.id,
      });
    }
  }

  public cancel() {
    this.controller.dismiss();
  }

  private forbiddenDataKeyValidator(control: AbstractControl): ValidationErrors | null {
    const forbiddenDataKey = this.data.allDatapointsForSystem?.some(
      (dp) => dp.dataKey === control.value && control.value !== this.data.datapoint?.dataKey,
    );
    return of(forbiddenDataKey ? { forbiddenDataKey: { value: control.value } } : null);
  }
}
