import { Component, OnInit, Input, forwardRef, OnChanges, OnDestroy, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

const noop = () => {};
export const ITEM_LIST_SELECTOR_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ItemListSelectorComponent),
  multi: true,
};

@Component({
  selector: 'gbc-item-list-selector',
  templateUrl: './item-list-selector.component.html',
  styleUrls: ['./item-list-selector.component.css'],
  providers: [ITEM_LIST_SELECTOR_CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class ItemListSelectorComponent implements OnInit, OnChanges, OnDestroy {
  // Can overwrite these if required
  @Input() idField = 'id';
  @Input() nameField = 'name';

  @Input() header: { key: string; title: string }[];
  @Input() public items: { id: string | number; name: string }[];

  @Input() public fallbackMessage = '';

  @Input() public clickable = false;

  @Output() public rowClick = new EventEmitter<string>();

  public form = new UntypedFormGroup({});

  private subscription = new Subscription();

  // NgModel stuff
  private innerValue: number[] = [];
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v === this.innerValue) {
      return;
    }

    this.innerValue = v;
    this.onChangeCallback(v);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  writeValue(value: any) {
    if (value === this.innerValue) {
      return;
    }

    this.innerValue = value;
    const newFormValue = this.items.reduce((acc, item) => {
      acc[item.id.toString()] = value.includes(item.id.toString());
      return acc;
    }, {});
    this.form.patchValue(newFormValue);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnInit(): void {
    const sub = this.form.valueChanges.subscribe((form) => {
      const value = [];
      for (const id in form) {
        if (form[id]) {
          value.push(id);
        }
      }

      this.value = value;
    });

    this.subscription.add(sub);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges() {
    if (!this.items || !this.items.length) {
      this.value = [];
    }
    // When the facilities update, we want to change which facilites we show and their values

    this.removeExistingFormControlsFromFacilities();
    this.addFormControlToFacilities(this.items);
  }

  removeExistingFormControlsFromFacilities() {
    const formGroup = this.form as UntypedFormGroup;
    for (const i in formGroup.controls) {
      if (!formGroup.controls[i]) {
        continue;
      }
      formGroup.removeControl(i);
    }
  }

  addFormControlToFacilities(items: { id: string | number; name: string }[]) {
    const form = this.form as UntypedFormGroup;
    for (const item of items) {
      const inList = this.value.includes(item.id.toString());

      form.addControl(item.id.toString(), new UntypedFormControl(inList));
    }
  }
}
