import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gbc-input-label',
  templateUrl: './input-label.component.html',
  styleUrls: ['./input-label.component.scss'],
})
export class InputLabelComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
