import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { InstallationsService } from '../../../services/installations.service';
import { IReducedInstallationDTO, IFullInstallationDTO, IPutInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';
import { ECommissionStatus } from 'shared';
import { EArchiveState } from '../../../interfaces/archiveState';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { inverseOfTranslation } from '../../../helpers/viewport.helpers';
import { debounceTime, distinctUntilChanged, filter, map, startWith, withLatestFrom } from 'rxjs/operators';
import { combineLatest } from 'rxjs';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}

@Component({
  selector: 'app-developer-installations-page',
  templateUrl: './developer-installations-page.component.html',
  styleUrls: ['./developer-installations-page.component.scss'],
})
export class DeveloperInstallationsPageComponent implements OnInit {
  uiState = UIState.edit;

  commissionStatus = Object.keys(ECommissionStatus);
  archiveStates = Object.keys(EArchiveState);

  installations$;
  selectedInstallationId;
  selectedSerialNumber;
  currentfilter: string;
  searchForm = new UntypedFormGroup({
    searchString: new UntypedFormControl(''),
  });
  createEditInstallationForm;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport;

  constructor(
    private installationService: InstallationsService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.installations$ = combineLatest([
      this.searchForm.controls.searchString.valueChanges.pipe(distinctUntilChanged(), startWith('')),
      installationService.installations$,
    ]).pipe(
      map(([searchString, installations]) => {
        const searchTerm = searchString?.toLowerCase() ?? '';
        return (
          installations.filter((i) =>
            [`${i.deviceId}`, `${i.serialNumber}`, `${i.name}`, `${i.commissionStatus}`, `${i.archiveState}`].some(
              (p) => p?.toLowerCase().includes(searchTerm),
            ),
          ) ?? []
        );
      }),
    );

    this.createEditInstallationForm = formBuilder.group({
      SerialNumber: ['', Validators.required],
      DeviceId: ['', Validators.required],
      CommissionStatus: ['', Validators.required],
      ArchiveState: ['', Validators.required],
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (this.selectedInstallationId === null) {
      // this.installationService.createInstallation(this.form.value);
    } else {
      const installation: IPutInstallationDTO = {
        id: this.selectedInstallationId,
        deviceId: this.createEditInstallationForm.get('DeviceId').value,
        archiveState: this.createEditInstallationForm.get('ArchiveState').value,
        commissionStatus: this.createEditInstallationForm.get('CommissionStatus').value,
      };
      this.installationService.patchInstallation(installation);
    }
    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.edit;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewInstallation() {
    this.selectedInstallationId = null;
    this.createEditInstallationForm.patchValue({
      DeviceId: '',
      Name: '',
    });
    this.uiState = UIState.create;
  }

  modifyInstallation(installation: IFullInstallationDTO) {
    this.uiState = UIState.modify;
    this.selectedInstallationId = installation.id;
    this.selectedSerialNumber = installation.serialNumber;
    this.createEditInstallationForm.patchValue({
      DeviceId: installation.deviceId,
      SerialNumber: installation.serialNumber,
      Name: installation.name,
      CommissionStatus: installation.commissionStatus,
      ArchiveState: installation.archiveState,
    });
  }

  deleteInstallation(installation: IFullInstallationDTO) {
    if (confirm('Confirm deletion of: ' + installation.id)) {
      this.installationService.deleteInstallation(installation);
    }
  }

  cancelNewInstallation() {
    this.uiState = UIState.edit;
  }

  public get inverseOfTranslation(): string {
    return inverseOfTranslation(this.viewPort);
  }
}
