import { Component, Input, OnInit, Output, EventEmitter, QueryList, ContentChildren } from '@angular/core';
import { PageInfo } from '../../interfaces/pageInfo';
import { TextButtonComponent } from '../text-button/text-button.component';

// Component for displaying the page header. Accepts a PageInfo input, which is
// created by he PageInfoService.
@Component({
  selector: 'gbc-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit {
  @Input()
  public pageInfo: PageInfo;

  public menuOpen = false;

  @ContentChildren(TextButtonComponent)
  public buttons: QueryList<TextButtonComponent>;

  constructor() {}

  ngOnInit(): void {}
}
