<form [formGroup]="form">
  <gbc-table *ngIf="items.length; else noFacilities" [clickableRows]="clickable">
    <th gbc-table-header style="width: 100px">
      {{ 'facility-selector.selected' | translate }}
    </th>

    <th gbc-table-header *ngFor="let headerEntry of header">
      {{ headerEntry.title }}
    </th>

    <tr gbc-table-row *ngFor="let item of items">
      <td gbc-table-cell>
        <gbc-checkbox [formControlName]="item.id.toString()" [name]="item.id"></gbc-checkbox>
      </td>
      <td *ngFor="let headerEntry of header" gbc-table-cell (click)="rowClick.emit(item.id)">
        {{ item[headerEntry.key] }}
      </td>
    </tr>
  </gbc-table>

  <ng-template #noFacilities>
    <gbc-table>
      <tr gbc-table-row>
        <td gbc-table-cell colspan="2">
          {{ fallbackMessage }}
        </td>
      </tr>
    </gbc-table>
  </ng-template>
</form>
