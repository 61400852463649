import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { IServiceGroup } from 'projects/serviceportal/src/app/interfaces/serviceGroup';

@Injectable({
  providedIn: 'root',
})
export class ServicegroupService {
  serviceGroups = new BehaviorSubject<IServiceGroup[]>([]);
  serviceGroupsChanged$ = this.serviceGroups.asObservable();

  constructor(private httpClient: HttpClient) {
    this.reloadServiceGroups();
  }

  public reloadServiceGroups() {
    this.getServiceGroups()
      .pipe(tap((serviceGroups) => this.serviceGroups.next(serviceGroups ? serviceGroups : [])))
      .subscribe();
  }

  public getServiceGroups(): Observable<IServiceGroup[]> {
    return this.httpClient.get<IServiceGroup[]>(`${environment.serverUrl}/api/servicegroups`);
  }

  public createServiceGroup(serviceGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(`${environment.serverUrl}/api/servicegroups`, serviceGroup).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public patchServiceGroup(serviceGroup: IServiceGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.patch(`${environment.serverUrl}/api/servicegroups/${serviceGroup.id}`, serviceGroup).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public deleteServiceGroup(serviceGroup: IServiceGroup): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.delete(`${environment.serverUrl}/api/servicegroups/${serviceGroup.id}`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }
}
