<gbc-page-error-handler [content]="content" [pageError$]="pageError$"> </gbc-page-error-handler>
<ng-template #content>
  <gbc-page-header [pageInfo]="pageInfo">
    <ng-container gbc-page-header-right>
      <button gbc-button [routerLink]="['/company/create']" *ngIf="showCreateCompanyButton$ | async">
        {{ 'overview-page.create-company' | translate }}
      </button>
      <button gbc-button [routerLink]="['/users/create']" *ngIf="showCreateUserButton$ | async">
        {{ 'overview-page.create-user' | translate }}
      </button>
      <button gbc-button [routerLink]="['/igrid/create']" *ngIf="showCreateIgridSystemButton$ | async">
        {{ 'facility-page.create-installation' | translate }}
      </button>
    </ng-container>
  </gbc-page-header>

  <gbc-tabs>
    <gbc-tab [title]="'overview-page.companies' | translate" name="companies">
      <gbc-page-container class="companies">
        <form [formGroup]="form">
          <app-searchbar formControlName="search" [placeholder]="'overview-page.search-company' | translate"></app-searchbar>
        </form>
        <gbc-tile [flexy]="true" [title]="'overview-page.companies' | translate">
          <gbc-table [stickyHeaders]="true" *ngIf="companiesFilteredList$ | async as companies" clickableRows="true">
            <th gbc-table-header>{{ 'overview-page.name' | translate }}</th>

            <tr gbc-table-row *ngFor="let company of companies" [routerLink]="['/company/view', company.id]">
              <td gbc-table-cell>{{ company.name }}</td>
            </tr>
          </gbc-table>
        </gbc-tile>
      </gbc-page-container>
    </gbc-tab>
    <gbc-tab [title]="'overview-page.users' | translate" name="users">
      <gbc-page-container>
        <app-users-list [users]="users$"></app-users-list>
      </gbc-page-container>
    </gbc-tab>

    <gbc-tab [title]="'overview-page.installations' | translate" name="installations">
      <gbc-page-container>
        <app-facility-installation-search-list></app-facility-installation-search-list>
      </gbc-page-container>
    </gbc-tab>
  </gbc-tabs>
</ng-template>
