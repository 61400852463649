import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import { inverseOfTranslation, vhToPixels } from '../../helpers/viewport.helpers';
import { IFacility } from '../../interfaces/facility';

@Component({
  selector: 'app-facility-list',
  templateUrl: './facility-list.component.html',
  styleUrls: ['./facility-list.component.scss'],
})
export class FacilityListComponent implements OnInit, OnDestroy {
  @Input()
  facilities: IFacility[];

  @ViewChild('virtualScrollport')
  public virtualScrollport?: CdkVirtualScrollViewport;
  public viewPortHeightInPx = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
  public virtualScrollPortBufferHeight = vhToPixels(this.viewPortHeightInPx);
  public get inverseOfTranslation(): string {
    return inverseOfTranslation(this.virtualScrollport);
  }
  protected subscriptions = new Subscription();
  private navigationEndEvents = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd && /view\/\d+;tab=facilities/.test(e.url)),
  );

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.subscriptions.add(this.navigationEndEvents.subscribe(() => window.dispatchEvent(new Event('resize'))));

    this.subscriptions.add(
      fromEvent(window, 'resize')
        .pipe(distinctUntilChanged(), debounceTime(10))
        .subscribe(() => {
          this.virtualScrollport?.checkViewportSize();
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
