import { Component, OnInit } from '@angular/core';
import { SupportService } from '../../../services/support.service';
import { CompanyService } from '../../../services/company.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { SortByValuePipe } from '../../../pipes/sort-by-value.pipe';
import { ServicegroupService } from '../../../services/servicegroup.service';
import { UserService } from '../../../services/user.service';
import { IUser, EAccessLevel, IPUTServiceUser, IPOSTServiceUser, IServiceGroup } from '../../../interfaces/user';
import { map, startWith, flatMap, withLatestFrom } from 'rxjs/operators';
import { EArchiveState } from '../../../interfaces/archiveState';
import { Observable, of } from 'rxjs';
import { ModalService } from 'shared';
import { TranslateService } from '@ngx-translate/core';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}

@Component({
  selector: 'app-developer-support-page',
  templateUrl: './developer-support-page.component.html',
  styleUrls: ['./developer-support-page.component.scss'],
})
export class DeveloperSupportPageComponent {
  error;
  uiState = UIState.edit;

  companies$;
  users$;
  serviceGroups$: Observable<IServiceGroup[]>;
  form;

  currentfilter;

  selectedUserId: string = null;

  constructor(
    private supportService: SupportService,
    public userService: UserService,
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    private sortByValuePipe: SortByValuePipe,
    private modalService: ModalService,
    private translate: TranslateService,
    private serviceGroupService: ServicegroupService,
  ) {
    this.companies$ = companyService.companies$;
    this.users$ = userService.usersWithCompany$.pipe(
      map((users) =>
        users.filter(
          (user) => user.accessLevel === EAccessLevel.GrundfosClaimAccessServiceGroup && user.archiveState === EArchiveState.Active,
        ),
      ),
    );
    this.serviceGroups$ = serviceGroupService.serviceGroupsChanged$;

    this.form = formBuilder.group({
      Name: [''],
      CompanyId: ['', Validators.required],
      Phone: [''],
      Email: ['', [Validators.required, Validators.email]],
      ServiceGroups: [[], Validators.required],
    });
  }

  createTrainingData() {
    this.error = 'Processing';
    this.supportService.createTrainingSet().then(
      (success) => {
        this.error = 'Success';
      },
      (error) => {
        this.error = JSON.stringify(error);
      },
    );
  }

  deleteTrainingData() {
    this.error = 'Processing';
    this.supportService.deleteTrainingSets().then(
      (success) => {
        this.error = 'Success';
      },
      (error) => {
        this.error = JSON.stringify(error);
      },
    );
  }

  private getFormAsUser(): IPOSTServiceUser {
    return {
      name: this.form.get('Name').value,
      companyId: this.form.get('CompanyId').value,
      phone: this.form.get('Phone').value,
      email: this.form.get('Email').value,
      serviceGroupIds: this.form.get('ServiceGroups').value.map((serviceGroup) => serviceGroup.id),
    };
  }

  onSubmit() {
    if (this.selectedUserId === null) {
      // new user
      this.userService.createServiceUser(this.getFormAsUser());
    } else {
      // editing existing user
      const user = this.getFormAsUser() as IPUTServiceUser;
      user.id = this.selectedUserId;
      this.userService.updateServiceUser(user);
    }

    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.list;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewUser() {
    this.uiState = UIState.create;
    this.selectedUserId = null;
    this.form.patchValue({
      Name: '',
      CompanyId: null,
      Phone: '',
      Email: '',
      ServiceGroups: '',
    });
  }

  deleteUser(user: IUser) {
    this.modalService
      .showConfirmModal(this.translate.instant('archive-page.remove-users'), this.translate.instant('archive-page.confirm-remove-users'))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.userService.archiveUser(user.id).subscribe((res) => {});
        }
      });
  }

  modifyUser(user: IUser) {
    this.uiState = UIState.modify;
    this.selectedUserId = user.id;

    this.form.patchValue({
      Name: user.name,
      Phone: user.phone,
      Email: user.email,
      CompanyId: user.companyId,
      ServiceGroups: user.serviceGroups,
    });
  }

  cancelNewUser() {
    this.uiState = UIState.edit;
  }
}
