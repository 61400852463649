import { InjectionToken } from '@angular/core';
import { CurrentUserService } from '../services/current-user.service';
import { Observable } from 'rxjs';
import { IUser } from '../interfaces/user';

export const initialUserToken = new InjectionToken('currentUser');
export const initialUserProvider = {
  provide: initialUserToken,
  useFactory: (currentUserService: CurrentUserService): Observable<IUser> => {
    return currentUserService.initialCurrentUser$;
  },
  deps: [CurrentUserService],
};
