<gbc-modal [title]="'gis-data-source-dialog.dialog-title' | translate">
  <gbc-modal-content>
    <form #gisSourceUploadForm [formGroup]="form">
      <gbc-input-container>
        <gbc-input-label>{{ 'app-name' | translate }}*</gbc-input-label>
        <gbc-input-field inputType="text" placeholderTranslationString="app-name" formControlName="description"> </gbc-input-field>
      </gbc-input-container>
      <gbc-input-container>
        <gbc-input-label>{{ 'gis-sources-list.geometry-class' | translate }}*</gbc-input-label>
        <gbc-dropdown formControlName="geometryClass" placeholderTranslationString="app-select" [dropDownItems]="geometryClasses$ | async">
        </gbc-dropdown>
      </gbc-input-container>
      <gbc-input-container>
        <gbc-input-label>{{ 'app-client' | translate }}*</gbc-input-label>
        <gbc-dropdown formControlName="company" placeholderTranslationString="app-select" [dropDownItems]="companies$ | async">
        </gbc-dropdown>
      </gbc-input-container>
      <gbc-input-container class="auto-index-on-close-row">
        <gbc-input-label>{{ 'gis-sources-list.auto-index-on-close' | translate }}</gbc-input-label>
        <gbc-toggle formControlName="autoIndexOnClose"></gbc-toggle>
      </gbc-input-container>
      <div class="form-group">
        <gbc-loading-spinner *ngIf="uploading$ | async; else dropzone" class="spinner"></gbc-loading-spinner>
        <ng-template #dropzone>
          <ngx-dropzone [multiple]="false" (change)="onFileSelect($event)">
            <ngx-dropzone-label class="dropzone-placeholder-label" *ngIf="!form.value.file">{{
              'gis-data-source-dialog.drop-file-or-click-to-upload' | translate
            }}</ngx-dropzone-label>
            <ngx-dropzone-preview class="file-preview" *ngIf="form.value.file as file" [removable]="true" (removed)="onFileRemove()">
              <ngx-dropzone-label
                >{{ file.name }}<br />
                <br />
                [{{ file.size | formatBytes }}]
              </ngx-dropzone-label>
            </ngx-dropzone-preview>
          </ngx-dropzone>
        </ng-template>

        <div *ngIf="form.errors" class="error-container alert alert-danger">
          <div *ngIf="form.errors?.invalidFileFormat">
            {{ 'gis-data-source-dialog.invalid-file-format' | translate }}
          </div>
          <div *ngIf="form.errors?.uploadFailed">
            {{ 'gis-data-source-dialog.upload-failed' | translate }}
          </div>
        </div>

        <gbc-input-container class="action-button-row">
          <button gbc-modal-button [disabled]="form.invalid" (click)="upload()" [ngClass]="{ primary: form.valid }">
            {{ 'app-ok' | translate }}
          </button>
        </gbc-input-container>
      </div>
    </form>
  </gbc-modal-content>
</gbc-modal>
