<form [formGroup]="form">
  <app-searchbar formControlName="search" [placeholder]="'users-list.search-user' | translate"></app-searchbar>
</form>
<gbc-tile [flexy]="true" [title]="'users-list.users' | translate" style="padding-top: 2em; margin-top: 0">
  <gbc-table [stickyHeaders]="true" *ngIf="usersList$ | async as users" clickableRows="true">
    <th gbc-table-header>{{ 'users-list.user-name' | translate }}</th>
    <th gbc-table-header>{{ 'users-list.email' | translate }}</th>
    <th gbc-table-header>{{ 'users-list.company' | translate }}</th>

    <tr gbc-table-row *ngFor="let user of users" [routerLink]="['/users', 'view', user.id]">
      <td gbc-table-cell>
        {{ user.name }}
      </td>
      <td gbc-table-cell>
        {{ user.email }}
      </td>
      <td gbc-table-cell>
        {{ user.company?.name }}
      </td>
    </tr>
  </gbc-table>
</gbc-tile>
