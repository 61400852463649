<div class="button-row">
  <form [formGroup]="searchForm">
    <app-searchbar id="installation-searchbar" formControlName="searchString" [placeholder]="'Search'"></app-searchbar>
  </form>
  <button (click)="createNewInstallation()">Create new Installation</button>
  <button *ngIf="uiState !== 'edit'" (click)="edit()">
    {{ uiState === 'modify' || uiState === 'create' ? 'Cancel' : 'Edit' }}
  </button>
</div>

<!-- List -->
<div class="list" *ngIf="uiState === 'list' || uiState === 'edit'">
  <cdk-virtual-scroll-viewport #scrollViewport [itemSize]="60">
    <table class="table table-striped">
      <thead>
        <tr>
          <th [style.top]="inverseOfTranslation" scope="col">#</th>
          <th [style.top]="inverseOfTranslation" scope="col" sortable="SerialNumber">SerialNumber</th>
          <th [style.top]="inverseOfTranslation" scope="col" sortable="InstallationId">DeviceId</th>
          <th *ngIf="uiState === 'edit'" [style.top]="inverseOfTranslation" scope="col">Modify</th>
          <th [style.top]="inverseOfTranslation" class="deleteColumn" *ngIf="uiState === 'edit'" scope="col">X</th>
        </tr>
      </thead>
      <tbody>
        <!-- | async -->
        <tr *cdkVirtualFor="let installation of installations$ | async">
          <th scope="row">{{ installation.id }}</th>
          <td>{{ installation.serialNumber }}</td>
          <td>{{ installation.deviceId }}</td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="modifyInstallation(installation)">Modify</button>
          </td>
          <td>
            <button class="deleteButton" *ngIf="uiState === 'edit'" (click)="deleteInstallation(installation)">X</button>
          </td>
        </tr>
      </tbody>
    </table>
  </cdk-virtual-scroll-viewport>
</div>
<!-- Create -->
<div class="formDiv" *ngIf="uiState === 'create' || uiState === 'modify'">
  <form class="newForm" [formGroup]="createEditInstallationForm" (ngSubmit)="onSubmit()">
    <gbc-input-container>
      <gbc-input-label>SerialNumber</gbc-input-label>
      <input
        class="formElement"
        readonly
        placeholder="Serial Number"
        type="text"
        formControlName="SerialNumber"
        style="margin-left: 31px"
      />
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>DeviceId</gbc-input-label>
      <input class="formElement" placeholder="Hardware ID" type="text" formControlName="DeviceId" style="margin-left: 31px" />
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label>CommissionStatus</gbc-input-label>
      <gbc-dropdown formControlName="CommissionStatus" placeholderTranslationString="app-select" [dropDownItems]="commissionStatus">
      </gbc-dropdown>
    </gbc-input-container>
    <gbc-input-container>
      <gbc-input-label> ArchiveState</gbc-input-label>
      <gbc-dropdown formControlName="ArchiveState" placeholderTranslationString="app-select" [dropDownItems]="archiveStates">
      </gbc-dropdown>
    </gbc-input-container>
    <button type="submit" [disabled]="!createEditInstallationForm.valid">Submit</button>
    <button type="reset" (click)="cancelNewInstallation()">Cancel</button>
  </form>
</div>
