import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable, of, combineLatest, BehaviorSubject, Subscription } from 'rxjs';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { CompanyService } from 'projects/serviceportal/src/app/services/company.service';
import { map, first, tap, shareReplay } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {
  EAccessLevel,
  IPOSTCompanyUser,
  IPOSTFacilityManager,
  IPUTCompanyUser,
  IPUTFacilityManager,
  IUser,
} from 'projects/serviceportal/src/app/interfaces/user';
import { FacilityService } from 'projects/serviceportal/src/app/services/facility.service';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { UserService } from 'projects/serviceportal/src/app/services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';

@Component({
  selector: 'app-create-edit-user',
  templateUrl: './create-edit-user.component.html',
  styleUrls: ['./create-edit-user.component.scss'],
})
export class CreateEditUserComponent implements AfterViewInit, OnDestroy {
  private user$: Observable<IUser>;
  public companies$: Observable<ICompany[]>;
  public pageError$: Observable<AppError>;
  public selectedCompanyName$: Observable<string>;
  public showFacilitySelector: Observable<boolean>;
  public facilitiesForCompany$: Observable<IFacility[]>;
  private subscription: Subscription = new Subscription();

  public id: string;

  public pageInfo: PageInfo;

  public roles: { name: string; id: string }[];

  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    company: new UntypedFormControl('', Validators.required),
    role: new UntypedFormControl('', Validators.required),
    facilities: new UntypedFormControl([]),
  });

  constructor(
    private router: Router,
    private companyService: CompanyService,
    private translate: TranslateService,
    private facilityService: FacilityService,
    private userService: UserService,
    private route: ActivatedRoute,
    private pageInfoService: PageInfoService,
    private errorService: AppErrorService,
  ) {
    const userId = this.route.snapshot.params.userId;

    this.user$ = userId ? this.userService.getUser(userId).pipe(shareReplay()) : of(null);

    this.companies$ = this.companyService.httpGetCompanies().pipe(shareReplay());

    this.pageError$ = this.errorService.createPageErrorObservable([this.user$, this.companies$]);

    this.roles = [
      {
        id: EAccessLevel.GrundfosClaimAccessCompany,
        name: this.translate.instant('roles.' + EAccessLevel.GrundfosClaimAccessCompany),
      },
      {
        id: EAccessLevel.GrundfosClaimAccessFacility,
        name: this.translate.instant('roles.' + EAccessLevel.GrundfosClaimAccessFacility),
      },
    ];

    this.selectedCompanyName$ = this.form.controls.company.valueChanges.pipe(map((company) => company?.name));

    this.showFacilitySelector = this.form.valueChanges.pipe(
      map((form) => {
        return !!(form.role?.id === EAccessLevel.GrundfosClaimAccessFacility && form.company);
      }),
    );
    const companySubject = new BehaviorSubject<ICompany>(null);
    const roleSubject = new BehaviorSubject(null);
    this.form.controls.company.valueChanges.subscribe(companySubject);
    this.form.controls.role.valueChanges.subscribe(roleSubject);
    this.facilitiesForCompany$ = combineLatest([companySubject, roleSubject, this.facilityService.facilities$]).pipe(
      map(([company, role, facilities]) => {
        if (role?.id !== EAccessLevel.GrundfosClaimAccessFacility) {
          return null;
        }
        return facilities.filter((facility) => facility.company?.id === company?.id);
      }),
    );

    // Set the pageInfo

    if (userId) {
      this.pageInfo = this.pageInfoService.editUser(userId);
    } else {
      this.pageInfo = this.pageInfoService.createUser();
    }
  }

  // Because of how the change detection for roles works, we need to patch the form values, after the content has been initialised
  // Then we can corretcly use the valueChanges observables above in the constructor.
  ngAfterViewInit() {
    // Set the values of the form for
    if (this.route.snapshot.params.userId) {
      this.id = this.route.snapshot.params.userId;
      this.subscription.add(
        combineLatest([this.user$, this.companies$])
          .pipe(
            first(),
            tap(([user, companies]) => {
              const role = this.roles.find((r) => r.id === user.accessLevel);
              const company = companies.find((c) => c.id === user.companyId);
              this.form.patchValue({
                name: user.name,
                email: user.email,
                role,
                company,
                facilities: user.facilityIDs.map((id) => id.toString()),
              });
            }),
          )
          .subscribe(),
      );
    }

    // Set the company, if we get it as part of the query param
    if (this.route.snapshot.queryParams.companyId) {
      this.subscription.add(
        this.companies$
          .pipe(
            first(),
            tap((companies) => {
              const company = companies.find((c) => c.id === Number(this.route.snapshot.queryParams.companyId));
              if (company) {
                this.form.patchValue({ company });
              }
            }),
          )
          .subscribe(),
      );
    }
  }

  submit() {
    const role = this.form.value.role.id;

    if (this.id) {
      switch (role) {
        case EAccessLevel.GrundfosClaimAccessCompany:
          this.updateCompanyUser();
          break;
        case EAccessLevel.GrundfosClaimAccessFacility:
          this.updateFacilityManager();
          break;
        default:
          throw new Error(`Not handling updating of user with role: ${role}`);
      }
    } else {
      switch (role) {
        case EAccessLevel.GrundfosClaimAccessCompany:
          this.createCompanyUser();
          break;
        case EAccessLevel.GrundfosClaimAccessFacility:
          this.createFacilityManager();
          break;
        default:
          throw new Error(`Not handling creation of user with role: ${role}`);
      }
    }
  }

  createCompanyUser() {
    const user: IPOSTCompanyUser = {
      name: this.form.value.name,
      companyId: this.form.value.company.id,
      email: this.form.value.email,
      phone: '',
    };

    this.userService.createCompanyAdminUser(user).subscribe(
      (userResult) => {
        this.router.navigate(['/users', 'view', userResult.id]);
      },
      () => {
        // In case of error, we don't want to do anything special, we allow the user to edit the user info and try again
      },
    );
  }

  createFacilityManager() {
    const user: IPOSTFacilityManager = {
      name: this.form.value.name,
      companyId: this.form.value.company.id,
      email: this.form.value.email,
      phone: '',
      facilityIDs: this.form.value.facilities.map((f) => Number(f)),
    };

    this.userService.createFacilityManagerUser(user).subscribe(
      (userResult) => {
        this.router.navigate(['/users', 'view', userResult.id]);
      },
      () => {
        // In case of error, we don't want to do anything special, we allow the user to edit the user info and try again
      },
    );
  }

  updateCompanyUser() {
    const user: IPUTCompanyUser = {
      id: this.id,
      name: this.form.value.name,
      companyId: this.form.value.company.id,
      email: this.form.value.email,
      phone: '',
    };

    this.userService.updateCompanyAdminUser(user).subscribe((userResult) => {
      this.router.navigate(['/users', 'view', userResult.id]);
    });
  }

  updateFacilityManager() {
    const user: IPUTFacilityManager = {
      id: this.id,
      name: this.form.value.name,
      companyId: this.form.value.company.id,
      email: this.form.value.email,
      phone: '',
      facilityIDs: this.form.value.facilities.map((f) => Number(f)),
    };

    this.userService.updateFacilityManagerUser(user).subscribe((userResult) => {
      this.router.navigate(['/users', 'view', userResult.id]);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
