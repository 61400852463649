import { Component, Inject, OnInit } from '@angular/core';
import { initialUserProvider, initialUserToken } from '../../providers/initial-user';
import { AppError, AppErrorService, AuthService } from 'shared';
import { catchError, map } from 'rxjs/operators';
import { CurrentUserService } from '../../services/current-user.service';
import { Router } from '@angular/router';
import { IUser } from '../../interfaces/user';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-root-page',
  templateUrl: './root-page.component.html',
  styleUrls: ['./root-page.component.scss'],
  providers: [initialUserProvider],
})
export class RootPageComponent implements OnInit {
  currentUser$: Observable<IUser>;
  pageError$: Observable<AppError>;
  showDeveloperUI$ = this.currentUserService.currentUser$.pipe(
    map((user) => user.accessClaimsGroupNames.some((acgn) => acgn === 'Admin')),
    catchError(() => of(false)),
  );
  showArchiveButton$: Observable<boolean>;
  showSupportButton$: Observable<boolean>;
  showCommissionButton$: Observable<boolean>;

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private authService: AuthService,
    private errorService: AppErrorService,
    @Inject(initialUserToken) initialUser$: Observable<IUser>,
  ) {
    this.pageError$ = this.errorService.createPageErrorObservable([initialUser$]);
    this.currentUser$ = this.currentUserService.currentUser$;

    this.showCommissionButton$ = this.currentUserService.hasClaim('Commission');
    this.showSupportButton$ = this.currentUserService.hasClaim('Support');
    this.showArchiveButton$ = this.currentUserService.hasClaim('Support');
  }
  ngOnInit(): void {}

  navigateToRoot() {
    this.router.navigate(['']);
  }

  logout() {
    this.authService.signout();
  }
}
