import { ICompany } from './company';
import { ILocation } from 'shared';
import { IReducedInstallationDTO } from './installation';
import { EArchiveState } from './archiveState';
import { IIGridSystem } from './iGridSystem';

export enum EFacilityType {
  BuildingConnect = 'BuildingConnect',
  MIXIT = 'MIXIT',
  iGrid = 'iGrid',
  ConnectingCore = 'ConnectingCore',
}

export interface IPutPatchFacility extends Omit<IFacility, 'archiveState'> {}

export interface IFacility {
  id?: number;
  name: string;
  facilityType: EFacilityType;
  location: ILocation;
  address?: string;
  addressRoad: string;
  addressPostal: string;
  addressCity: string;
  company: ICompany;
  installations?: (IReducedInstallationDTO | IIGridSystem)[];
  archiveState: EArchiveState;
}

export interface IIGridFacilityDTO {
  alarmsCount: number;
  companyArchiveState: string;
  companyId: number;
  companyName: string;
  companyOwnerName: string;
  companyOwnerEmail: string;
  companyOwnerPhone: string;
  facilityAddressCity: string;
  facilityAddressPostal: string;
  facilityAddressRoad: string;
  facilityId: number;
  facilityLocationLatitude: number;
  facilityLocationLongitude: number;
  facilityName: string;
  systems: IIGridSystem[];
  systemsCount: number;
  warningsCount: number;
}
