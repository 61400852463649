import { Component, Inject, Input } from '@angular/core';
import { MODAL_CONTROLLER, ModalController } from '../../interfaces/modal';

@Component({
  selector: 'gbc-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent {
  @Input()
  public title: string;

  dismiss() {
    this.controller.dismiss();
  }

  constructor(@Inject(MODAL_CONTROLLER) private controller: ModalController<any>) {}
}
