<gbc-tile *ngIf="warning" [title]="'💥⚠️' + (warning.titleTranslationId | translate) + '⚠️💥'" style="margin-top: 2em">
  <p class="missing-in-dis-text">{{ warning.textTranslationId | translate }}</p>
</gbc-tile>

<gbc-tile *ngIf="installation" [title]="'app-installation-information' | translate" style="margin-top: 2em">
  <gbc-table>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'igrid-installation-configuration-form.serial-number' | translate }}
      </td>
      <td gbc-table-cell>
        {{ installation?.serialNumber }}
      </td>
    </tr>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'igrid-installation-configuration-form.hardware-id' | translate }}
      </td>
      <td gbc-table-cell>
        {{ installation?.deviceId }}
      </td>
    </tr>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'igrid-installation-configuration-form.device-group' | translate }}
      </td>
      <td gbc-table-cell>
        {{ installation?.deviceGroup }}
      </td>
    </tr>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'igrid-installation-configuration-form.installation-type' | translate }}
      </td>
      <td gbc-table-cell>
        {{ installation.systemTypeDescription + (installation?.systemTypeVariant ? ' (' + installation?.systemTypeVariant + ')' : '') }}
      </td>
    </tr>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'create-edit-igrid-installation.configuration-keys.commission-status' | translate }}
      </td>
      <td gbc-table-cell>
        {{ installation?.commissionStatus }}
      </td>
    </tr>
  </gbc-table>
</gbc-tile>

<gbc-tile [title]="'app-company-information' | translate" style="margin-top: 2em">
  <gbc-table>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'app-client-name' | translate }}
      </td>
      <td gbc-table-cell>
        {{ company?.name }}
      </td>
    </tr>

    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'app-company-contact-person' | translate }}
      </td>
      <td gbc-table-cell>
        {{ company?.contactName }}
      </td>
    </tr>

    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'app-project-customer-phone' | translate }}
      </td>
      <td gbc-table-cell>
        {{ company?.contactPhone }}
      </td>
    </tr>

    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'app-project-customer-email' | translate }}
      </td>
      <td gbc-table-cell>
        {{ company?.contactEmail }}
      </td>
    </tr>
  </gbc-table>
</gbc-tile>

<gbc-tile [title]="'app-facility-information' | translate" style="margin-top: 2em">
  <gbc-table>
    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'app-facility-name' | translate }}
      </td>
      <td gbc-table-cell>
        {{ facility?.name }}
      </td>
    </tr>

    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'summary-page.customer-address' | translate }}
      </td>
      <td gbc-table-cell>
        {{ facility?.addressRoad }}
      </td>
    </tr>

    <tr gbc-table-row>
      <td gbc-table-cell class="title">
        {{ 'summary-page.postal-code' | translate }}
      </td>
      <td gbc-table-cell>
        {{ facility?.addressPostal }}
      </td>
    </tr>
  </gbc-table>
</gbc-tile>

<div *ngIf="systemState">
  <app-data-point-list [dataPoints]="systemState.state.system.datapoints"></app-data-point-list>
</div>

<div *ngIf="systemState">
  <app-settings-tree [settings]='settingsData'></app-settings-tree>
</div>


<gbc-tile *ngIf="routings" [title]="'app-routings' | translate" id="system-routings" style="margin-top: 2em">
  <app-igrid-system-routings
    [routings]="routings"
    [navigateRoutings]="navigateRoutings"
    [installation]="installation"
  ></app-igrid-system-routings>
</gbc-tile>
