import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { shareReplay, tap } from 'rxjs/operators';
import {
  IFullInstallationDTO,
  IPostSystemDTO,
  IPutInstallationDTO,
  IReducedInstallationDTO,
  IUpdateSystemDTO,
} from 'projects/serviceportal/src/app/interfaces/installation';
import { AppErrorService, CommandService, createRefreshSubject } from 'shared';
import { CurrentUserService } from './current-user.service';
import { ICommandOptions } from '../../../../shared/src/lib/interfaces/command';
import { IIGridInstallationType } from '../interfaces/iGridInstallationConfiguration';
import { ISettingType } from '../interfaces/iGridSystem';
import { IAddSystemSettingPayload, IUpdateSystemSettingPayload } from '../interfaces/iGridSystemSetting';

const INSTALLATION_ERROR_CODES = {
  // There are currently no installation error codes
};

@Injectable({
  providedIn: 'root',
})
export class InstallationsService {
  installations$: Observable<IReducedInstallationDTO[]>;
  public reloadInstallations: () => Observable<IReducedInstallationDTO[]>;

  constructor(
    private httpClient: HttpClient,
    private commandService: CommandService,
    private appErrorService: AppErrorService,
    private currentUserService: CurrentUserService,
  ) {
    const { subject, scheduleRefresh } = createRefreshSubject(
      () => this.httpClient.get<IReducedInstallationDTO[]>(`${environment.serverUrl}/api/installations`),
      this.currentUserService.initialCurrentUser$,
    );
    this.installations$ = subject;
    this.reloadInstallations = scheduleRefresh;
  }

  public getArchivedInstallations() {
    return this.httpClient.get<IReducedInstallationDTO[]>(`${environment.serverUrl}/api/installations/archived`);
  }

  private executeWithReload<R>(commandFn: () => Observable<R>, options: ICommandOptions) {
    return this.commandService.execute((): Observable<R> => this.withReload(commandFn), options);
  }

  private withReload<R>(fn: () => Observable<R>) {
    const result = fn().pipe(
      tap(() => this.reloadInstallations()),
      shareReplay(),
    );
    result.subscribe(
      () => {},
      () => {},
    );
    return result;
  }

  public createIGridInstallation(facilityId: string | number, postSystemDto: IPostSystemDTO): Observable<IFullInstallationDTO> {
    return this.executeWithReload(
      () =>
        from(
          (async () => {
            return await this.httpClient
              .post<IFullInstallationDTO>(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system`, postSystemDto)
              .toPromise();
          })(),
        ).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'installation-service.create-installation-error',
            errorCodes: INSTALLATION_ERROR_CODES,
          }),
        ),
      { successMessageKey: 'installation-service.installation-created' },
    );
  }

  public updateIGridInstallation(updateSystemDTO: IUpdateSystemDTO): Observable<any> {
    return this.executeWithReload(
      () =>
        from(
          (async () => {
            await this.httpClient
              .put<any>(
                `${environment.serverUrl}/api/igrid/facility/${updateSystemDTO.facilityId}/system/${updateSystemDTO.systemId}/update`,
                {
                  systemTypeId: updateSystemDTO.systemTypeId,
                  companyId: updateSystemDTO.companyId,
                  dataKeysAndActions: updateSystemDTO.dataKeysAndActions ?? [],
                  settingPathsAndActions: updateSystemDTO.settingPathsAndActions ?? [],
                },
              )
              .toPromise();
            return { id: updateSystemDTO.systemId };
          })(),
        ).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'installation-service.update-installation-error',
            errorCodes: INSTALLATION_ERROR_CODES,
          }),
        ),
      { successMessageKey: 'installation-service.installation-updated' },
    );
  }

  public getIGridInstallationTypes(): Observable<IIGridInstallationType[]> {
    return this.httpClient.get<IIGridInstallationType[]>(`${environment.serverUrl}/api/igrid/system/type`);
  }

  public patchInstallation(installation: IPutInstallationDTO): Observable<IFullInstallationDTO> {
    return this.withReload(() =>
      this.httpClient.patch<IFullInstallationDTO>(`${environment.serverUrl}/api/installations/${installation.id}`, installation).pipe(
        this.appErrorService.catchApiError({
          fallbackMessageKey: 'installation-service.update-installation-error',
          errorCodes: INSTALLATION_ERROR_CODES,
        }),
      ),
    );
  }

  public deleteInstallation(installation: IFullInstallationDTO | IReducedInstallationDTO): Observable<unknown> {
    return this.executeWithReload(
      () => this.httpClient.delete(`${environment.serverUrl}/api/igrid/system/installations/${installation.id}`),
      {},
    );
  }

  public deployLatestSchematic(installationId: string, version: string): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/schematics/deploy/${installationId}/${version}`, null).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: '',
            errorCodes: {
              'device-not-found': {
                messageKey: 'installation-service.config-failed-device-unknown',
              },
              'device-update-failed': {
                messageKey: 'installation-service.config-failed',
              },
            },
          }),
        );
        return result;
      },
      { successMessageKey: 'installation-service.config-deployed' },
    );
  }

  public archive(installationId: string): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient.put(`${environment.serverUrl}/api/installations/archiveInstallation/${installationId}`, {}).pipe(
          this.appErrorService.catchApiError({
            fallbackMessageKey: 'installation-service.archive-failed',
            errorCodes: {
              403: {
                messageKey: 'installation-service.archive-rights',
              },
            },
          }),
        );
        result.subscribe(
          () => this.reloadInstallations(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'installation-service.archive-success' },
    );
  }

  public archiveForDeletion(installationIds: string[]): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient
          .put(`${environment.serverUrl}/api/installations/archiveInstallationsForDeletion`, installationIds)
          .pipe(
            this.appErrorService.catchApiError({
              fallbackMessageKey: 'installation-service.archive-for-deletion-failed',
              errorCodes: {
                403: {
                  messageKey: 'installation-service.archive-for-deletion-rights',
                },
              },
            }),
          );
        result.subscribe(
          () => this.reloadInstallations(),
          (error) => undefined,
        );
        return result;
      },
      {
        successMessageKey: 'installation-service.archive-for-deletion-success',
      },
    );
  }

  public archiveIGridInstallation(facilityId: number, installationId: number): Observable<unknown> {
    return this.commandService.execute(
      () => {
        const result = this.httpClient
          .post(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${installationId}/archive`, {})
          .pipe(
            this.appErrorService.catchApiError({
              fallbackMessageKey: 'installation-service.archive-failed',
              errorCodes: {
                403: {
                  messageKey: 'installation-service.archive-rights',
                },
              },
            }),
          );
        result.subscribe(
          () => this.reloadInstallations(),
          (error) => undefined,
        );
        return result;
      },
      { successMessageKey: 'installation-service.archive-success' },
    );
  }

  public deleteIGridSystemSetting(facilityId: number, installationId: number, settingId: number): Observable<unknown> {
    return this.commandService.execute(
      () =>
        this.httpClient
          .delete(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${installationId}/settings/${settingId}`)
          .pipe(
            this.appErrorService.catchApiError({
              fallbackMessageKey: 'installation-service.archive-setting-failed',
              errorCodes: {
                403: {
                  messageKey: 'installation-service.archive-setting-rights',
                },
              },
            }),
            tap(() => this.reloadInstallations()),
          ),
      { successMessageKey: 'installation-service.archive-setting-success' },
    );
  }

  public addSettingToIGridSystem(facilityId: number, installationId: number, payload: IAddSystemSettingPayload): Observable<unknown> {
    return this.commandService.execute(
      () =>
        this.httpClient
          .post(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${installationId}/settings`, { ...payload })
          .pipe(
            this.appErrorService.catchApiError({
              fallbackMessageKey: 'installation-service.add-setting-failed',
              errorCodes: {
                403: {
                  messageKey: 'installation-service.add-settting-rights',
                },
              },
            }),
            tap(() => this.reloadInstallations()),
          ),
      { successMessageKey: 'installation-service.add-setting-success' },
    );
  }

  public updateIGridSystemSetting(
    facilityId: number,
    installationId: number,
    { id, order, customName, settingTypeId }: IUpdateSystemSettingPayload & { id: number },
  ): Observable<unknown> {
    return this.commandService.execute(
      () =>
        this.httpClient
          .put(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${installationId}/settings/${id}/meta`, {
            order: +order,
            customName: customName ?? null,
            settingTypeId: +settingTypeId,
          })
          .pipe(
            this.appErrorService.catchApiError({
              fallbackMessageKey: 'installation-service.update-setting-failed',
              errorCodes: {
                403: {
                  messageKey: 'installation-service.update-settting-rights',
                },
              },
            }),
            tap(() => this.reloadInstallations()),
          ),
      { successMessageKey: 'installation-service.update-setting-success' },
    );
  }

  public getIGridSystemSettingTypes(): Observable<ISettingType[]> {
    return this.httpClient.get<ISettingType[]>(`${environment.serverUrl}/api/igrid/setting-type`).pipe(
      this.appErrorService.catchApiError({
        fallbackMessageKey: '',
        errorCodes: {
          403: {
            messageKey: '',
          },
        },
      }),
      tap(() => this.reloadInstallations()),
    );
  }
}
