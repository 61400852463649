<form [formGroup]="form">
  <app-searchbar
    formControlName="installationSearch"
    [placeholder]="'facility-installation-search-list.search-term-description' | translate"
  ></app-searchbar>
</form>
<gbc-tile [flexy]="true" [title]="'facility-installation-search-list.installations' | translate" style="padding-top: 2em; margin-top: 0">
  <ng-container *ngIf="filteredSystems$ | async as filteredSystems; else loadingSpinner">
    <cdk-virtual-scroll-viewport
      #virtualScrollport
      [minBufferPx]="virtualScrollPortBufferHeight"
      [maxBufferPx]="virtualScrollPortBufferHeight"
      [itemSize]="60"
      *ngIf="(filteredSystems?.length || 0) > 0; else noSystems"
      class="virtual-viewport"
    >
      <gbc-table
        [stickyHeaders]="true"
        [suppressAutoAddChevronHeader]="true"
        [clickableRows]="true"
        *ngIf="inverseOfTranslation as topPosition"
      >
        <th [style.top]="topPosition" gbc-table-header>
          {{ 'facility-installation-search-list.serial' | translate }}
        </th>
        <th [style.top]="topPosition" gbc-table-header>
          {{ 'facility-installation-search-list.hardwareId' | translate }}
        </th>
        <th [style.top]="topPosition" gbc-table-header>
          {{ 'facility-installation-search-list.address' | translate }}
        </th>
        <th gbc-table-chevron-header [style.top]="topPosition"></th>
        <tr
          gbc-table-row
          *cdkVirtualFor="let system of filteredSystems"
          [routerLink]="[facilityTypeMap['iGrid'], system.facilityId, system.id]"
        >
          <td gbc-table-cell>
            {{ system.serialNumber }}
          </td>
          <td gbc-table-cell>
            {{ system.deviceId }}
          </td>
          <td gbc-table-cell>
            {{ system.facilityAddress }}
          </td>
        </tr>
      </gbc-table>
    </cdk-virtual-scroll-viewport>
  </ng-container>

  <ng-template #noSystems>
    <div class="no-systems-container">
      <h3>No systems...</h3>
      <h4>if you have applied a filter, try adjusting it.</h4>
    </div>
  </ng-template>

  <ng-template #loadingSpinner>
    <gbc-loading-spinner class="spinner no-shadow"></gbc-loading-spinner>
  </ng-template>
</gbc-tile>
