import { Component, OnInit, Input } from '@angular/core';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[gbc-modal-button]',
  templateUrl: './modal-button.component.html',
  styleUrls: ['./modal-button.component.scss'],
})
export class ModalButtonComponent implements OnInit {
  @Input() buttonType: 'primary' | 'danger' | 'default' = 'default';

  constructor() {}

  ngOnInit(): void {}
}
