<div class="base">
  <div class="input-container" [ngClass]="{ invalid: !valid, hasUnit: !!unit, disabled: disabled }">
    <input
      [min]="min"
      [max]="max"
      [step]="step"
      [disabled]="disabled"
      type="{{ inputType }}"
      [(ngModel)]="value"
      #filterInputField
      class="input"
      placeholder="{{ placeholderTranslationString | translate }}"
      (keyup)="onKey(filterInputField.value)"
      [maxlength]="maxlength"
      [attr.name]="formControlName || name"
    />
    <div class="unit" *ngIf="unit">
      {{ unit }}
    </div>
  </div>
  <div class="error-label" *ngIf="!valid">
    {{ errorDescriptionTranslationString | translate: errorDescriptionData }}
  </div>
</div>
