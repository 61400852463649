import { Component, OnInit, Inject } from '@angular/core';
import { LanguageService, languagesVisibleInFronted } from 'projects/shared/src/lib/services/language.service';
import { MODAL_CONTROLLER, ModalController } from 'projects/shared/src/lib/interfaces/modal';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Component({
  selector: 'gbc-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit {
  public currentLanguage$: Observable<string>;
  public languages$: Observable<{ name: string; languageCode: string }[]>;

  // These languages are hardcoded on purpose
  private allLanguages: { name: string; languageCode: string }[] = languagesVisibleInFronted;

  constructor(
    private languageService: LanguageService,
    @Inject(MODAL_CONTROLLER) private controller: ModalController<void>,
  ) {
    this.currentLanguage$ = this.languageService.currentLanguage;

    this.languages$ = this.languageService.languages$.pipe(
      first(),
      map((languages) => {
        return this.allLanguages.filter((a) => languages.includes(a.languageCode));
      }),
    );
  }

  ngOnInit(): void {}

  chooseLanguage(language: string) {
    this.languageService.setLanguageLocallyAndRemotely(language);
    this.controller.dismiss();
  }

  dismiss() {
    this.controller.dismiss();
  }
}
