import { Component, OnInit } from '@angular/core';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { PageInfo } from 'shared';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-create-edit-igrid-installation',
  templateUrl: './create-edit-igrid-installation.component.html',
  styleUrls: ['./create-edit-igrid-installation.component.scss'],
})
export class CreateEditIGridInstallationComponent implements OnInit {
  public initialCompanyId: string;
  public initialFacilityId: string;
  public installationId: string;

  constructor(
    private route: ActivatedRoute,
    private pageInfoService: PageInfoService,
  ) {}

  pageInfo: PageInfo;

  ngOnInit() {
    const [params, queryParams] = [this.route.snapshot.params, this.route.snapshot.queryParams];
    if (queryParams.companyId) {
      this.pageInfo = this.pageInfoService.createIGridInstallationPage(queryParams.companyId);
      this.initialCompanyId = queryParams.companyId;
    } else {
      this.pageInfo = this.pageInfoService.editIGridInstallationPage(params.facilityId, params.installationId);
    }
    if (queryParams.facilityId) {
      this.initialFacilityId = queryParams.facilityId;
    }
    if (params.installationId) {
      this.initialFacilityId = params.facilityId;
      this.installationId = params.installationId;
    }
  }
}
