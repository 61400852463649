import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gbc-input-container',
  templateUrl: './input-container.component.html',
  styleUrls: ['./input-container.component.scss'],
})
export class InputContainerComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
