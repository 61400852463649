import { Component, Input, OnInit, ContentChildren, QueryList } from '@angular/core';
import { TableHeaderComponent } from '../table-header/table-header.component';

@Component({
  selector: 'gbc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  @Input()
  public clickableRows: boolean;
  @Input()
  public selectableRows: boolean;
  @Input()
  public suppressAutoAddChevronHeader: boolean;
  @Input()
  public stickyHeaders: boolean;

  @ContentChildren(TableHeaderComponent)
  public headers: QueryList<TableHeaderComponent>;

  constructor() {}

  ngOnInit(): void {}
}
