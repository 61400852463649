<div class="base-container">
  <div class="overview-header-row">
    <div class="overview-header-row-inner">
      <div class="overview-title">Select Installation to Commission</div>
      <div class="overview-header-row-right-container">
        Filter:
        <gbc-input-field
          class="overview-header-row-right-container-filter"
          [placeholderTranslationString]="'app-project-name'"
          [(ngModel)]="currentfilter"
        >
        </gbc-input-field>
      </div>
    </div>
  </div>

  <div class="overview-tabs-row">
    <div class="overview-tabs-row-inner" *ngIf="activeRoute.queryParams | async as params">
      <div class="overview-tab" [ngClass]="{ selected: params.tab === 'missingId' }" (click)="selectTab('missingId')">
        Missing IOT-Hub deviceID
      </div>

      <div class="overview-tab" [ngClass]="{ selected: params.tab === 'pendingDevice' }" (click)="selectTab('pendingDevice')">
        Pending IOT-Hub device
      </div>

      <div class="overview-tab" [ngClass]="{ selected: params.tab === 'testing' }" (click)="selectTab('testing')">Undergoing tests</div>

      <div class="overview-tab" [ngClass]="{ selected: params.tab === 'tested' }" (click)="selectTab('tested')">Completed tests</div>

      <div class="overview-tab" [ngClass]="{ selected: params.tab === 'live' }" (click)="selectTab('live')">Live</div>
    </div>

    <div *ngIf="configVersions">
      Configuration versions C/CR/IO:<br />{{ configVersions.controlVersion }}/{{ configVersions.controlRuntimeVersion }}/{{
        configVersions.ioVersion
      }}
    </div>
    <div *ngIf="!configVersions">No configVersions</div>
  </div>
  <div *ngIf="(activeRoute.queryParams | async).tab as tab" class="installation-page-container">
    <div
      class="overview-table"
      *ngIf="
        installations$
          | async
          | sortByName
          | filterByTab: tabToCommissionStates(tab)
          | filterInstallationOnNameOrSerial: currentfilter as installations
      "
    >
      <div *ngIf="!installation">
        <!-- If no installations by filter -->
        <div *ngIf="!requestPending && installations.length === 0 && currentfilter" class="overview-table-primary-element">
          <div class="overview-table-no-filter-matches-title">
            {{ 'app-page-overview-button-filterproject-nomatches' | translate }}: '{{ currentfilter }}'
          </div>
        </div>

        <div *ngIf="requestPending && installations.length === 0" class="no-companies-card">Loading Content</div>
        <table class="table table-striped" *ngIf="!requestPending && installations.length !== 0">
          <thead>
            <tr>
              <th scope="col">Serial</th>
              <th scope="col" sortable="Active">Name</th>
              <th scope="col" sortable="AccessLevel">DeviceId</th>
              <th scope="col" sortable="AccessClaimsGroups">Version</th>
              <th scope="col">Config (C/CR/IO)</th>
            </tr>
          </thead>
          <tbody>
            <!-- | async -->
            <tr
              *ngFor="let installation of installations | sortBySerialnumber"
              class="commission-table-row-element"
              (click)="selectInstallation(installation, tab)"
            >
              <th scope="row">{{ installation.serialNumber }}</th>
              <td>{{ installation.name }}</td>
              <td>{{ installation.deviceId }}</td>
              <td>{{ installation.version }}</td>
              <table *ngIf="installation.configVersionSupportedControl">
                <tr>
                  <td
                    [ngClass]="
                      isVersionOk(configVersions.controlVersion, installation.configVersionSupportedControl) ? 'okVersion' : 'badVersion'
                    "
                  >
                    {{ installation.configVersionSupportedControl }}
                  </td>
                  <td
                    [ngClass]="
                      isVersionOk(configVersions.controlRuntimeVersion, installation.configVersionSupportedControlRuntime)
                        ? 'okVersion'
                        : 'badVersion'
                    "
                  >
                    {{ installation.configVersionSupportedControlRuntime }}
                  </td>
                  <td [ngClass]="isVersionOk(configVersions.ioVersion, installation.configVersionSupportedIo) ? 'okVersion' : 'badVersion'">
                    {{ installation.configVersionSupportedIo }}
                  </td>
                </tr>
              </table>
              <td *ngIf="!installation.configVersionSupportedControl"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Installation Selected -->
      <div *ngIf="installation">
        <div *ngIf="!updatingDeviceId && !requestPending">
          <div class="commission-table-primary-element">
            <div>Installation: {{ installation.id }}</div>
            <div>SoftwareVersion: {{ installation.version }}</div>
            <div>SerialNumber: {{ installation.serialNumber }}</div>
            <div>State: {{ installation.commissionStatus }}</div>
            <div>DeviceId: {{ installation.deviceId }}</div>
            <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="resetCommissionProgress()">
              Reset Commission Progress
            </button>
          </div>
          <!-- Set DeviceId -->
          <div *ngIf="tab === 'missingId'">
            Update Installation Device Id
            <div>
              <input
                [disabled]="updatingDeviceId"
                type="text"
                [formControl]="deviceIdControl"
                (keyup.enter)="setDeviceIDtoControlValue()"
              />
              <button [disabled]="updatingDeviceId" (click)="setDeviceIDtoControlValue()">Set DeviceId</button>
              <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
            </div>
            <button (click)="toggleShowSoftwareSelection()">Show Software Selection</button>
            <div *ngIf="showSoftwareSelection" class="softwareSelection">
              <ng-select
                class="select"
                [items]="softwareNames$ | async"
                [multiple]="false"
                placeholder="Select Software"
                [(ngModel)]="selectedSoftwarePackageName"
                (ngModelChange)="getSoftwareVersionsFromData()"
              >
              </ng-select>
              <ng-select
                *ngIf="selectedSoftwarePackageName"
                class="select"
                bindLabel="version"
                [items]="filteredSoftewarePackages"
                [multiple]="false"
                placeholder="Select Software"
                [(ngModel)]="selectedSoftwarePackage"
              >
              </ng-select>
            </div>
          </div>

          <!-- Check For Device  -->
          <div *ngIf="tab === 'pendingDevice'">
            Check for Connected device:
            <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="checkDeviceOnlineStatus()">Check</button>
            <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
          </div>

          <!-- Test Device -->
          <div *ngIf="tab === 'testing' || tab === 'tested' || tab === 'live'">
            <!-- if we have a connection status - only happens if device is disconnected -->
            <div *ngIf="deviceTwinStatus?.ConnectionStatus == 'Disconnected'">
              ConnectionStatus:
              {{ deviceTwinStatus.ConnectionStatus }}
              <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="getInstallationTwinStatus()">
                Check Device Connetion Status
              </button>
            </div>

            <!-- otherwise show -->
            <div *ngIf="deviceTwinStatus?.ConnectionStatus != 'Disconnected'">
              <!-- Show Self test status -->
              <div class="commission-table-primary-element">
                <div>
                  SelftestStatus:
                  {{ deviceTwinStatus?.Reported.selfTestStatus }}
                </div>
                <div>
                  selfTestErrorMessage:
                  {{ deviceTwinStatus?.Reported.selfTestErrorMessage }}
                </div>
              </div>
              <!-- if we dont have a selftest status or the status wasnt ok -->
              <div class="commission-table-primary-element">
                Check Device for SelfTest Status
                <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="getInstallationTwinStatus()">Get Status</button>
                <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
              </div>
              <!-- otherwise we have a device status and it was ok -->
              <div *ngIf="deviceTwinStatus" class="commission-table-primary-element">
                mbusScanning:
                {{ deviceTwinStatus?.Reported.mbusScanning }} mbusScanResult:
                {{ deviceTwinStatus?.Reported.mbusScanResult }}

                Start Test Cycle
                <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="testInstallation('scan_mbus')">scan_mbus</button>
                <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="testInstallation('scan_geni')">scan_geni</button>
                <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
              </div>
              <!-- allow approval of device -->
              <div *ngIf="deviceTwinStatus" class="commission-table-primary-element">
                Approve Device as tested
                <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="approveDeviceTests()">Approve</button>
                <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
              </div>
            </div>
          </div>
          <!-- Go Live?  -->
          <div *ngIf="tab === 'tested'">
            <div class="commission-table-primary-element">
              Move device to live
              <button [disabled]="requestPending" *ngIf="installation.deviceId" (click)="moveDeviceToLive()">Go Live!</button>
              <div *ngIf="checkResult">{{ checkResult.status }} {{ checkResult.statusText }}</div>
            </div>
          </div>
        </div>
        <div *ngIf="updatingDeviceId || requestPending">
          <p *ngIf="updatingDeviceId">Updating Device Id</p>
          <p *ngIf="requestPending">Checking for Device in IOTHUB</p>
        </div>
      </div>
    </div>
  </div>
</div>
