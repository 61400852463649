import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[gbc-button]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
