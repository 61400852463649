import { Component, ViewChild, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subscription, Observable } from 'rxjs';
import { JsonEditorOptions, JsonEditorComponent } from 'ang-jsoneditor';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-igrid-installation-module-configuration-form',
  templateUrl: './igrid-installation-module-configuration-form.component.html',
  styleUrls: ['./igrid-installation-module-configuration-form.component.scss'],
})
export class IGridInstallationModuleConfigurationFormComponent implements OnInit, OnDestroy {
  @Input() public modules: Observable<any[]>;
  public form: UntypedFormGroup;
  @ViewChild(JsonEditorComponent, { static: false })
  editor: JsonEditorComponent;

  private subscriptions = new Subscription();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.form = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.modules
        .pipe(
          tap((modules) => {
            return modules?.length && this.addFormControlForModules(modules);
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  makeOptions = (): JsonEditorOptions => {
    const opts = new JsonEditorOptions();
    opts.modes = ['tree'];
    return opts;
  };

  addFormControlForModules(modules: any[]) {
    for (const module of modules) {
      this.form.addControl(module.name, new UntypedFormControl());
      this.form.controls[module.name]?.setValue(module);
    }
  }
}
