<!-- Select facility and client/customer-->

<gbc-tile [title]="'app-project-client-and-facility' | translate">
  <form [formGroup]="form">
    <gbc-input-container>
      <gbc-input-label>{{ 'app-client' | translate }}*</gbc-input-label>
      <gbc-dropdown formControlName="company" placeholderTranslationString="app-select" [dropDownItems]="companies$ | async">
      </gbc-dropdown>
    </gbc-input-container>

    <gbc-input-container>
      <gbc-input-label>{{ 'app-project-facility' | translate }}*</gbc-input-label>
      <gbc-dropdown formControlName="facility" placeholderTranslationString="app-select" [dropDownItems]="facilities$ | async">
      </gbc-dropdown>
    </gbc-input-container>

    <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
  </form>
</gbc-tile>
