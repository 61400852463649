import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AppErrorService } from 'shared';
import { environment } from '../../environments/environment';
import { IDataPointDTO, IDataPointType } from '../interfaces/iGridSystem';

@Injectable({
  providedIn: 'root',
})
export class DataPointService {
  constructor(
    private httpClient: HttpClient,
    private appErrorService: AppErrorService,
  ) {
    this.refreshDatapoints();
  }

  public allDatapointTypes$ = new ReplaySubject<IDataPointType[]>(1);

  public getAllDatapointTypes() {
    return this.httpClient.get<IDataPointType[]>(`${environment.serverUrl}/api/igrid/datapointtypes`);
  }

  public getDatapointsForSystem(facilityId: number, systemId: number): Observable<IDataPointDTO[]> {
    return this.httpClient.get<IDataPointDTO[]>(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${systemId}/data-point`);
  }

  public refreshDatapoints() {
    this.getAllDatapointTypes()
      .pipe(first())
      .subscribe((dataPointTypes) => this.allDatapointTypes$.next(dataPointTypes));
  }

  public addDatapointForSystem(facilityId: number, systemId: number, dataPoint: Partial<IDataPointDTO>) {
    return this.httpClient
      .post<IDataPointDTO[]>(`${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${systemId}/data-point`, { ...dataPoint })
      .pipe(
        this.appErrorService.catchApiError({
          fallbackMessageKey: 'data-point-service.failed-to-create-datapoint',
        }),
      );
  }

  public updateDatapointForSystem(facilityId: number, systemId: number, dataPoint: Partial<IDataPointDTO>) {
    return this.httpClient.patch<IDataPointDTO[]>(
      `${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${systemId}/data-point/${dataPoint.id}`,
      {
        ...dataPoint,
      },
    );
  }

  public deleteDatapointForSystem(facilityId: number, systemId: number, dataPointId: number) {
    return this.httpClient.delete<IDataPointDTO[]>(
      `${environment.serverUrl}/api/igrid/facility/${facilityId}/system/${systemId}/data-point/${dataPointId}`,
    );
  }
}
