<ng-container *ngIf="isArray; else itemView">
  <app-multi-dimensional-view
    *ngFor="let item of value"
    [childPropName]="childPropName"
    [content]="content"
    [value]="item || value"
  >

  </app-multi-dimensional-view>
</ng-container>
<ng-template #itemView>
  <ng-container *polymorpheusOutlet="content as content; context: value">
    {{content}}
  </ng-container>
  <ng-container *ngIf="value[childPropName]">
    <app-multi-dimensional-view
      [childPropName]="childPropName"
      [content]="content"
      [value]="value[childPropName]"
    >
    </app-multi-dimensional-view>
  </ng-container>
</ng-template>
