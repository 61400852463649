import { ICompany } from './company';
import { EArchiveState } from 'projects/serviceportal/src/app/interfaces/archiveState';

export enum ERole {
  CompanyAdmin = 'CompanyAdmin',
  CompanyUser = 'CompanyUser',
  CompanyFacilityManager = 'CompanyFacilityManager',
  GFAdmin = 'GFAdmin',
  GFService = 'GFService',
  GFDeveloperAdmin = 'GFDeveloperAdmin',
  GFSupportTraining = 'GFSupportTraining',
}

export enum EAccessLevel {
  None = 'None',
  GrundfosClaimAccessAny = 'GrundfosClaimAccessAny',
  GrundfosClaimAccessServiceGroup = 'GrundfosClaimAccessServiceGroup',
  GrundfosClaimAccessCompany = 'GrundfosClaimAccessCompany',
  GrundfosClaimAccessFacility = 'GrundfosClaimAccessFacility',
}

// This is the standard user object, that is returned for all user requests
// Some of these properties are read only and can only be modified by using the correct user endpoint.
export interface IUser {
  id?: string;
  active: boolean;

  accessLevel: EAccessLevel;
  accessClaimsGroupNames: string[];

  name: string;
  companyId: number;
  company?: ICompany;
  phone: string;
  email: string;
  language: string;

  facilityIDs?: number[];
  serviceGroups?: IServiceGroup[];
  archiveState: EArchiveState;
  lastLifesignTimestampEpoch: number;
}

export type IPostUser = Omit<IUser, 'archiveState'>;

export interface IServiceGroup {
  id?: number;
  name: string;
}

export interface IPOSTServiceUser {
  name: string;
  companyId: number;
  phone: string;
  email: string;
  serviceGroupIds: number[];
}

export interface IPOSTCompanyUser {
  name: string;
  companyId: number;
  phone?: string;
  email: string;
}

export interface IPOSTFacilityManager {
  name: string;
  companyId: number;
  phone?: string;
  email: string;
  facilityIDs: number[];
}

export interface IPUTServiceUser extends IPOSTServiceUser {
  id: string;
}

export interface IPUTCompanyUser extends IPOSTCompanyUser {
  id: string;
}

export interface IPUTFacilityManager extends IPOSTFacilityManager {
  id: string;
}
