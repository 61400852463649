import { Pipe, PipeTransform } from '@angular/core';
import { IIGridSystem } from '../interfaces/iGridSystem';

@Pipe({
  name: 'generateLinkFromDeviceId',
})
export class GenerateLinkFromDeviceIdPipe implements PipeTransform {
  transform(deviceId: string, allSystems: IIGridSystem[]): IIGridSystem | undefined {
    return allSystems?.find((s) => s.deviceId === deviceId);
  }
}
