import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FacilityService } from '../../../services/facility.service';
import { InstallationsService } from '../../../services/installations.service';
import { CompanyService } from '../../../services/company.service';
import { FacilityTypeService } from '../../../services/facilitytype.service';
import { IFacility, IPutPatchFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { IFullInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';
import { ModalService } from 'shared';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { inverseOfTranslation } from '../../../helpers/viewport.helpers';
import { combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith } from 'rxjs/operators';

export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}

@Component({
  selector: 'app-developer-facility-page',
  templateUrl: './developer-facility-page.component.html',
  styleUrls: ['./developer-facility-page.component.scss'],
})
export class DeveloperFacilityPageComponent {
  uiState = UIState.edit;
  selectedFacilityId: number = null;
  selectedInstallationIds: string[];

  facilities$;
  installations$;
  companies$;
  facilityTypes;
  searchForm = new UntypedFormGroup({
    searchString: new UntypedFormControl(''),
  });

  form;

  @ViewChild(CdkVirtualScrollViewport, { static: false })
  public viewPort: CdkVirtualScrollViewport;

  constructor(
    private facilityService: FacilityService,
    private installationService: InstallationsService,
    private companyService: CompanyService,
    private facilityTypeService: FacilityTypeService,
    private formBuilder: UntypedFormBuilder,
    private modalService: ModalService,
  ) {
    this.facilityTypes = facilityTypeService.facilityTypes;
    this.installations$ = installationService.installations$;
    this.facilities$ = combineLatest([
      this.searchForm.controls.searchString.valueChanges.pipe(distinctUntilChanged(), startWith('')),
      facilityService.facilities$,
    ]).pipe(
      map(([searchString, facilities]) => {
        const searchTerm = searchString?.toLowerCase() ?? '';
        return (
          facilities
            .filter((i) =>
              [`${i.address}`, `${i.addressCity}`, `${i.addressPostal}`, `${i.addressRoad}`, `${i.company?.name ?? ''}`, `${i.name}`].some(
                (p) => p?.toLowerCase().includes(searchTerm),
              ),
            )
            .map((f) => ({
              ...f,
              address: `${f.addressRoad}, ${f.addressPostal} ${f.addressCity}`,
            })) ?? []
        );
      }),
    );
    this.companies$ = companyService.companies$;

    this.form = formBuilder.group({
      Name: ['', Validators.required],
      Company: ['', Validators.required],
      Location: formBuilder.group({
        Longitude: ['', Validators.required],
        Latitude: ['', Validators.required],
      }),
      AddressRoad: ['', Validators.required],
      AddressCity: ['', Validators.required],
      AddressPostal: ['', Validators.required],
      FacilityType: ['', Validators.required],
      InstallationSchematics: [''],
    });
  }

  onSubmit() {
    // New Facility
    if (this.selectedFacilityId === null) {
      this.facilityService.createFacility(this.form.value).subscribe(
        (res) => {
          if (this.selectedInstallationIds && this.selectedInstallationIds.length) {
            this.facilityService.updateFacilityInstallations(res.id, this.selectedInstallationIds);
          }
        },
        () => undefined,
      );
    } else {
      const facility: IPutPatchFacility = {
        id: this.selectedFacilityId,
        name: this.form.get('Name').value,
        company: this.form.get('Company').value,
        location: this.form.get('Location').value,
        addressRoad: this.form.get('AddressRoad').value,
        addressPostal: this.form.get('AddressCity').value,
        addressCity: this.form.get('AddressPostal').value,
        facilityType: this.form.get('FacilityType').value,
      };
      const installationSchematics = (this.form.get('InstallationSchematics').value as IFullInstallationDTO[]).map((iss) => iss.id);

      this.facilityService.patchFacility(facility).subscribe(
        (res) => {
          if (this.form.get('InstallationSchematics').dirty && installationSchematics && installationSchematics.length > 0) {
            this.facilityService.updateFacilityInstallations(this.selectedFacilityId, installationSchematics);
          }
        },
        () => undefined,
      );
    }

    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.edit;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewFacility() {
    this.uiState = UIState.create;
    this.selectedFacilityId = null;
    this.form.patchValue({
      Id: '',
      Name: '',
      Company: '',
      AddressRoad: '',
      AddressPostal: '',
      AddressCity: '',
    });
  }

  deleteFacility(facility: IFacility) {
    this.modalService.confirm('Delete Facility', 'Confirm deletion of: ' + facility.name, () =>
      this.facilityService.deleteFacility(facility.id),
    );
  }

  modifyFacility(facility: IFacility) {
    this.uiState = UIState.modify;
    this.selectedFacilityId = facility.id;
    this.form.patchValue({
      Id: facility.id,
      Name: facility.name,
      Company: facility.company,
      AddressRoad: facility.addressRoad,
      AddressPostal: facility.addressPostal,
      AddressCity: facility.addressCity,
      Location: {
        Longitude: facility.location ? facility.location.longitude : '',
        Latitude: facility.location ? facility.location.latitude : '',
      },
      FacilityType: facility.facilityType,
      InstallationSchematics: facility.installations,
    });
  }

  cancelNewFacility() {
    this.uiState = UIState.edit;
  }

  public get inverseOfTranslation(): string {
    return inverseOfTranslation(this.viewPort);
  }
}
