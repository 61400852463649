import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { MODAL_DATA, MODAL_CONTROLLER, ModalController } from 'shared';
import { IAddSystemSettingPayload, IUpdateSystemSettingPayload } from '../../interfaces/iGridSystemSetting';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { IGridDeviceRouteStrategy, IIGridDeviceRoute } from '../../interfaces/iGridDeviceRoute';

const NUM_AVAILABLE_ROUTENAMES = 30;
/**
 * There are harcoded for now as hopefully this will not change, but it would be great if they were returned
 * from the backend, so we can keep in sync.
 */
const CURRENT_ROUTE_STRATEGIES: IGridDeviceRouteStrategy[] = ["Forward", "MapToStandardKeys"]
@Component({
  selector: 'app-add-edit-route-for-system-dialog',
  templateUrl: './add-edit-route-for-system-dialog.component.html',
  styleUrls: ['./add-edit-route-for-system-dialog.component.scss'],
})
export class AddEditRouteForSystemDialogComponent implements OnInit {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      selectableSystems$: Observable<IIGridSystem[]>;
      system: IIGridSystem;
      route?: IIGridDeviceRoute;
      forbiddenRoutes: IIGridDeviceRoute[];
      enabled: boolean;
    },
    @Inject(MODAL_CONTROLLER)
    private controller: ModalController<IAddSystemSettingPayload | IUpdateSystemSettingPayload>,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
  ) {}

  public title$ = this.translateService
    .stream(['add-edit-igrid-system-routing.edit-routing', 'add-edit-igrid-system-routing.add-new-routing'])
    .pipe(
      map(
        ({
          'add-edit-igrid-system-routing.edit-routing': editDatapointTitle,
          'add-edit-igrid-system-routing.add-new-routing': addNewDatapointTitle,
        }: any) =>
          this.data.route
            ? `${editDatapointTitle}: ` +
              (this.data.route?.routingName ?? `${this.data.route.sourceDeviceId} → ${this.data.route.targetDeviceId}`)
            : addNewDatapointTitle,
      ),
    );

  public form = this.fb.group({
    sourceSystem: [null, Validators.required],
    routingName: null,
    description: null,
    strategy: ["MapToStandardKeys", Validators.required],
    enabled: true
  });

  public selectableRouteNames$: Observable<string[]> = of(
    Array.from(Array(NUM_AVAILABLE_ROUTENAMES).keys())
      .map((n) => `System_${n}`)
      .filter((n) => !this.data.forbiddenRoutes.some((r) => r.routingName === n)),
  );

  public selectableRoutingStrategy$: Observable<IGridDeviceRouteStrategy[]> = of(CURRENT_ROUTE_STRATEGIES);


  ngOnInit(): void {
    this.selectableRouteNames$.pipe(first()).subscribe((selectableRouteNames) => {
      const { route } = this.data;
      if (route) {
        this.form.patchValue({
          sourceSystem: {
            name: route.sourceDeviceId,
            value: { deviceId: route.sourceDeviceId },
          },
          routingName: selectableRouteNames.find((r) => r === route.routingName) ?? route.routingName,
          description: route.description,
          strategy: route.strategy,
          enabled: route.enabled
        });
      }
    });
  }

  public save() {
    if (this.form.value) {
      const { ...formValues } = this.form.value;
      this.controller.complete({
        ...formValues,
      });
    }
  }

  public cancel() {
    this.controller.dismiss();
  }
}
