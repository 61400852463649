<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <gbc-page-container>
      <gbc-tile [title]="(creating ? 'create-edit-facility.create' : 'create-edit-facility.edit') | translate">
        <form [formGroup]="form">
          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-facility.facility-name' | translate }}</gbc-input-label>
            <gbc-input-field formControlName="facilityName"></gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-facility.customer-address' | translate }}*</gbc-input-label>
            <gbc-input-field formControlName="address"></gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-facility.postal-code' | translate }}*</gbc-input-label>
            <gbc-input-field formControlName="postalCode"></gbc-input-field>
          </gbc-input-container>

          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-facility.city' | translate }}*</gbc-input-label>
            <gbc-input-field formControlName="city"></gbc-input-field>
          </gbc-input-container>

          <hr />

          <!-- GPS Information -->
          <div class="gps-coordinates-header">
            {{ 'create-edit-facility.gps-subtitle' | translate }}
          </div>
          <div class="gps-coordinates-description">
            <div>
              {{ 'create-edit-facility.gps-hint-1' | translate }}
              <a target="string_blank" href="{{ mapsUrl$ | async }}">Google Maps</a>
            </div>
            <div>{{ 'create-edit-facility.gps-hint-2' | translate }}</div>
            <div [innerHTML]="'create-edit-facility.gps-hint-3' | translate"></div>
          </div>
          <img class="gps-image" src="./assets/pngs/browser@2x.png" />
          <gbc-input-container>
            <div class="gps-coordinate-label gps-coordinate-label--lattitude">
              {{ 'create-edit-facility.latitude' | translate }}
            </div>
            <div class="gps-coordinate-label gps-coordinate-label--longitude">
              {{ 'create-edit-facility.longitude' | translate }}
            </div>
          </gbc-input-container>
          <div class="gps-input-container">
            <gbc-input-field
              inputType="number"
              class="gps-input-latitude"
              formControlName="latitude"
              min="-90"
              max="90"
              [valid]="form.controls.latitude.valid"
              errorDescriptionTranslationString="create-edit-facility.latitude-invalid"
            >
            </gbc-input-field>
            <gbc-input-field
              inputType="number"
              class="gps-input-longitude"
              formControlName="longitude"
              min="-180"
              max="180"
              [valid]="form.controls.longitude.valid"
              errorDescriptionTranslationString="create-edit-facility.longitude-invalid"
            >
            </gbc-input-field>
          </div>

          <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
        </form>
      </gbc-tile>

      <div class="footer-container">
        <div class="foot-container-left"></div>
        <div class="foot-container-right">
          <button gbc-button secondary large style="width: 280px" [disabled]="!form.valid" (click)="submit()">
            {{ (creating ? 'app-create' : 'app-update') | translate }}
          </button>
        </div>
      </div>
    </gbc-page-container>
  </gbc-command-spinner>
</ng-template>
