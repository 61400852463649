import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { IConfigVersions, IReducedInstallationDTO } from 'projects/serviceportal/src/app/interfaces/installation';

@Injectable({
  providedIn: 'root',
})
export class CommissionService {
  installations = new BehaviorSubject<IReducedInstallationDTO[]>([]);
  installationsChanged$ = this.installations.asObservable();

  softwareNamesChanged$ = new BehaviorSubject<string[]>([]);
  softwareData;
  public configVersions: IConfigVersions = null;

  constructor(private httpClient: HttpClient) {
    this.reloadInstallations();

    // Get the Software Update list
    this.GetDeviceSoftwareList().then((res) => {
      this.softwareNamesChanged$.next([...new Set(res.data.map((item) => item.name as string))] as string[]);
      this.softwareData = res.data;
    });
  }

  public reloadInstallations() {
    this.httpClient
      .get<IReducedInstallationDTO[]>(`${environment.serverUrl}/api/commission`)
      .pipe(tap((installations) => this.installations.next(installations ? installations : [])))
      .subscribe();
  }

  public setInstallationDeviceId(installationId: string, deviceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.serverUrl}/api/commission/${installationId}/deviceId`, { deviceId }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public updateDeviceSoftware(installationId: string, packageInfo: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.put(`${environment.serverUrl}/api/commission/${installationId}/DeviceSoftware`, packageInfo).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public GetDeviceSoftwareList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${environment.serverUrl}/api/commission/DeviceSoftware`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public GetConfigVersions3(): Observable<IConfigVersions> {
    return this.httpClient
      .get<IConfigVersions>(`${environment.serverUrl}/api/schematics/configversions`)
      .pipe(tap((data) => console.log('look at me: ' + JSON.stringify(data))));
  }

  public checkDeviceOnlineStatus(installationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${environment.serverUrl}/api/commission/${installationId}`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public getInstallationTwinStatus(installationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.get(`${environment.serverUrl}/api/commission/${installationId}/status`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public commandInstallation(installationId: string, command: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.serverUrl}/api/commission/${installationId}/command`, { command }).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public goliveInstallation(installationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.serverUrl}/api/commission/${installationId}/live`, '').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public approveDeviceTests(installationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.serverUrl}/api/commission/${installationId}/approvetests`, '').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public resetCommissionProgress(installationId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.httpClient.post(`${environment.serverUrl}/api/commission/${installationId}/reset`, '').subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        },
      );
    });
  }

  public getStatusIcon(action: string): string {
    let svgName: string = null;
    switch (action) {
      case 'NoChange':
        break;
      case 'Create':
        svgName = 'green-checked-circle.svg';
        break;
      case 'Ignore':
        svgName = 'warning-triangle_filled.svg';
        break;
      case 'Delete':
        svgName = 'remove-circle_filled.svg';
        break;
      case 'Hide':
        svgName = 'eye-off_outline.svg';
        break;
    }
    return svgName ? `./assets/svgs/${svgName.toLowerCase()}` : null;
  }
}
