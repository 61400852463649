<ng-select
  [ngStyle]="{ 'min-width': minWidth ? minWidth : undefined }"
  class="custom"
  [(ngModel)]="value"
  [items]="dropDownItems"
  bindLabel="name"
  [multiple]="false"
  placeholder="{{ placeholderTranslationString | translate }}"
  [disabled]="disabled"
  [virtualScroll]="virtualScroll || false"
>
</ng-select>
