<gbc-page-error-handler [pageError$]="pageError$"></gbc-page-error-handler>
<gbc-command-spinner>
  <gbc-stepper
    linear
    (selectionChange)="stepperChange($event)"
    (exit)="stepperExit()"
    [backDisabled]="backDisabled"
    [backLabel]="'app-back' | translate"
    [nextLabel]="'app-next' | translate"
    [doneLabel]="'app-done' | translate"
    (done)="completeWizard()"
  >
    <cdk-step [label]="'create-edit-igrid-installation.installation-data' | translate" [stepControl]="formComponent.form">
      <app-create-installation-form [facilityType]="'iGrid'" #formComponent></app-create-installation-form>
    </cdk-step>

    <cdk-step
      [label]="'create-edit-igrid-installation.installation-configuration' | translate"
      [stepControl]="configureInstallationFormComponent.form"
    >
      <app-igrid-installation-configuration-form
        [installation$]="installation$"
        [routings]="routings$ | async"
        #configureInstallationFormComponent
      ></app-igrid-installation-configuration-form>
    </cdk-step>
    <cdk-step [label]="'create-edit-igrid-installation.installation-configuration-summary' | translate">
      <app-igrid-summary-page
        *ngIf="formSummary$ | async as formSummary"
        [installation]="prepareInstallationObject(formSummary)"
        [navigateRoutings]="false"
        [facility]="formSummary.facility"
        [company]="formSummary.company"
        [configuration]="undefined"
        [systemState]="summarySystemState$ | async"
        [warning]="summaryWarning$ | async"
      >
      </app-igrid-summary-page>
    </cdk-step>
  </gbc-stepper>
</gbc-command-spinner>
