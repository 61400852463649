import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FacilityService } from '../../../services/facility.service';
import { CompanyService } from '../../../services/company.service';
import { IUser } from 'projects/serviceportal/src/app/interfaces/user';
import { SortByValuePipe } from '../../../pipes/sort-by-value.pipe';
import { AccessclaimsgroupsService } from '../../../services/accessclaimsgroups.service';
import { ServicegroupService } from '../../../services/servicegroup.service';
export enum UIState {
  list = 'list',
  create = 'create',
  edit = 'edit',
  modify = 'modify',
}
@Component({
  selector: 'app-developer-user-page',
  templateUrl: './developer-user-page.component.html',
  styleUrls: ['./developer-user-page.component.scss'],
})
export class DeveloperUserPageComponent implements OnInit {
  uiState = UIState.edit;

  companies$;
  facilities$;
  users$;
  userAccessLevels$;
  accessClaimsGroups$;
  serviceGroups$;
  form;

  currentfilter;

  selectedUserId: string = null;
  selectedFacilityIds: number[];

  constructor(
    public userService: UserService,
    private facilityService: FacilityService,
    private companyService: CompanyService,
    private formBuilder: UntypedFormBuilder,
    private sortByValuePipe: SortByValuePipe,
    private accessclaimsgroupsService: AccessclaimsgroupsService,
    private serviceGroupService: ServicegroupService,
  ) {
    this.facilities$ = facilityService.facilities$;
    this.companies$ = companyService.companies$;
    this.userAccessLevels$ = accessclaimsgroupsService.accessLevels$;
    this.accessClaimsGroups$ = accessclaimsgroupsService.accessClaimsGroups$;
    this.users$ = userService.usersWithCompany$;
    this.serviceGroups$ = serviceGroupService.serviceGroupsChanged$;

    this.form = formBuilder.group({
      Active: [true, Validators.required],
      Name: [''],
      CompanyId: ['', Validators.required],
      Phone: [''],
      Email: ['', [Validators.required, Validators.email]],
      AccessLevel: ['', Validators.required],
      AccessClaimsGroupNames: ['', Validators.required],
      ServiceGroups: [''],
    });
  }

  ngOnInit() {}

  private getFormAsUser(): any {
    return {
      active: this.form.get('Active').value,
      name: this.form.get('Name').value,
      companyId: this.form.get('CompanyId').value,
      phone: this.form.get('Phone').value,
      email: this.form.get('Email').value,
      accessLevel: this.form.get('AccessLevel').value,
      accessClaimsGroupNames: this.form.get('AccessClaimsGroupNames').value,
      facilityIDs: this.selectedFacilityIds,
      serviceGroupIds: this.form.get('ServiceGroups').value,
    };
  }

  onSubmit() {
    // new user
    if (this.selectedUserId === null) {
      this.userService
        .createUser(this.getFormAsUser())
        .toPromise()
        .then(
          (res) => {},
          (err) => {},
        );
    } else {
      // editing existing user
      // todo error handling
      this.userService.updateCustomUser(this.selectedUserId, this.getFormAsUser());
    }

    this.uiState = UIState.edit;
  }

  edit() {
    if (this.uiState === UIState.edit) {
      this.uiState = UIState.list;
    } else {
      this.uiState = UIState.edit;
    }
  }

  createNewUser() {
    this.uiState = UIState.create;
    this.selectedUserId = null;
    this.selectedFacilityIds = null;
    this.form.patchValue({
      Active: true,
      Name: '',
      Company: '',
      CompanyId: '',
      Phone: '',
      Email: '',
      AccessLevel: '',
      AccessClaimsGroupNames: '',
      mobileActive: false,
      emailActive: false,
      mobile: '',
      ServiceGroups: '',
    });
  }

  deleteUser(user: IUser) {
    if (confirm('Confirm deletion of: ' + user.name)) {
      // todo error handling
      this.userService.deleteUser(user);
    }
  }

  modifyUser(user: IUser) {
    this.uiState = UIState.modify;
    this.selectedUserId = user.id;
    this.form.patchValue({
      Id: user.id,
      Active: user.active,
      Name: user.name,
      CompanyId: user.companyId,
      Phone: user.phone,
      Email: user.email,
      AccessClaimsGroupNames: this.sortByValuePipe.transform(user.accessClaimsGroupNames),
      AccessLevel: user.accessLevel,
      ServiceGroups: user.serviceGroups.map((sg) => sg.id),
    });
    if (user.facilityIDs) {
      this.selectedFacilityIds = user.facilityIDs;
    }
  }

  cancelNewUser() {
    this.uiState = UIState.edit;
  }
}
