import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from '@angular/core';
import { PageInfoService } from 'projects/serviceportal/src/app/services/page-info.service';
import { PageInfo, ModalService } from 'shared';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { InstallationsService } from '../../services/installations.service';
import { FacilityService } from '../../services/facility.service';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { ICompany } from '../../interfaces/company';
import { Observable, Subscription, combineLatest, BehaviorSubject, ReplaySubject } from 'rxjs';
import { IIGridDeviceConfigurationDTO } from '../../interfaces/iGridInstallationConfiguration';
import { map, shareReplay, first, skipWhile, filter, switchMap, debounceTime, startWith, tap } from 'rxjs/operators';
import { EArchiveState } from '../../interfaces/archiveState';
import { IFacility } from '../../interfaces/facility';
import { IIGridSystem } from '../../interfaces/iGridSystem';
import { DOCUMENT } from '@angular/common';
import { environment } from 'projects/serviceportal/src/environments/environment';
import { ISettingsChangedProps } from '../../components/igrid-settings/igrid-settings.component';
import { SystemRoutingService } from '../../services/system-routing.service';
import { orderBy } from 'lodash';
import { IIGridDeviceRoute } from '../../interfaces/iGridDeviceRoute';

@Component({
  selector: 'app-igrid-installation-page',
  templateUrl: './igrid-installation-page.component.html',
  styleUrls: ['./igrid-installation-page.component.scss'],
})
export class IGridInstallationPageComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private pageInfoService: PageInfoService,
    private router: Router,
    public route: ActivatedRoute,
    private installationService: InstallationsService,
    private facilityService: FacilityService,
    private userService: UserService,
    private modalService: ModalService,
    private translate: TranslateService,
    private routingService: SystemRoutingService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  refresh$ = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    map((e) => !!e),
    startWith(true),
    debounceTime(250),
  );

  installation$ = new BehaviorSubject<IIGridSystem>(null);
  deployedVersion: { version: string; timestampEpoch: number };
  currentVersion: { version: string; timestampEpoch: number };
  systemTypes$ = this.installationService.getIGridInstallationTypes();
  facility: IFacility;
  company: ICompany;
  configuration: IIGridDeviceConfigurationDTO;
  users$ = new ReplaySubject(1);

  subscriptions: Subscription = new Subscription();
  pageInfo$: Observable<PageInfo> = this.refresh$.pipe(
    switchMap(() =>
      combineLatest([this.facilityService.getFacility(this.route.snapshot.params.facilityId), this.installation$]).pipe(
        skipWhile(([_, installation]) => !installation),
        shareReplay(1),
        map(([facility, installation]) => this.pageInfoService.iGridInstallationPage(facility, installation)),
      ),
    ),
  );

  public deviceRoutes$ = this.routingService.allSystemRoutings$.pipe(map((d) => d.deviceroutes));
  public outgoingRoutes$ = combineLatest([this.deviceRoutes$, this.installation$.pipe(filter((i) => !!i)), this.refresh$]).pipe(
    map(([routes, system]) => routes[system.deviceId] ?? []),
  );
  public ingoingRoutes$ = combineLatest([this.deviceRoutes$, this.installation$.pipe(filter((i) => !!i)), this.refresh$]).pipe(
    map(([routes, system]) => {
      const incomingRoutes: IIGridDeviceRoute[] = [];
      for (const sourceDeviceId of Object.keys(routes)) {
        const matchingRoute = routes[sourceDeviceId]?.find((r) => r?.targetDeviceId.toLowerCase() === system.deviceId?.toLowerCase());
        if (matchingRoute) {
          incomingRoutes.push({ sourceDeviceId, ...matchingRoute });
        }
      }
      // tslint:disable-next-line: no-construct
      return orderBy(incomingRoutes, (r) => Number((r.routingName ?? '_0').split('_')[1] ?? '0')); // Previously buggy when routingName === null.
    }),
  );
  public routings$ = combineLatest([this.outgoingRoutes$, this.ingoingRoutes$]).pipe(
    map(([outgoing, incoming]) => ({ outgoing, incoming })),
  );

  ngOnInit(): void {
    // Get the installation, facility, company and configuration
    this.refresh$.subscribe(() => this.getProps());

    // Select the correct tab on startup
    const queryParams = this.route.snapshot.queryParams;
    if (!queryParams.tab) {
      this.router.navigate(['.'], {
        relativeTo: this.route,
        queryParams: { tab: 'overview' },
      });
    }
  }

  editInstallation() {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.router.navigate(['igrid/create/', facilityId, installationId]);
  }

  async handleSettingsChanged(event: ISettingsChangedProps) {
    await this.getProps();
  }

  private async getProps() {
    try {
      const { installationId, facilityId } = this.route.snapshot.params;
      combineLatest([this.facilityService.getIGridFacility(facilityId), this.systemTypes$]).subscribe(async ([facility, types]) => {
        this.facility = facility;
        const installation = (facility.installations as IIGridSystem[])
          .map(({ systemTypeVariant, ...rest }) => ({
            ...rest,
            parentFacilityId: facilityId,
            systemTypeVariant: types.find((t) => `${t.id}` === `${rest.systemTypeId}`).description,
          }))
          .find((s) => s.id.toString() === installationId);
        this.installation$.next(installation);
        this.deployedVersion = installation?.version?.changes.find(
          (v) => v.version === installation?.version?.deployedVersion && v.deployed,
        ) || { version: '1', timestampEpoch: new Date().getTime() };

        this.currentVersion = installation?.version?.changes.find((v) => v.version === installation?.version?.currentVersion) || {
          version: '1',
          timestampEpoch: new Date().getTime(),
        };
        this.company = this.facility.company;
      });

      this.userService
        .getUsersForFacility(facilityId)
        .pipe(map((users) => users.filter((u) => u.archiveState === EArchiveState.Active)))
        .subscribe((users) => this.users$.next(users));
    } catch (e) {
      // TODO: Handle error here
      console.error(e);
    }
  }

  ngOnDestroy() {
    this.subscriptions?.unsubscribe();
  }

  ngAfterViewInit() {
    scrollTo(0, 0);
  }

  navigateToCustomerPortal() {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.document.location.href = `${environment.getiGridCustomerPortalUrl(location)}/site/${facilityId};system=${installationId}/settings`;
  }

  showArchiveModal() {
    this.modalService.showTextModal({
      title: this.translate.instant('igrid-installation-page.archive-project'),
      content: this.translate.instant('igrid-installation-page.archive-confirm'),
      actions: [
        { text: this.translate.instant('app-cancel'), cancel: true },
        {
          text: this.translate.instant('igrid-installation-page.archive'),
          type: 'danger',
          handler: this.archiveProject,
        },
      ],
    });
  }

  private archiveProject = () => {
    this.installation$
      .pipe(
        skipWhile((installation) => !(installation?.archiveState === EArchiveState.Active)),
        first(),
      )
      .subscribe((installation) =>
        this.installationService.archiveIGridInstallation(this.facility.id, installation.id).pipe(first()).subscribe(),
      );
  };

  showPushConfigModal() {
    this.modalService.showTextModal({
      title: this.translate.instant('igrid-installation-page.push-latest-configuration'),
      content: this.translate.instant('igrid-installation-page.config-confirm-deploy'),
      actions: [
        { text: this.translate.instant('app-cancel'), cancel: true },
        {
          text: this.translate.instant('app-update'),
          handler: this.pushConfig.bind(this),
          type: 'primary',
        },
      ],
    });
  }

  async pushConfig() {
    try {
      const installation = await this.installation$.toPromise();
      await this.installationService.deployLatestSchematic(installation.buildingConnectId, this.currentVersion.version);
      await this.getProps();
    } catch (e) {
      // TODO: Handle error here
      console.error(e);
    }
  }

  confirmCreateNewConfigBasedOn(version: any) {
    this.modalService.showTextModal({
      title: this.translate.instant('igrid-installation-page.create-new-config'),
      content: this.translate.instant('igrid-installation-page.create-new-config-based-on-text'),
      actions: [
        { text: this.translate.instant('app-cancel'), cancel: true },
        {
          text: this.translate.instant('igrid-installation-page.create-new-config'),
          handler: this.createNewConfigBasedOnExisting.bind(this, version),
          type: 'primary',
        },
      ],
    });
  }

  createNewConfigBasedOnExisting(version?: any) {
    const { facilityId, installationId } = this.route.snapshot.params;
    this.router.navigate(['igrid', 'create', facilityId, installationId], {
      queryParams: {
        version,
      },
    });
  }
}
