import { EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ModalService } from 'shared';
import { ESettingControlType, ESettingValueType, IIGridSystem, ISetting } from '../../interfaces/iGridSystem';
import { CurrentUserService, EAdminClaimNames } from '../../services/current-user.service';
import { InstallationsService } from '../../services/installations.service';
import { IAddSystemSettingPayload, ISettingNode, ISettingNodeFlat, IUpdateSystemSettingPayload } from '../../interfaces/iGridSystemSetting';
import { AddIgridSystemSettingDialogComponent } from '../add-igrid-system-setting-dialog/add-igrid-system-setting-dialog.component';

export interface ISettingsChangedProps {
  readonly parentFacilityId: number;
  readonly systemId: number;
  readonly settingId?: number;
}

@Component({
  selector: 'app-igrid-settings',
  templateUrl: './igrid-settings.component.html',
  styleUrls: ['./igrid-settings.component.scss'],
})
export class IgridSettingsComponent implements OnInit, OnChanges {
  constructor(
    private translateService: TranslateService,
    private systemsService: InstallationsService,
    private modalService: ModalService,
    private currentUserService: CurrentUserService,
    private fb: UntypedFormBuilder,
  ) {}

  @Input() public currentSystem: IIGridSystem;
  @Output() public $settingsChanged = new EventEmitter<ISettingsChangedProps>();

  public loading$ = new BehaviorSubject<boolean>(false);
  public settingsTree$ = new ReplaySubject<ISettingNode[]>();
  public translations$ = this.translateService.stream('igrid-installation-settings');
  public rainbowMode = this.fb.group({ useRainbow: false });
  public isDeveloper$ = this.currentUserService.currentUser$.pipe(
    map((user) => user.accessClaimsGroupNames.some((acgn) => acgn === 'Admin')),
    catchError(() => of(false)),
  );

  public maxWidth$ = this.translations$.pipe(
    first(),
    map(
      (translations) =>
        Math.max.apply(
          Math,
          this.currentSystem.settings.map((s) => translations[s.settingType.settingKey]?.length),
        ) * 10,
    ),
  );

  public maxIndentationLevel$ = this.settingsTree$.pipe(
    map((tree) =>
      Math.max.apply(
        Math,
        tree.flat(Infinity).map((s) => s.level + 1),
      ),
    ),
  );

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentSystem) {
      this.applyCurrentSystem();
    }
  }

  public ngOnInit(): void {
    this.applyCurrentSystem();
  }

  /* TODO: avoid nested subscription hell. */
  public addSetting = () =>
    this.currentUserService
      .hasClaim(EAdminClaimNames.ServicePortalSupportBase)
      .pipe(
        withLatestFrom(this.currentUserService.hasClaim(EAdminClaimNames.ServicePortalServiceGroup), this.isDeveloper$),
        first(),
        switchMap(([isSupportUser, isServiceGroup, isDeveloper]) =>
          this.modalService.openDialog<IAddSystemSettingPayload>(AddIgridSystemSettingDialogComponent, {
            data: {
              system: this.currentSystem,
              isSupportUser: isSupportUser || isDeveloper,
              editable: true,
            },
          }),
        ),
      )
      .subscribe(({ result }) => {
        if (result) {
          this.systemsService.addSettingToIGridSystem(this.currentSystem.parentFacilityId, this.currentSystem.id, { ...result }).subscribe(
            () => {
              this.$settingsChanged.next({
                parentFacilityId: this.currentSystem.parentFacilityId,
                systemId: this.currentSystem.id,
              });
            },
            () => {
              this.loading$.next(false);
            },
            () => {
              this.loading$.next(false);
            },
          );
        }
      });

  /**
   * Open the modal, do not use the return value for anything
   */
  public viewSetting(settingId) {
    this.modalService
      .openDialog<IAddSystemSettingPayload>(AddIgridSystemSettingDialogComponent, {
        data: {
          system: this.currentSystem,
          setting: this.currentSystem.settings.find((s) => s.id === settingId),
          editable: false,
        },
      })
      .subscribe();
  }

  public editSetting(settingId) {
    this.modalService
      .openDialog<IUpdateSystemSettingPayload & { id: number }>(AddIgridSystemSettingDialogComponent, {
        data: {
          system: this.currentSystem,
          setting: this.currentSystem.settings.find((s) => s.id === settingId),
          editable: true,
        },
      })
      .subscribe(({ result }) => {
        if (result) {
          this.systemsService.updateIGridSystemSetting(this.currentSystem.parentFacilityId, this.currentSystem.id, { ...result }).subscribe(
            () => {
              this.$settingsChanged.next({
                parentFacilityId: this.currentSystem.parentFacilityId,
                systemId: this.currentSystem.id,
              });
            },
            () => {
              this.loading$.next(false);
            },
            () => {
              this.loading$.next(false);
            },
          );
        }
      });
  }

  public deleteSetting(settingId) {
    this.loading$.next(true);
    this.translateService
      .get([
        'igrid-installation-settings-component.delete-warning-title',
        'igrid-installation-settings-component.delete-warning-description',
      ])
      .pipe(
        first(),
        switchMap(
          ({
            'igrid-installation-settings-component.delete-warning-title': modalTitle,
            'igrid-installation-settings-component.delete-warning-description': modalText,
          }: any) => this.modalService.showConfirmModal(modalTitle, modalText).pipe(first()),
        ),
      )
      .subscribe((deleteIntendConfirmed) => {
        if (!deleteIntendConfirmed) {
          this.loading$.next(false);
          return;
        }
        return this.systemsService
          .deleteIGridSystemSetting(this.currentSystem.parentFacilityId, this.currentSystem.id, settingId)
          .subscribe(
            () => {
              this.$settingsChanged.next({
                parentFacilityId: this.currentSystem.parentFacilityId,
                systemId: this.currentSystem.id,
              });
            },
            () => {
              this.loading$.next(false);
            },
            () => {
              this.loading$.next(false);
            },
          );
      });
  }

  /* Called/used in template; keep public. */
  private nodeComparer = (a: ISettingNodeFlat, b: ISettingNodeFlat) => a.order - b.order;

  /* Assigns levels to the tree items (used after having created the tree) */
  private assignLevelToTreeItems = (node: ISettingNodeFlat[], childrenPropName = 'settings', level = 0) =>
    node?.sort(this.nodeComparer).forEach((child) => {
      child.level = level;
      this.assignLevelToTreeItems(child[childrenPropName], childrenPropName, level + 1);
    });

  /**
   * Translates the settings of the system, compiles the setting tree expands the entire tree.
   */
  private applyCurrentSystem() {
    this.loading$.next(true);
    const settingsMapped = this.currentSystem?.settings?.map((setting) => this.generateNodeBasedOnSetting(setting)) || [];
    const settingsTree = this.arrayToTree(settingsMapped, 'settings');
    this.assignLevelToTreeItems(settingsTree, 'settings', 0);
    this.settingsTree$.next(settingsTree);
    this.loading$.next(false);
  }

  /* Unflattens a flattened tree (creates an actual nested tree structure) */
  private arrayToTree(flatTree: any[], childrenPropName = 'children'): ISettingNode[] {
    const root: any[] = [];
    const itemMap = new Map();
    flatTree.forEach((node) => {
      if (!node.parentId) {
        return root.push(node);
      }
      let parentIndex = itemMap.get(node.parentId);
      if (typeof parentIndex !== 'number') {
        parentIndex = flatTree.findIndex((el) => el.id === node.parentId);
        itemMap.set(node.parentId, parentIndex);
      }
      if (!flatTree[parentIndex][childrenPropName]) {
        return (flatTree[parentIndex][childrenPropName] = [node]);
      }
      flatTree[parentIndex][childrenPropName].push(node);
      flatTree[parentIndex][childrenPropName].sort(this.nodeComparer);
    });
    return root;
  }

  /* Generates a node based on a given setting */
  private generateNodeBasedOnSetting = (setting: ISetting): ISettingNodeFlat => {
    const settingKey = setting.settingType.settingKey;
    const type: string = this.inferControlTypeBasedOnSettingValueType(setting); // Replace with one of the two lines below to test selection settings
    const selectOptions$ = new ReplaySubject();
    let defaultValue;
    let tick = 1;

    // Construct the available selectOptions based on the parametersJson property of the setting.
    const parametersJson = setting.settingType?.parametersJson;
    if (parametersJson) {
      try {
        const parameters = JSON.parse(parametersJson);
        selectOptions$.next(
          (parameters.options || []).map(({ translationKey, value }: Record<string, string | number>) =>
            type === ESettingControlType.multiselect
              ? {
                  checked: setting.value.includes(value),
                  key: translationKey ? 'DEVICE.SETTINGS.OPTIONS.' + translationKey : '',
                  value,
                }
              : { key: translationKey, value },
          ),
        );
        if (parameters.tick) {
          tick = parameters.tick;
        }
        if (parameters.default) {
          defaultValue = parameters.default;
        }
      } catch (error) {
        console.error('Something went wrong while parsing parametersJson property as JSON: ', error, parametersJson);
      }
    }

    return {
      id: setting.id,
      parentId: setting.parentId,
      childrenCount: setting.childrenCount,
      value: setting.value?.trim(),
      min: setting.settingType.minValue,
      max: setting.settingType.maxValue,
      order: setting.order,
      unit: setting.settingType.unit,
      changedBy: setting.changedBy,
      type,
      tick,
      settingKey,
      customName: setting.customName,
      changed: setting.changed ? new Date(setting.changed * 1000).toDateString() : null,
      defaultValue,
      selectOptions$,
      needsSupportUserLevel: setting.needsSupportUserLevel,
    };
  };

  /**
   * Helper function which maps a server-defined type of settingValue to FE domains controlTypes.
   *
   * @param setting Input setting from which to derive the specific control to use
   * @returns a string (ESettingControlType) representing the type of control to use.
   */
  private inferControlTypeBasedOnSettingValueType(setting: ISetting) {
    return setting.settingType.valueType === ESettingValueType.number
      ? ESettingControlType.slider
      : setting.settingType.valueType === ESettingValueType.string
      ? ESettingControlType.text
      : setting.settingType.valueType;
  }
}
