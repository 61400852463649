<div class="language-picker">
  <div class="language-picker-header">
    <!-- This is hardcoded in english on purpose -->
    Select your preferred language
  </div>

  <ng-container *ngIf="currentLanguage$ | async as currentLanguage">
    <div class="language-list" *ngIf="languages$ | async as languages">
      <button
        class="language"
        *ngFor="let language of languages"
        [ngClass]="{ selected: currentLanguage === language.languageCode }"
        (click)="chooseLanguage(language.languageCode)"
      >
        {{ language.name }}
      </button>
    </div>
  </ng-container>
</div>
