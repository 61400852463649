<gbc-breadcrumb [pageInfo]="pageInfo"></gbc-breadcrumb>
<gbc-page-error-handler [content]="content" [pageError$]="pageError$"></gbc-page-error-handler>
<ng-template #content>
  <gbc-command-spinner>
    <gbc-page-container>
      <form [formGroup]="form" (submit)="submit()">
        <h2 class="title">
          {{ (id ? 'create-edit-user.edit-user' : 'create-edit-user.create-user') | translate }}
        </h2>

        <gbc-tile [title]="(id ? 'create-edit-user.edit-user' : 'create-edit-user.create-user') | translate">
          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-user.name' | translate }}*</gbc-input-label>
            <gbc-input-field formControlName="name"></gbc-input-field>
          </gbc-input-container>
          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-user.email' | translate }}*</gbc-input-label>
            <gbc-input-field
              inputType="email"
              formControlName="email"
              [valid]="!form.get('email').value || form.get('email').valid"
              errorDescriptionTranslationString="create-edit-user.invalid-email"
            ></gbc-input-field>
          </gbc-input-container>
          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-user.company' | translate }}*</gbc-input-label>
            <gbc-dropdown formControlName="company" [dropDownItems]="companies$ | async"></gbc-dropdown>
          </gbc-input-container>
          <gbc-input-container>
            <gbc-input-label>{{ 'create-edit-user.role' | translate }}*</gbc-input-label>
            <gbc-dropdown formControlName="role" [dropDownItems]="roles"></gbc-dropdown>
          </gbc-input-container>
          <gbc-form-hint> * {{ 'app-must-fill-in' | translate }} </gbc-form-hint>
        </gbc-tile>

        <ng-container *ngIf="facilitiesForCompany$ | async as facilities">
          <gbc-tile [title]="selectedCompanyName$ | async">
            <gbc-item-list-selector
              formControlName="facilities"
              [header]="[{ key: 'name', title: 'create-edit-user.facility' | translate }]"
              [fallbackMessage]="'create-edit-user.no-facilities-for-company' | translate"
              [items]="facilities"
            >
            </gbc-item-list-selector>
          </gbc-tile>
        </ng-container>

        <button gbc-button large type="submit" [disabled]="!form.valid" class="submit-button">
          {{ (id ? 'create-edit-user.update-user' : 'create-edit-user.create-user') | translate }}
        </button>
      </form>
    </gbc-page-container>
  </gbc-command-spinner>
</ng-template>
