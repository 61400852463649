import { Component, Input } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { CompanyService } from '../../services/company.service';
import { FacilityService } from '../../services/facility.service';
import { ICompany } from 'projects/serviceportal/src/app/interfaces/company';
import { IFacility } from 'projects/serviceportal/src/app/interfaces/facility';
import { map, shareReplay, tap } from 'rxjs/operators';
import { EArchiveState } from '../../interfaces/archiveState';

@Component({
  selector: 'app-create-installation-form',
  templateUrl: './create-installation-form.component.html',
  styleUrls: ['./create-installation-form.component.scss'],
})
export class CreateInstallationFormComponent {
  form: UntypedFormGroup;
  companies$: Observable<ICompany[]>;

  // The list of facilities for the *selected* company
  facilities$: Observable<IFacility[]>;

  @Input() public facilityType: 'BuildingConnect' | 'MIXIT' | 'iGrid' = 'BuildingConnect';

  public setCompanyAndFacility(company: ICompany, f: IFacility) {
    this.form.patchValue({
      company,
      facility: {
        ...f,
        name: f.name?.trim() || `${f.addressRoad}, ${f.addressPostal}`,
      } as any,
    });
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private companyService: CompanyService,
    private facilityService: FacilityService,
  ) {
    this.form = this.formBuilder.group({
      company: [null, Validators.required],
      facility: [null, Validators.required],
    });

    this.companies$ = this.companyService.companies$;

    this.facilities$ = combineLatest([this.form.get('company').valueChanges, this.facilityService.facilities$]).pipe(
      tap(([client]) => {
        if (client) {
          this.form.get('facility').enable();
        } else {
          this.form.get('facility').disable();
        }
      }),
      map(([client, facilities]) => {
        if (!client) {
          return [];
        }
        return facilities
          .filter(
            (facility) =>
              facility.company?.id === client.id &&
              facility.facilityType === this.facilityType &&
              facility.archiveState === EArchiveState.Active,
          ) // Bugfix #148354: Add filter param to filter away the archived sites to prevent accidentally adding a system to an archived site.
          .map((f) => ({
            ...f,
            name: f.name?.trim() || `${f.addressRoad}, ${f.addressPostal}`,
          }));
      }),
      shareReplay(1),
    );
  }
}
