<gbc-modal [title]="data.title">
  <gbc-modal-content>
    <img *ngIf="data.iconUrl" class="icon" [src]="data.iconUrl" />
    <h4 *ngIf="data.headline" class="headline">{{ data.headline }}</h4>
    {{ data.content }}
  </gbc-modal-content>
  <gbc-modal-buttons>
    <button
      gbc-modal-button
      *ngFor="let action of data.actions"
      (click)="selectAction(action)"
      [ngClass]="{
        danger: action.type === 'danger',
        primary: action.type === 'primary'
      }"
    >
      {{ action.text }}
    </button>
  </gbc-modal-buttons>
</gbc-modal>
