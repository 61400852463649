<gbc-modal [title]="title$ | async">
  <gbc-modal-content>
    <form #createIGridSystemRoutingForm [formGroup]="form" (ngSubmit)="save()">
      <gbc-input-container>
        <gbc-input-label>{{ 'add-edit-igrid-system-routing.source-system' | translate }}*</gbc-input-label>
        <gbc-dropdown
          formControlName="sourceSystem"
          placeholderTranslationString="app-select"
          [dropDownItems]="data.selectableSystems$ | async"
          [virtualScroll]="true"
        >
        </gbc-dropdown>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label>{{ 'add-edit-igrid-system-routing.routing-name' | translate }}</gbc-input-label>
        <gbc-dropdown
          formControlName="routingName"
          placeholderTranslationString="app-select"
          [dropDownItems]="selectableRouteNames$ | async"
        >
        </gbc-dropdown>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label>{{ 'add-edit-igrid-system-routing.description' | translate }}</gbc-input-label>
        <gbc-input-field
          inputType="text"
          placeholderTranslationString="{{ form.disabled ? '' : 'app-description' }}"
          formControlName="description"
        >
        </gbc-input-field>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label>{{ 'add-edit-igrid-system-routing.strategy' | translate }}*</gbc-input-label>
        <gbc-dropdown
          formControlName="strategy"
          placeholderTranslationString="app-select"
          [dropDownItems]="selectableRoutingStrategy$ | async"
        >
        </gbc-dropdown>
      </gbc-input-container>

      <gbc-input-container>
        <gbc-input-label>{{ 'add-edit-igrid-system-routing.toggle-routing' | translate }}</gbc-input-label>
        <div class="enabled-wrapper">
          <img class="route-disabled" [src]="form.get('enabled').value ? './assets/svgs/grey_remove-circle_filled.svg' : './assets/svgs/remove-circle_filled.svg'" />
          <p-inputSwitch formControlName="enabled"></p-inputSwitch>
          <img class="route-enabled" [src]="form.get('enabled').value ? './assets/svgs/green-checked-circle.svg' : '/assets/svgs/grey-checked-circle.svg'" />
        </div>


      </gbc-input-container>

      <div *ngIf="form.dirty && form.controls.sourceSystem?.errors?.required" class="alert alert-danger">
        {{ 'add-edit-igrid-system-routing.error-source-system-required' | translate }}
      </div>

      <div *ngIf="form.dirty && !form.controls.routingName.value?.length" class="alert alert-warning">
        {{ 'add-edit-igrid-system-routing.warning-routing-name-unset' | translate }}
        ({{ data.system?.deviceId }}).
      </div>

      <div class="form-group">
        <div *ngFor="let error of form.errors" class="error-container alert alert-danger">
          {{ error }}
        </div>
      </div>
    </form>

    <div class="action-button-row">
      <ng-container>
        <button gbc-button primary [disabled]="form.invalid || !form.dirty" (click)="save()" class="primary">
          {{ 'app-done' | translate }}
        </button>
        <button gbc-button (click)="cancel()">
          {{ 'app-cancel' | translate }}
        </button>
      </ng-container>
    </div>
  </gbc-modal-content>
</gbc-modal>
